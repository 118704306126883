import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { useItemMessagesListResultsMessageContentButtons } from '../../hooks/useItemMessagesListResultsMessageContentButtons';

import { TaskResultsMarks } from '../../../../../../../../../../../main/tasks/helpers/TaskResultsMarks';

import { ItemMessagesListResultsMessageContentButton } from '../ItemMessagesListResultsMessageContentButton';
import { ItemMessagesListResultsMessageContentDropdownButtons } from '../ItemMessagesListResultsMessageContentDropdownButtons';

import { ItemMessagesListResultsMessageContentButtonTypes } from '../../ItemMessagesListResultsMessageContent.types';

import { ItemMessagesListResultsMessageContentDoneStatusTask } from './ItemMessagesListResultsMessageContentDoneStatus.types';

const buttons: ItemMessagesListResultsMessageContentButtonTypes[] = [
  ItemMessagesListResultsMessageContentButtonTypes.DOWNLOAD_RESULTS,
  ItemMessagesListResultsMessageContentButtonTypes.SUBMIT_SOURCE_FILES
];

interface ItemMessagesListResultsMessageContentDoneStatusProps {
  task: ItemMessagesListResultsMessageContentDoneStatusTask;
}

function ItemMessagesListResultsMessageContentDoneStatus({
  task
}: ItemMessagesListResultsMessageContentDoneStatusProps) {
  const { mainButton, dropdownButtons } =
    useItemMessagesListResultsMessageContentButtons({
      buttons
    });

  return (
    <div className="flex justify-center w-full text-sm mt-4 px-2">
      <div className="w-full px-2 py-4 space-y-4">
        <TaskResultsMarks supportMark={task.supportMark} mark={task.mark} />
        <div className="flex gap-1 justify-center">
          <span className="relative z-10 inline-flex shadow-sm rounded-md z-auto">
            <ItemMessagesListResultsMessageContentButton
              main
              buttonType={mainButton}
              task={task}
              color="blue"
              rounded={isEmpty(dropdownButtons)}
            />

            <ItemMessagesListResultsMessageContentDropdownButtons
              buttonColor="blue"
              buttons={dropdownButtons}
              task={task}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

export default ItemMessagesListResultsMessageContentDoneStatus;
