import { gql } from 'graphql-request';

import { ClientFileUUID } from '../clientFilesTypes';

export interface DownloadClientFileQueryResponse {
  uuid: ClientFileUUID;
}

export const DOWNLOAD_CLIENT_FILE_QUERY = gql`
  mutation DownloadClientFile($uuid: ID!, $deviceNanoId: String!) {
    downloadClientFile(input: { uuid: $uuid, deviceNanoId: $deviceNanoId }) {
      recordUuid
      status
      record {
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
