import { TaskStatus } from '../../../../../../../../tasks/tasksTypes';

import { ItemMessagesListResultsMessageContentDoneStatusTask } from './components/ItemMessagesListResultsMessageContentDoneStatus';
import { ItemMessagesListResultsMessageContentQaStatusTask } from './components/ItemMessagesListResultsMessageContentQaStatus';
import { ItemMessagesListResultsMessageContentRFAStatusTask } from './components/ItemMessagesListResultsMessageContentRFAStatus';

import { TasksPermissions } from '../../../../../../../../tasks/tasksConstants';

interface TaskMessageTask {
  status: TaskStatus;
}

interface TaskMessagesListResultsMessageContentItem {
  task: TaskMessageTask &
    ItemMessagesListResultsMessageContentDoneStatusTask &
    ItemMessagesListResultsMessageContentQaStatusTask &
    ItemMessagesListResultsMessageContentRFAStatusTask;
  project?: never;
}

interface ProjectMessagesListResultsMessageContentItem {
  task?: never;
}

export type ItemMessagesListResultsMessageContentItem =
  | TaskMessagesListResultsMessageContentItem
  | ProjectMessagesListResultsMessageContentItem;

export enum ItemMessagesListResultsMessageContentButtonTypes {
  REVISION = 'revision',
  ACCEPT_RESULTS = 'accept_results',
  APPROVE_RESULTS = 'approve_results',
  DOWNLOAD_RESULTS = 'download_results',
  SUBMIT_SOURCE_FILES = 'submit_source_files'
}

export const buttonPermissions = {
  [ItemMessagesListResultsMessageContentButtonTypes.APPROVE_RESULTS]:
    TasksPermissions.READ_APPROVE_RESULTS_IN_TASK_BUTTON,
  [ItemMessagesListResultsMessageContentButtonTypes.ACCEPT_RESULTS]:
    TasksPermissions.READ_ACCEPT_RESULTS_IN_TASK_BUTTON,
  [ItemMessagesListResultsMessageContentButtonTypes.REVISION]:
    TasksPermissions.READ_REVISION_TASK_BUTTON,
  [ItemMessagesListResultsMessageContentButtonTypes.DOWNLOAD_RESULTS]:
    TasksPermissions.READ_DOWNLOAD_TASK_RESULTS_BUTTON,
  [ItemMessagesListResultsMessageContentButtonTypes.SUBMIT_SOURCE_FILES]:
    TasksPermissions.READ_TASK_SUBMIT_SOURCE_FILES_BUTTON
};
