import { TaskNanoID } from '../../../tasks/tasksTypes';

import {
  CreateTodoItemInTaskGqlQuery,
  CreateTodoItemInTaskGqlStatus,
  TodoItemDone,
  TodoItemGqlError,
  TodoItemName,
  TodoItemTaskID,
  TodoItemUUID,
  TodoItemVisibleForClients
} from '../../todoItemsTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

import { TaskTodoItemsCache } from '../../TaskTodoItemsCache';

interface CreateTodoItemInTaskInput {
  done: TodoItemDone;
  name: TodoItemName;
  taskId: TodoItemTaskID;
  visibleForClients: TodoItemVisibleForClients;
}

interface CreateTodoItemInTaskResponse<CreateTodoItemInTaskRecordType> {
  createTodoItemInTask: {
    status: CreateTodoItemInTaskGqlStatus;
    recordUuid: TodoItemUUID;
    record: CreateTodoItemInTaskRecordType;
    errors: CreateTodoItemInTaskErrors;
  };
}

interface CreateTodoItemInTaskErrors {
  done: TodoItemGqlError;
  fullMessages: TodoItemGqlError;
  name: TodoItemGqlError;
  taskId: TodoItemGqlError;
  visibleForClients: TodoItemGqlError;
}

interface CreateTodoItemInTaskOptions {
  taskNanoId: TaskNanoID;
  query: CreateTodoItemInTaskGqlQuery;
}

const action = 'createTodoItemInTask';

function useCreateTodoItemInTask<CreateTodoItemInTaskRecordType>({
  taskNanoId,
  query
}: CreateTodoItemInTaskOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateTodoItemInTaskInput,
    CreateTodoItemInTaskResponse<CreateTodoItemInTaskRecordType>,
    CreateTodoItemInTaskErrors,
    CreateTodoItemInTaskRecordType
  >({
    action,
    cacheKeys: [TaskTodoItemsCache.indexCacheKey(taskNanoId)],
    query
  });

  return {
    createTodoItemInTaskData: createQueryData,
    createTodoItemInTaskError: createQueryError,
    createTodoItemInTaskLoading: createQueryLoading,
    createTodoItemInTaskErrorMessage: createQueryErrorMessage,
    createTodoItemInTask: createQuery,
    createTodoItemInTaskReset: createQueryReset
  };
}

export default useCreateTodoItemInTask;
