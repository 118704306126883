import { TaskNanoID } from '../../../tasks/tasksTypes';
import { TextFilterType } from '../../../../types';

import {
  FETCH_TASK_MESSAGES,
  FetchTaskMessagesQueryResponse
} from '../../../messages/queries/fetchTaskMessages.query';
import { FETCH_TASK_MESSAGES_WITH_ASSOCIATIONS } from '../../queries/fetchTaskMessagesWithAssociations.query';

import { useMessagesWithCustomPagination } from '../../../messages/hooks/useMessagesWithCustomPagination';
import { useTaskMessagesWithCustomPagination } from '../useTaskMessagesWithCustomPagination';
import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { TaskCache } from '../../../tasks/TaskCache';

import { TasksPermissions } from '../../../tasks/tasksConstants';

interface TaskMessagesOptions {
  taskNanoId: TaskNanoID;
  whiteboardKeyId?: TextFilterType;
}

function useTaskMessages({ taskNanoId, whiteboardKeyId }: TaskMessagesOptions) {
  const currentUser = useCurrentUser();

  const withAssociations = currentUser.hasPermissions(
    TasksPermissions.READ_TASK_MESSAGES_ASSOCIATIONS
  );

  const messagesPagination =
    useMessagesWithCustomPagination<FetchTaskMessagesQueryResponse>({
      cacheKey: TaskCache.messagesCacheKey(taskNanoId),
      query: FETCH_TASK_MESSAGES,
      initialFilters: {
        taskNanoId,
        whiteboardKeyId
      },
      options: {
        enabled: !withAssociations,
        enabledPlaceholder: false
      }
    });

  const taskMessagesPagination =
    useTaskMessagesWithCustomPagination<FetchTaskMessagesQueryResponse>({
      cacheKey: TaskCache.messagesCacheKey(taskNanoId),
      query: FETCH_TASK_MESSAGES_WITH_ASSOCIATIONS,
      initialFilters: {
        taskNanoId,
        whiteboardKeyId
      },
      options: {
        enabled: withAssociations,
        enabledPlaceholder: false
      }
    });

  if (withAssociations) {
    return taskMessagesPagination;
  }

  return messagesPagination;
}

export default useTaskMessages;
