import React from 'react';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { PositionStyleEnum } from '../../../../../../../../../../../types';

import { IconsEnum } from '../../../../../../../../../../../assets/icons/types';

import { TooltipDropdownHelper } from '../../../../../../../../../../../helpers/dropdowns/TooltipDropdownHelper';

import { words } from '../../../../../../../../../../../locales/keys';

import { PopoverPlacement } from '../../../../../../../../../../../helpers/Popover/popoverConstants';

import { ItemMessagesListResultsMessageContentButton } from '../ItemMessagesListResultsMessageContentButton';

import { ItemMessagesListResultsMessageContentButtonTypes } from '../../ItemMessagesListResultsMessageContent.types';

import { ItemMessagesListResultsMessageContentDropdownButtonsTask } from './ItemMessagesListResultsMessageContentDropdownButtons.types';

interface ItemMessagesListResultsMessageContentDropdownButtonsProps {
  buttonColor: 'blue' | 'purple' | 'green';
  buttons: ItemMessagesListResultsMessageContentButtonTypes[];
  task: ItemMessagesListResultsMessageContentDropdownButtonsTask;
  refetchAfterSubmit?: () => void;
}

function ItemMessagesListResultsMessageContentDropdownButtons({
  buttonColor,
  buttons,
  task,
  refetchAfterSubmit
}: ItemMessagesListResultsMessageContentDropdownButtonsProps) {
  if (isEmpty(buttons)) {
    return null;
  }

  return (
    <TooltipDropdownHelper
      buttonClassName={cl(
        'p-2 rounded-r-md text-white border-l border-white border-opacity-20 text-sm font-medium focus:z-10',
        {
          'bg-blue-500 hover:bg-blue-600': buttonColor === 'blue',
          'bg-purple-500 hover:bg-purple-600': buttonColor === 'purple',
          'bg-green-500 hover:bg-green-600': buttonColor === 'green'
        }
      )}
      icon={IconsEnum.CHEVRON_DOWN}
      iconClassName="h-5 w-5"
      popoverClassName="relative min-w-48 z-10 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700"
      popoverPositionStyle={PositionStyleEnum.fixed}
      dropdownPlacement={PopoverPlacement.BOTTOM}
      tooltipI18nText={words.openOptions}
      closeOnOutOfView
    >
      {buttons.map((button) => (
        <ItemMessagesListResultsMessageContentButton
          key={button}
          buttonType={button}
          task={task}
          refetchAfterSubmit={refetchAfterSubmit}
        />
      ))}
    </TooltipDropdownHelper>
  );
}

export default ItemMessagesListResultsMessageContentDropdownButtons;
