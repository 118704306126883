import React, { useMemo } from 'react';

import { PositionStyleEnum } from '../../../../../../../../../../../types';
import { IconsEnum } from '../../../../../../../../../../../assets/icons/types';
import { TooltipPlacement } from '../../../../../../../../../../../helpers/tooltips/tooltipsConstants';
import { TaskNanoID } from '../../../../../../../../../../tasks/tasksTypes';
import { TogglePreventModalCloseAction } from '../../../../../../../../../../../helpers/modals/modalsTypes';
import { FetchMessagesCacheKey } from '../../../../../../../../../messagesTypes';
import { ItemMessagesListResultsMessageResultImageButtonsFileAttachment } from './ItemMessagesListResultsMessageResultImageButtons.types';
import { SendImagesToWhiteboardPageModalButtonImage } from '../../../../../../../../../../whiteboards/components/forms/SendImagesToWhiteboardPageForm';
import { ProjectNanoID } from '../../../../../../../../../../projects/projectsTypes';

import { useCurrentUser } from '../../../../../../../../../../../auth/hooks/useAuth';

import { EditImageAndSendToTaskModalButton } from '../../../../../../../../../../tasks/components/modalButtons/EditImageAndSendToTaskModalButton';
import { DownloadFileAttachmentButton } from '../../../../../../../../../../fileAttachments/components/buttons/DownloadFileAttachmentButton';
import { RemoveFileAttachmentButton } from '../../../../../../../../../../fileAttachments/components/buttons/RemoveFileAttachmentButton';
import { SendImagesToWhiteboardPageDynamicModalButton } from '../../../../../../../../../../whiteboards/components/buttons/SendImagesToWhiteboardPageModalButton';
import { SubmitSourceFilesToTaskModalButton } from '../../../../../../../../../../tasks/components/modalButtons/SubmitSourceFilesToTaskModalButton';

import { Files } from '../../../../../../../../../../../utils/Files';
import { PopoverPlacement } from '../../../../../../../../../../../helpers/Popover/popoverConstants';
import { TooltipDropdownHelper } from '../../../../../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { TooltipSingletonSourceWrapper } from '../../../../../../../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import {
  stringsKeys,
  tasksKeys,
  words
} from '../../../../../../../../../../../locales/keys';

import { MessagesPermissions } from '../../../../../../../../../messagesConstants';

interface ItemMessagesListResultsMessageResultImageButtonsProps {
  fileAttachment: ItemMessagesListResultsMessageResultImageButtonsFileAttachment;
  messagesCacheKey: FetchMessagesCacheKey;
  projectNanoId: ProjectNanoID;
  taskNanoId: TaskNanoID;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}

function ItemMessagesListResultsMessageResultImageButtons({
  fileAttachment,
  messagesCacheKey,
  projectNanoId,
  taskNanoId
}: ItemMessagesListResultsMessageResultImageButtonsProps) {
  const currentUser = useCurrentUser();

  const withEditImageButton =
    taskNanoId &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_RESULT_EDIT_BUTTON
    );

  const withDownloadButton =
    fileAttachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENT_BUTTON
    );

  const withSubmitSourceFilesButton =
    taskNanoId &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_RESULT_SUBMIT_SOURCE_FILES_BUTTON
    );

  const withRemoveButton =
    fileAttachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_REMOVE_FILE_ATTACHMENT_BUTTON
    );

  const withSendToWhiteboardButton =
    fileAttachment.uuid &&
    Files.isImage(fileAttachment.file) &&
    taskNanoId &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_SEND_RESULT_TO_WHITEBOARD_BUTTON
    );

  const images = useMemo<SendImagesToWhiteboardPageModalButtonImage[]>(() => {
    if (!withSendToWhiteboardButton) {
      return [];
    }

    return [
      {
        fileUrl: fileAttachment.file,
        height: fileAttachment.height,
        name: fileAttachment.name,
        width: fileAttachment.width,
        fileAttachmentId: fileAttachment.id
      }
    ];
  }, [fileAttachment, withSendToWhiteboardButton]);

  if (
    !withEditImageButton &&
    !withDownloadButton &&
    !withSubmitSourceFilesButton &&
    !withRemoveButton &&
    !withSendToWhiteboardButton
  ) {
    return null;
  }

  return (
    <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 bg-white dark:bg-gray-800 rounded-md shadow p-0.5 flex gap-0.5">
      <TooltipSingletonSourceWrapper withArrow placement={TooltipPlacement.TOP}>
        {withDownloadButton && (
          <DownloadFileAttachmentButton
            fileAttachmentUuid={fileAttachment.uuid}
            className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            icon={IconsEnum.DOWNLOAD_SOLID}
            tooltipI18nText={words.download}
            tooltipSingleton
          />
        )}
        {withEditImageButton && (
          <EditImageAndSendToTaskModalButton
            file={fileAttachment.file}
            taskNanoId={taskNanoId}
            className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            icon={IconsEnum.PENCIL_ALT_OUTLINE}
            tooltipI18nText={words.edit}
            tooltipSingleton
          />
        )}

        {(withRemoveButton ||
          withSubmitSourceFilesButton ||
          withSendToWhiteboardButton) && (
          <div className="relative">
            <TooltipDropdownHelper
              buttonClassName="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
              popoverPositionStyle={PositionStyleEnum.fixed}
              tooltipI18nText={words.more}
              dropdownPlacement={PopoverPlacement.BOTTOM_END}
              tooltipSingleton
              closeOnOutOfView
            >
              {withSubmitSourceFilesButton && (
                <SubmitSourceFilesToTaskModalButton
                  taskNanoId={taskNanoId}
                  className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                  i18nText={tasksKeys.submitAsSourceFile}
                  initialFile={fileAttachment}
                />
              )}

              {withRemoveButton && (
                <RemoveFileAttachmentButton
                  cacheKeys={[messagesCacheKey]}
                  fileAttachmentUuid={fileAttachment.uuid}
                  className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                  i18nText={words.remove}
                />
              )}

              {withSendToWhiteboardButton && (
                <SendImagesToWhiteboardPageDynamicModalButton
                  cacheKeys={[messagesCacheKey]}
                  i18nText={stringsKeys.sendToWhiteboard}
                  images={images}
                  isResults
                  projectNanoId={projectNanoId}
                  taskNanoId={taskNanoId}
                  className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                />
              )}
            </TooltipDropdownHelper>
          </div>
        )}
      </TooltipSingletonSourceWrapper>
    </div>
  );
}

export default ItemMessagesListResultsMessageResultImageButtons;
