import cl from 'classnames';
import { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  ItemMessagesFilters,
  ItemMessagesListFilterMessages
} from '../../../../list/ItemMessagesList';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';

import { words } from '../../../../../../../locales/keys';

interface ItemMessagesHeaderVisibleForClientsFilterProps {
  messagesFilters: ItemMessagesFilters;
  filterMessages: ItemMessagesListFilterMessages;
}

function ItemMessagesHeaderVisibleForClientsFilter({
  messagesFilters,
  filterMessages
}: ItemMessagesHeaderVisibleForClientsFilterProps) {
  const onClickVisibleForClient = useCallback(() => {
    filterMessages({
      ...messagesFilters,
      visibleForClient: messagesFilters.visibleForClient ? undefined : true
    });
  }, [filterMessages, messagesFilters]);

  return (
    <PureIconButtonHelper
      className={cl(
        'py-1 pl-1 pr-1 sm:pr-2 sm:space-x-1 rounded inline-flex items-center whitespace-nowrap text-xs leading-5 focus:ring-base focus:ring-offset-0',
        messagesFilters.visibleForClient
          ? 'text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300'
          : 'hover:text-gray-950 dark:hover:text-white'
      )}
      icon={IconsEnum.EYE_SOLID}
      iconClassName="h-4 w-4"
      onClick={onClickVisibleForClient}
      i18nTextClassName="sr-only sm:not-sr-only"
      i18nText={words.visible}
    />
  );
}

export default ItemMessagesHeaderVisibleForClientsFilter;
