import React, { useCallback } from 'react';

import {
  FetchMessagesCacheKey,
  MessageCreatedProductID
} from '../../../../../../../messagesTypes';
import { ItemMessagesListResultsMessageResultImageFileAttachment } from './ItemMessagesListResultsMessageResultImage.types';
import { ProjectNanoID } from '../../../../../../../../projects/projectsTypes';
import { TaskNanoID } from '../../../../../../../../tasks/tasksTypes';

import { ItemMessagesListResultsMessageResultImageButtons } from './components/ItemMessagesListResultsMessageResultImageButtons';

import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../../../helpers/ImageHelper';
import { ProductTooltipIcon } from '../../../../../../../../products/helpers/ProductTooltipIcon';
import { FileAttachmentWhiteboardTooltipIcon } from '../../../../../../../../fileAttachments/helpers/FileAttachmentWhiteboardTooltipIcon';
import { LinkHelper } from '../../../../../../../../../helpers/links/LinkHelper';
import { OnLightboxOpenAction } from '../../../../../../../../../helpers/LightboxWrapper';
import { TogglePreventModalCloseAction } from '../../../../../../../../../helpers/modals/modalsTypes';

import { Files } from '../../../../../../../../../utils/Files';

interface ItemMessagesListResultsMessageResultImageProps {
  projectNanoId: ProjectNanoID;
  taskNanoId: TaskNanoID;
  fileAttachment: ItemMessagesListResultsMessageResultImageFileAttachment;
  onLightboxOpen: OnLightboxOpenAction;
  messagesCacheKey: FetchMessagesCacheKey;
  togglePreventModalClose?: TogglePreventModalCloseAction;
  createdProductId?: MessageCreatedProductID;
}

function ItemMessagesListResultsMessageResultImage({
  projectNanoId,
  taskNanoId,
  fileAttachment,
  onLightboxOpen,
  messagesCacheKey,
  togglePreventModalClose,
  createdProductId
}: ItemMessagesListResultsMessageResultImageProps) {
  const handleOpenLightbox = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      if (fileAttachment && Files.isImage(fileAttachment.file)) {
        e.preventDefault();
        onLightboxOpen(fileAttachment);
      }
    },
    [fileAttachment, onLightboxOpen]
  );

  return (
    <div className="group relative rounded-md border border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-850 hover:bg-gray-200 dark:hover:bg-gray-800 group">
      <LinkHelper
        className="block focus:ring-base overflow-hidden focus:ring-offset-0 rounded relative aspect-w-1 aspect-h-1 h-auto w-full"
        href={fileAttachment.file}
        target="_blank"
        onClick={handleOpenLightbox}
      >
        <ImageHelper
          className="absolute inset-0 rounded object-cover bg-gray-100 dark:bg-gray-850 block"
          src={fileAttachment.file}
          alt={fileAttachment.name}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
          tooltipAlt
        />
        <div className="absolute inset-0 flex items-end pointer-events-none">
          <div className="flex-1 max-w-full max-h-full px-4 py-2 bg-black bg-opacity-50 opacity-0 group-hover/item:opacity-100 transition-opacity duration-100">
            <p className="line-clamp-6 text-sm text-white break-words leading-tight pointer-events-auto">
              {fileAttachment.name}
            </p>
          </div>
        </div>
      </LinkHelper>

      <div className="absolute inset-0 flex items-end justify-start p-2 pointer-events-none space-x-1">
        {fileAttachment.whiteboardKeyId ? (
          <FileAttachmentWhiteboardTooltipIcon
            fileAttachmentId={fileAttachment.uuid}
          />
        ) : null}

        {createdProductId ? (
          <ProductTooltipIcon productId={createdProductId} />
        ) : null}
      </div>
      <ItemMessagesListResultsMessageResultImageButtons
        projectNanoId={projectNanoId}
        taskNanoId={taskNanoId}
        fileAttachment={fileAttachment}
        messagesCacheKey={messagesCacheKey}
        togglePreventModalClose={togglePreventModalClose}
      />
    </div>
  );
}

export default ItemMessagesListResultsMessageResultImage;
