import { gql } from 'graphql-request';

import { MaxFileUUID } from '../maxFilesTypes';

export interface DownloadMaxFileQueryResponse {
  uuid: MaxFileUUID;
}

export const DOWNLOAD_MAX_FILE_QUERY = gql`
  mutation DownloadMaxFile($uuid: ID!, $deviceNanoId: String!) {
    downloadMaxFile(input: { uuid: $uuid, deviceNanoId: $deviceNanoId }) {
      recordUuid
      status
      record {
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
