import { gql } from 'graphql-request';

import { ImageUUID } from '../imagesTypes';

export interface DownloadImageQueryResponse {
  uuid: ImageUUID;
}

export const DOWNLOAD_IMAGE_QUERY = gql`
  mutation DownloadImage($uuid: ID!, $deviceNanoId: String!) {
    downloadImage(input: { uuid: $uuid, deviceNanoId: $deviceNanoId }) {
      recordUuid
      status
      record {
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
