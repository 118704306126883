import React, { memo } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { TableViewType } from '../../../../../types';

import { IndexTableStickyCell } from '../IndexTableStickyCell';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

interface IndexTableRowExpandButtonProps {
  onClick: () => void;
  onMouseEnter?: () => void;
  viewType?: TableViewType;
}

function IndexTableRowExpandButton({
  onClick,
  onMouseEnter,
  viewType
}: IndexTableRowExpandButtonProps) {
  return (
    <IndexTableStickyCell isRight viewType={viewType}>
      <PureIconButtonHelper
        className="absolute dark:hover:bg-gray-700 dark:focus:bg-gray-700 flex focus:ring-offset-0 focus:ring-0 hover:bg-gray-200 focus:bg-gray-200 inset-0 items-center justify-center p-2 text-gray-400 group-hover:text-gray-600 hover:text-gray-800 focus:text-gray-800 dark:hover:text-gray-200 dark:focus:text-gray-200 dark:text-gray-600 dark:group-hover:text-gray-400 w-full"
        icon={IconsEnum.SELECTOR}
        iconClassName="w-5 h-5 transform rotate-45"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
      />
    </IndexTableStickyCell>
  );
}

export default memo<IndexTableRowExpandButtonProps>(IndexTableRowExpandButton);
