import React, { memo, useState } from 'react';

import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';
import { ItemMessagesListWhiteboardLogMessageItem } from './ItemMessagesListWhiteboardLogMessage.types';

import { ItemMessagesListWhiteboardLogMessageImage } from './components/ItemMessagesListWhiteboardLogMessageImage';

import { TooltipMessageDate } from '../../../../tooltips/TooltipMessageDate';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

interface ItemMessagesListWhiteboardLogMessageProps {
  message: ItemMessagesListWhiteboardLogMessageItem;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}

function ItemMessagesListWhiteboardLogMessage({
  message,
  togglePreventModalClose
}: ItemMessagesListWhiteboardLogMessageProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  return (
    <div
      id={`message-${message.uuid}`}
      ref={setReferenceTooltipElement}
      className="text-center text-xs text-gray-500 dark:text-gray-400 mt-6 mb-4"
    >
      <div className="flex justify-center items-center gap-2">
        {message.fileAttachment ? (
          <ItemMessagesListWhiteboardLogMessageImage
            message={message}
            togglePreventModalClose={togglePreventModalClose}
          />
        ) : null}
        <p>{message.body}</p>
      </div>

      <TooltipMessageDate
        referenceElement={referenceTooltipElement}
        date={message.createdAt}
        placement={TooltipPlacement.TOP}
      />
    </div>
  );
}

export default memo<ItemMessagesListWhiteboardLogMessageProps>(
  ItemMessagesListWhiteboardLogMessage
);
