import { Control, Controller } from 'react-hook-form';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { ItemMessageFilterFormData } from '../../hooks/useItemMessagesFilterForm/useItemMessagesFilterForm.types';

import {
  ItemMessagesFilterFormItem,
  ItemMessagesFilterFormItemValue
} from '../ItemMessagesFilterFormItem';

import { Icon } from '../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../helpers/Translate';

import { words } from '../../../../../../../locales/keys';

const items: ItemMessagesFilterFormItemValue<boolean>[] = [
  {
    i18nText: words.visible,
    value: true
  },
  {
    i18nText: words.hidden,
    value: false
  }
];

interface ItemMessagesFilterFormVisibilityProps {
  control: Control<ItemMessageFilterFormData>;
}

function ItemMessagesFilterFormVisibility({
  control
}: ItemMessagesFilterFormVisibilityProps) {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-2">
        <Icon icon={IconsEnum.EYE_SOLID} className="h-4 w-4" />

        <span className="text-sm font-semibold text-left flex-1">
          <Translate id={words.visibility} />
        </span>
      </div>

      <Controller
        control={control}
        name="visible"
        render={({ field: { onChange, value } }) => (
          <div className="flex flex-wrap gap-2">
            {items.map((item, index) => (
              <ItemMessagesFilterFormItem
                key={index}
                value={value}
                item={item}
                onChange={onChange}
              />
            ))}
          </div>
        )}
      />
    </div>
  );
}

export default ItemMessagesFilterFormVisibility;
