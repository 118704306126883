import { ReactNode, memo } from 'react';
import cl from 'classnames';

import { TableViewType } from '../../../../../types';

interface IndexTableStickyCellProps {
  isLeft?: boolean;
  isRight?: boolean;
  viewType?: TableViewType;
  children: ReactNode;
}

function IndexTableStickyCell({
  isLeft,
  isRight,
  viewType,
  children
}: IndexTableStickyCellProps) {
  return (
    <td
      className={cl(
        'bg-white bg-opacity-90 dark:bg-gray-900 dark:bg-opacity-90 sticky text-center z-10 group-hover:bg-gray-100 dark:group-hover:bg-gray-850 group-hover:bg-opacity-80 dark:group-hover:bg-opacity-80',
        {
          'left-0': isLeft,
          'right-0': isRight,
          'px-6 py-4': !viewType || viewType === TableViewType.DEFAULT,
          'px-6 w-px py-0.5 text-xs': viewType === TableViewType.SLIM
        }
      )}
    >
      {children}
    </td>
  );
}

export default memo<IndexTableStickyCellProps>(IndexTableStickyCell);
