import {
  DownloadMaxFileGqlQuery,
  DownloadMaxFileGqlStatus,
  MaxFileDeviceNanoId,
  MaxFileGqlError,
  MaxFileUUID
} from '../../maxFilesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface DownloadMaxFileOptions {
  query: DownloadMaxFileGqlQuery;
}

export interface DownloadMaxFileResponse<DownloadMaxFileRecordType> {
  downloadMaxFile: {
    status: DownloadMaxFileGqlStatus;
    recordUuid: MaxFileUUID;
    record: DownloadMaxFileRecordType;
    errors: DownloadMaxFileError;
  };
}

export interface DownloadMaxFileInput {
  uuid: MaxFileUUID;
  deviceNanoId: MaxFileDeviceNanoId;
}

export interface DownloadMaxFileError {
  fullMessages: MaxFileGqlError;
}

const action = 'downloadMaxFile';

function useDownloadMaxFile<DownloadMaxFileRecordType>({
  query
}: DownloadMaxFileOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadMaxFileInput,
    DownloadMaxFileResponse<DownloadMaxFileRecordType>,
    DownloadMaxFileError,
    DownloadMaxFileRecordType
  >({ action, query });

  return {
    downloadMaxFileData: updateQueryData,
    downloadMaxFileError: updateQueryError,
    downloadMaxFileLoading: updateQueryLoading,
    downloadMaxFileErrorMessage: updateQueryErrorMessage,
    downloadMaxFile: updateQuery,
    downloadMaxFileReset: updateQueryReset
  };
}

export default useDownloadMaxFile;
