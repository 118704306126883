import React, { memo, useCallback, useState, useEffect } from 'react';
import cl from 'classnames';

import { ContainerElemType, ContentWrapperRefType } from '../../types';

import { useStickyBottomScrollSize } from '../../hooks/useStickyBottomScrollSize';
import { useStickyBottomScrollVisibility } from '../../hooks/useStickyBottomScrollVisibility';

import { SyncScroll } from '../../utils/SyncScroll';

interface StickyBottomScrollContainerScrollbarProps {
  containerElem?: ContainerElemType;
  contentWrapperRef: ContentWrapperRefType;
}

function StickyBottomScrollContainerScrollbar({
  containerElem,
  contentWrapperRef
}: StickyBottomScrollContainerScrollbarProps) {
  const [stickyScrollElem, setStickyScrollElem] = useState<HTMLElement>();

  const stickyScrollCallbackRef = useCallback(
    (elem: HTMLElement | null) => {
      if (elem) {
        setStickyScrollElem(elem);
      }
    },
    [setStickyScrollElem]
  );

  const { stickyScrollInnerStyle } = useStickyBottomScrollSize({
    contentWrapperRef
  });

  const { isStickyScrollbarVisible } = useStickyBottomScrollVisibility({
    containerElem
  });

  useEffect(() => {
    if (!containerElem || !stickyScrollElem) {
      return;
    }

    const synchronizer = new SyncScroll([containerElem, stickyScrollElem]);

    return () => {
      synchronizer.stop();
    };
  }, [containerElem, stickyScrollElem]);

  return (
    <>
      <div
        className={cl(
          'overflow-x-auto overflow-y-hidden sticky bottom-0 xl:border-r xl:border-l border-transparent',
          {
            invisible: !isStickyScrollbarVisible
          }
        )}
        ref={stickyScrollCallbackRef}
      >
        <div className="h-px" style={stickyScrollInnerStyle}></div>
      </div>
    </>
  );
}

export default memo(StickyBottomScrollContainerScrollbar);
