import { gql } from 'graphql-request';

import { MessageSortType, MessageUUID } from '../messagesTypes';

export interface UpdateMessageSortTypeRecordType {
  sortType: MessageSortType;
  uuid: MessageUUID;
}

export const UPDATE_MESSAGE_SORT_TYPE_QUERY = gql`
  mutation UpdateMessageSortType($uuid: ID!, $sortType: String!) {
    updateMessageSortType(input: { uuid: $uuid, sortType: $sortType }) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        uuid
        sortType
      }
    }
  }
`;
