import React, { ReactNode } from 'react';
import cl from 'classnames';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { Translate } from '../../../../../helpers/Translate';
import { Icon } from '../../../../../helpers/Icon';

interface IndexTableHeaderCellDefaultProps {
  addClassName?: ClassName;
  className?: ClassName;
  colSpan?: number;
  iconClassName?: ClassName;
  textAlignEnd?: boolean;
}

interface IndexTableHeaderCellWithTextProps {
  i18nText?: I18nText;
  icon?: IconsEnum | null;
  children?: never;
}

interface IndexTableHeaderCellWithChildrenProps {
  i18nText?: never;
  icon?: never;
  children: ReactNode;
}

type IndexTableHeaderCellProps = IndexTableHeaderCellDefaultProps &
  (IndexTableHeaderCellWithTextProps | IndexTableHeaderCellWithChildrenProps);

function IndexTableHeaderCell({
  addClassName = 'px-3',
  children,
  className,
  colSpan = 1,
  i18nText,
  icon,
  iconClassName,
  textAlignEnd = false
}: IndexTableHeaderCellProps) {
  return (
    <th
      scope="col"
      colSpan={colSpan}
      className={cl(
        className ||
          'py-2 group focus-within:shadow dark:focus-within:glow focus-within:bg-gray-100 dark:focus-within:bg-gray-800 z-10 dark:border-gray-700 border-gray-200 border-b',
        addClassName
      )}
    >
      <div
        className={cl(
          'flex items-center',
          textAlignEnd ? 'justify-end' : 'justify-between'
        )}
      >
        {i18nText || icon ? (
          <span className="text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
            {icon ? <Icon className={iconClassName} icon={icon} /> : null}
            {i18nText ? <Translate id={i18nText} /> : null}
          </span>
        ) : (
          children
        )}
      </div>
    </th>
  );
}

export default IndexTableHeaderCell;
