import React from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  ItemMessagesListSendingMessageMessageItem,
  ItemMessagesListSendingMessageOnRemoveSendingMessage
} from './ItemMessagesListSendingMessage.types';

import { useItemMessagesListSendingMessage } from './hooks/useItemMessagesListSendingMessage';

import { ItemMessagesListSendingMessageAttachments } from './components/ItemMessagesListSendingMessageAttachments';
import { ItemMessagesListMessageBody } from '../ItemMessagesListMessageBody';
import { ItemMessagesListSendingMessageMenu } from './components/ItemMessagesListSendingMessageMenu';

import { Icon } from '../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../helpers/Translate';
import { Loading } from '../../../../../../../helpers/Loading';

import { messagesKeys } from '../../../../../../../locales/keys';

interface ItemMessagesListSendingMessageProps {
  message: ItemMessagesListSendingMessageMessageItem;
  onRemoveSendingMessage?: ItemMessagesListSendingMessageOnRemoveSendingMessage;
}

function ItemMessagesListSendingMessage({
  message,
  onRemoveSendingMessage
}: ItemMessagesListSendingMessageProps) {
  const {
    attachmentsAmount,
    messageCreated,
    trySendAgainErrorMessage,
    isTrySendAgainLoading,
    handleTrySendAgain,
    handleRemoveSendingMessage
  } = useItemMessagesListSendingMessage({ message, onRemoveSendingMessage });

  if (!message.formData) {
    return null;
  }

  return (
    <div id={`message-${message.uuid}`} className="ml-12 sm:ml-16 mb-5 mt-5">
      <div className="flex">
        <div className="flex flex-col flex-1">
          <div className="text-xs mb-0.5 ml-2 text-gray-600 dark:text-gray-400">
            &nbsp;
          </div>
          <div className="w-full">
            <ItemMessagesListSendingMessageAttachments
              attachmentsAmount={attachmentsAmount}
            />
            <div className="group flex justify-end">
              <div>
                <ItemMessagesListMessageBody
                  message={message}
                  reverse
                  prevSameAuthor={false}
                  nextSameAuthor={false}
                />
              </div>
              <ItemMessagesListSendingMessageMenu
                isTrySendAgainLoading={isTrySendAgainLoading}
                onRemoveSendingMessage={
                  message.errorMessage &&
                  !isTrySendAgainLoading &&
                  !messageCreated
                    ? handleRemoveSendingMessage
                    : undefined
                }
                onTrySendAgain={
                  (message.task || message.project) &&
                  message.errorMessage &&
                  !isTrySendAgainLoading &&
                  !messageCreated
                    ? handleTrySendAgain
                    : undefined
                }
              />
            </div>

            {trySendAgainErrorMessage &&
            !isTrySendAgainLoading &&
            !messageCreated ? (
              <div className="text-sm mt-2 mr-3 flex items-center justify-end gap-1 text-red-500">
                <Icon icon={IconsEnum.EXCLAMATION_CIRCLE} className="h-4 w-4" />
                <span>{trySendAgainErrorMessage}</span>
              </div>
            ) : null}

            {message.errorMessage &&
            !trySendAgainErrorMessage &&
            !isTrySendAgainLoading &&
            !messageCreated ? (
              <div className="text-sm mt-2 mr-3 flex items-center justify-end gap-1 text-red-500">
                <Icon icon={IconsEnum.EXCLAMATION_CIRCLE} className="h-4 w-4" />
                <span>{message.errorMessage}</span>
              </div>
            ) : null}

            {!message.errorMessage ||
            isTrySendAgainLoading ||
            messageCreated ? (
              <div className="text-sm mt-2 mr-3 flex item-center justify-end gap-2">
                <span>
                  <Translate id={messagesKeys.sending} />
                </span>
                <Loading addClass="inline" size="xs" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemMessagesListSendingMessage;
