import { useMemo } from 'react';
import filter from 'lodash/filter';

import { ItemMessagesListResultsMessageResultFileFileAttachment } from '../../ItemMessagesListResultsMessageResultFile.types';
import { TaskNanoID } from '../../../../../../../../../../tasks/tasksTypes';
import { FetchMessagesCacheKey } from '../../../../../../../../../messagesTypes';

import { IconsEnum } from '../../../../../../../../../../../assets/icons/types';
import { useCurrentUser } from '../../../../../../../../../../../auth/hooks/useAuth';
import { SubmitSourceFilesToTaskModalButton } from '../../../../../../../../../../tasks/components/modalButtons/SubmitSourceFilesToTaskModalButton';
import { ModelViewerModalButton } from '../../../../../../../../../../modelViewers/components/modalButtons/ModelViewerModalButton';
import { RemoveFileAttachmentButton } from '../../../../../../../../../../fileAttachments/components/buttons/RemoveFileAttachmentButton';
import { TooltipDropdownHelper } from '../../../../../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { CheckPermissions } from '../../../../../../../../../../../helpers/CheckPermissions';

import { Files } from '../../../../../../../../../../../utils/Files';

import { MessagesPermissions } from '../../../../../../../../../messagesConstants';
import { ProjectsPermissions } from '../../../../../../../../../../projects/projectsConstants';
import { TasksPermissions } from '../../../../../../../../../../tasks/tasksConstants';
import { PopoverPlacement } from '../../../../../../../../../../../helpers/Popover/popoverConstants';
import {
  tasksKeys,
  words
} from '../../../../../../../../../../../locales/keys';

interface ItemMessagesListResultsMessageResultFileDropdownProps {
  fileAttachments: ItemMessagesListResultsMessageResultFileFileAttachment[];
  fileAttachment: ItemMessagesListResultsMessageResultFileFileAttachment;
  taskNanoId: TaskNanoID;
  messagesCacheKey: FetchMessagesCacheKey;
}

export function ItemMessagesListResultsMessageResultFileDropdown({
  fileAttachments,
  fileAttachment,
  taskNanoId,
  messagesCacheKey
}: ItemMessagesListResultsMessageResultFileDropdownProps) {
  const currentUser = useCurrentUser();

  const withSubmitSourceFilesButton = currentUser.hasPermissions(
    MessagesPermissions.READ_MESSAGE_RESULT_SUBMIT_SOURCE_FILES_BUTTON
  );

  const withRemoveButton =
    fileAttachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_REMOVE_FILE_ATTACHMENT_BUTTON
    );

  const models = useMemo(
    () => filter(fileAttachments, (item) => Files.isPreview3dModel(item.file)),
    [fileAttachments]
  );

  const withView3dModel = Files.isPreview3dModel(fileAttachment.file);

  if (!withSubmitSourceFilesButton && !withRemoveButton) {
    return null;
  }

  return (
    <TooltipDropdownHelper
      className="relative"
      buttonClassName="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
      iconClassName="h-6 w-6"
      dropdownPlacement={PopoverPlacement.BOTTOM_START}
      tooltipI18nText={words.more}
      tooltipSingleton
      closeOnOutOfView
    >
      {withView3dModel && (
        <CheckPermissions
          action={
            taskNanoId
              ? TasksPermissions.READ_TASKS_RESULTS_VIEW_3D_MODEL_BUTTON
              : ProjectsPermissions.READ_PROJECTS_RESULTS_VIEW_3D_MODEL_BUTTON
          }
        >
          <ModelViewerModalButton
            className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
            i18nText={words.view3DModel}
            initialModelIndex={models.findIndex(
              (file) => fileAttachment.id === file.id
            )}
            models={models}
            cameraControls
            withDownloadButton
          />
        </CheckPermissions>
      )}

      {withSubmitSourceFilesButton && (
        <SubmitSourceFilesToTaskModalButton
          taskNanoId={taskNanoId}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
          i18nText={tasksKeys.submitAsSourceFile}
          initialFile={fileAttachment}
        />
      )}

      {withRemoveButton && (
        <RemoveFileAttachmentButton
          cacheKeys={[messagesCacheKey]}
          fileAttachmentUuid={fileAttachment.uuid}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
          i18nText={words.remove}
        />
      )}
    </TooltipDropdownHelper>
  );
}

export default ItemMessagesListResultsMessageResultFileDropdown;
