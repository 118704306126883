import {
  DownloadImageGqlQuery,
  DownloadImageGqlStatus,
  ImageDeviceNanoId,
  ImageGqlError,
  ImageUUID
} from '../../imagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface DownloadImageOptions {
  query: DownloadImageGqlQuery;
}

export interface DownloadImageResponse<DownloadImageRecordType> {
  downloadImage: {
    status: DownloadImageGqlStatus;
    recordUuid: ImageUUID;
    record: DownloadImageRecordType;
    errors: DownloadImageError;
  };
}

export interface DownloadImageInput {
  uuid: ImageUUID;
  deviceNanoId: ImageDeviceNanoId;
}

export interface DownloadImageError {
  fullMessages: ImageGqlError;
}

const action = 'downloadImage';

function useDownloadImage<DownloadImageRecordType>({
  query
}: DownloadImageOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadImageInput,
    DownloadImageResponse<DownloadImageRecordType>,
    DownloadImageError,
    DownloadImageRecordType
  >({ action, query });

  return {
    downloadImageData: updateQueryData,
    downloadImageError: updateQueryError,
    downloadImageLoading: updateQueryLoading,
    downloadImageErrorMessage: updateQueryErrorMessage,
    downloadImage: updateQuery,
    downloadImageReset: updateQueryReset
  };
}

export default useDownloadImage;
