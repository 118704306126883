import { useCallback, useMemo } from 'react';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import isNull from 'lodash/isNull';
import every from 'lodash/every';
import values from 'lodash/values';
import isArray from 'lodash/isArray';

import {
  FetchMessagesFilterMessages,
  FetchMessagesFilters,
  MessageMessageTypes
} from '../../../../../messagesTypes';
import {
  ItemMessageFilterFormData,
  ItemMessageFilterFormOtherTypes
} from './useItemMessagesFilterForm.types';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useMessagesSettings } from '../../../../../hooks/useMessagesSettings';

interface useItemMessagesFilterFormProps {
  filterMessages: FetchMessagesFilterMessages;
  messagesFilters: FetchMessagesFilters;
}

function useItemMessagesFilterForm({
  filterMessages,
  messagesFilters
}: useItemMessagesFilterFormProps) {
  const { messagesSettings } = useMessagesSettings();

  const defaultValues = useMemo<ItemMessageFilterFormData>(() => {
    const other =
      (messagesFilters.withAttachments &&
        ItemMessageFilterFormOtherTypes.WITH_ATTACHMENTS) ||
      (messagesFilters.whiteboardKeyId?.isNotNull &&
        ItemMessageFilterFormOtherTypes.WHITEBOARD) ||
      (messagesFilters.messageType &&
        ItemMessageFilterFormOtherTypes.CRITICAL_REQUIREMENTS) ||
      null;

    return {
      userUuids: messagesFilters.userUuids || [],
      visible: messagesFilters.visibleForClient ?? null,
      other
    };
  }, [
    messagesFilters.messageType,
    messagesFilters.userUuids,
    messagesFilters.visibleForClient,
    messagesFilters.whiteboardKeyId,
    messagesFilters.withAttachments
  ]);

  const { control, handleSubmitReactHookForm, resetForm } =
    useReactHookForm<ItemMessageFilterFormData>({
      defaultValues,
      isModalForm: true
    });

  const resetFilterMessagesForm = useCallback<() => void>(() => {
    resetForm(defaultValues);
  }, [defaultValues, resetForm]);

  const clearFilterMessagesForm = useCallback<() => void>(() => {
    filterMessages(
      omit(messagesFilters, [
        'userUuids',
        'visibleForClient',
        'messageType',
        'whiteboardKeyId',
        'withAttachments'
      ])
    );
  }, [filterMessages, messagesFilters]);

  const isEmptyMessagesFilters = useMemo<boolean>(
    () =>
      every(values(defaultValues), (v) =>
        isArray(v) ? isEmpty(v) : isNull(v)
      ),
    [defaultValues]
  );

  return {
    control,
    resetFilterMessagesForm,
    handleSubmitReactHookForm,
    handleSubmitFilterMessagesForm: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: (data) => {
        const userUuids = isEmpty(data.userUuids) ? null : data.userUuids;
        const visibleForClient = data.visible ?? null;
        const withAttachments =
          data.other === ItemMessageFilterFormOtherTypes.WITH_ATTACHMENTS;

        const defaultWhiteboardKeyId = messagesSettings.withWhiteboardMessages
          ? null
          : { isNull: true };
        const whiteboardKeyId =
          data.other === ItemMessageFilterFormOtherTypes.WHITEBOARD
            ? { isNotNull: true }
            : defaultWhiteboardKeyId;

        const messageType =
          data.other === ItemMessageFilterFormOtherTypes.CRITICAL_REQUIREMENTS
            ? MessageMessageTypes.CLIENT_DESCRIPTION
            : null;

        filterMessages(
          pickBy(
            {
              ...messagesFilters,
              userUuids,
              visibleForClient,
              withAttachments,
              whiteboardKeyId,
              messageType
            },
            (value) => !isNull(value)
          )
        );
      }
    }),
    clearFilterMessagesForm,
    isEmptyMessagesFilters
  };
}

export default useItemMessagesFilterForm;
