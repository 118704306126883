import { useCallback, useEffect } from 'react';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

import { ItemMessagesListResultsMessageMenuDropdownUploadToLibraryButtonMessage } from '../../ItemMessagesListResultsMessageMenuDropdownUploadToLibraryButton.types';
import { CreateProductDefaultData } from '../../../../../../../../../../../../products/components/forms/CreateProductForm/CreateProductForm.types';

import {
  FETCH_CATEGORIES_SELECT_FIELD_QUERY,
  FetchCategoriesSelectFieldQueryResponse
} from '../../../../../../../../../../../../categories/queries/fetchCategoriesSelectField.query';

import { useLocalForageState } from '../../../../../../../../../../../../common/hooks/base/reactQuery/useLocalForageState';
import { useFinPaginatedCategories } from '../../../../../../../../../../../../categories/hooks/useFinPaginatedCategories';

import { Files } from '../../../../../../../../../../../../../utils/Files';

import { ProductCache } from '../../../../../../../../../../../../products/ProductCache';

import { CategoryCache } from '../../../../../../../../../../../../categories/CategoryCache';
import { FetchCategoriesScopes } from '../../../../../../../../../../../../categories/categoriesTypes';

type UploadToLibraryOptions = {
  message: ItemMessagesListResultsMessageMenuDropdownUploadToLibraryButtonMessage;
};

const STYLE_ID = '519';

function useUploadToLibrary({ message }: UploadToLibraryOptions) {
  const { setValue } = useLocalForageState<CreateProductDefaultData>(
    ProductCache.createProductDataCacheKey()
  );

  const teamName = message.task?.project?.team?.name;

  const {
    categories,
    categoriesFetched,
    categoriesFilters,
    changeCategoriesFilters
  } = useFinPaginatedCategories<FetchCategoriesSelectFieldQueryResponse>({
    cacheKey: CategoryCache.brandName(),
    query: FETCH_CATEGORIES_SELECT_FIELD_QUERY,
    initialFilters: {
      scope: { eq: FetchCategoriesScopes.FACILITY_MANUFACTURERS },
      name: {
        eq: teamName
      }
    },
    options: {
      withoutPrefetch: true,
      enabled: !!teamName,
      enabledPlaceholder: !!teamName
    }
  });

  useEffect(() => {
    if (teamName && teamName !== categoriesFilters.name?.eq) {
      changeCategoriesFilters({ name: { eq: teamName } });
    }
  }, [categoriesFilters.name?.eq, changeCategoriesFilters, teamName]);

  const imageAttachments = filter(message.fileAttachments, (file) =>
    Files.isImage(file.name)
  );
  const maxFileAttachment = filter(
    message.fileAttachments,
    (file) => !Files.isImage(file.name)
  );

  const setCreateProductData = useCallback<() => Promise<void>>(async () => {
    setValue({
      messageUuid: message.uuid,
      name: message.task.name,
      sku: message.task.name,
      clientIds:
        message.task.project?.owner?.id && message.task.project?.owner.client
          ? [message.task.project.owner.id]
          : [],
      styleId: STYLE_ID,
      nda: true,
      brandId: head(categories)?.id || null,
      taskId: message.task.id,
      imageAttachments: !isEmpty(imageAttachments) && imageAttachments,
      maxFileAttachment: !isEmpty(maxFileAttachment) && head(maxFileAttachment)
    });
  }, [categories, imageAttachments, maxFileAttachment, message, setValue]);

  return { setCreateProductData, disabled: !categoriesFetched && !!teamName };
}

export default useUploadToLibrary;
