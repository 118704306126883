import React from 'react';
import cl from 'classnames';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import size from 'lodash/size';

import { ButtonHelper } from '../../buttons/ButtonHelper';
import { Translate } from '../../Translate';

import { DropzoneTabFile, DropzoneTabItem } from './DropzoneTab.types';

interface DropzoneTabProps {
  allFiles: { [id: string]: DropzoneTabFile };
  active: boolean;
  item: DropzoneTabItem;
  onChange: (name: string) => void;
}

function DropzoneTab({ allFiles, active, item, onChange }: DropzoneTabProps) {
  const processingFilesCount = size(
    filter(
      keys(allFiles),
      (key) =>
        allFiles[key].state === 'processing' &&
        allFiles[key].activeTab === item.name
    )
  );

  const finishedFilesCount = size(
    filter(
      keys(allFiles),
      (key) =>
        allFiles[key].state === 'finished' &&
        allFiles[key].activeTab === item.name
    )
  );

  return (
    <ButtonHelper
      key={`dropzone-field-list-tab-${item.name}`}
      className={cl(
        'px-3 py-2 font-medium text-sm rounded-md focus:ring-offset-0',
        {
          'bg-gray-200 dark:bg-gray-800 dark:text-gray-200 text-gray-800':
            active,
          'text-gray-500 hover:text-gray-700 dark:hover:text-gray-300': !active
        }
      )}
      onClick={() => onChange(item.name)}
    >
      <Translate id={item.i18nText} />
      {processingFilesCount > 0 ? '...' : null}{' '}
      {finishedFilesCount > 0 ? `(${finishedFilesCount})` : null}
    </ButtonHelper>
  );
}

export default DropzoneTab;
