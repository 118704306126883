import React, { ReactNode } from 'react';

interface IndexTableBodyProps {
  children: ReactNode;
}

function IndexTableBody({ children }: IndexTableBodyProps) {
  return (
    <tbody className="dark:divide-gray-800 divide-gray-200 divide-y">
      {children}
    </tbody>
  );
}

export default IndexTableBody;
