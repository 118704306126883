import { gql } from 'graphql-request';

import { TodoItemID, TodoItemName, TodoItemUUID } from '../todoItemsTypes';

export interface CreateTodoItemInTaskQueryResponse {
  id: TodoItemID;
  uuid: TodoItemUUID;
  name: TodoItemName;
}

export const CREATE_TODO_ITEM_IN_TASK_QUERY = gql`
  mutation CreateTodoItemInTask(
    $name: String!
    $taskId: ID!
    $visibleForClients: Boolean
    $done: Boolean
  ) {
    createTodoItemInTask(
      input: {
        name: $name
        taskId: $taskId
        visibleForClients: $visibleForClients
        done: $done
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
        name
        visibleForClients
        done
        createdAt
        updatedAt
        userId
        user {
          uuid
          fullName
          image {
            uuid
            file
          }
        }
        taskId
        task {
          id
          uuid
          name
        }
      }
      errors {
        name
        taskId
        visibleForClients
        done
        fullMessages
      }
    }
  }
`;
