import React, { useCallback } from 'react';

import { FetchMessagesCacheKey } from '../../../../../../../../../messagesTypes';
import { ItemMessagesListResultsMessageItem } from '../../../../ItemMessagesListResultsMessage.types';

import {
  TOGGLE_MESSAGE_IS_RESULT_QUERY,
  ToggleMessageIsResultRecordType
} from '../../../../../../../../../queries/toggleMessageIsResult.query';
import {
  UPDATE_MESSAGE_RESULT_VERSION_QUERY,
  UpdateMessageResultVersionRecordType
} from '../../../../../../../../../queries/updateMessageResultVersion.query';

import { useToggleMessageIsResult } from '../../../../../../../../../hooks/useToggleMessageIsResult';
import { useUpdateMessageResultVersion } from '../../../../../../../../../hooks/useUpdateMessageResultVersion';
import { useShowToastOnErrorChange } from '../../../../../../../../../../../common/hooks/useShowToastOnErrorChange';

import { PureButtonHelper } from '../../../../../../../../../../../helpers/buttons/PureButtonHelper';

import { resultsKeys } from '../../../../../../../../../../../locales/keys';

import { TaskCache } from '../../../../../../../../../../tasks/TaskCache';

interface ItemMessagesListResultsMessageMenuDropdownIsResultButtonProps {
  messagesCacheKey: FetchMessagesCacheKey;
  message: ItemMessagesListResultsMessageItem;
}

function ItemMessagesListResultsMessageMenuDropdownIsResultButton({
  message,
  messagesCacheKey
}: ItemMessagesListResultsMessageMenuDropdownIsResultButtonProps) {
  const {
    toggleMessageIsResult,
    toggleMessageIsResultLoading,
    toggleMessageIsResultErrorMessage
  } = useToggleMessageIsResult<ToggleMessageIsResultRecordType>({
    cacheKeys: [
      TaskCache.showCacheKey(),
      TaskCache.indexCacheKey(),
      TaskCache.resultsWithoutVersionCacheKey(message.task?.nanoId),
      TaskCache.resultsCacheKey(message.task?.nanoId),
      TaskCache.lastMessagesWithResultCacheKey(message.task?.nanoId),
      messagesCacheKey
    ],
    query: TOGGLE_MESSAGE_IS_RESULT_QUERY
  });

  const {
    updateMessageResultVersion,
    updateMessageResultVersionLoading,
    updateMessageResultVersionErrorMessage
  } = useUpdateMessageResultVersion<UpdateMessageResultVersionRecordType>({
    query: UPDATE_MESSAGE_RESULT_VERSION_QUERY,
    cacheKeys: []
  });

  useShowToastOnErrorChange({
    error:
      toggleMessageIsResultErrorMessage ||
      updateMessageResultVersionErrorMessage
  });

  const handleToggleMessageVisibleForClient = useCallback<
    () => Promise<unknown>
  >(async () => {
    await updateMessageResultVersion({
      resultVersion: 0,
      uuid: message.uuid
    });

    toggleMessageIsResult({ uuid: message.uuid });
  }, [message.uuid, toggleMessageIsResult, updateMessageResultVersion]);

  return (
    <PureButtonHelper
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      disabled={
        toggleMessageIsResultLoading || updateMessageResultVersionLoading
      }
      i18nText={resultsKeys.cancel}
      onClick={handleToggleMessageVisibleForClient}
    />
  );
}

export default ItemMessagesListResultsMessageMenuDropdownIsResultButton;
