function findVerticalScrollContainer(
  element: HTMLElement
): HTMLElement | undefined {
  const parent = element.parentElement;

  if (!parent) {
    return undefined;
  }

  const isParentHasScroll =
    parent.scrollHeight > parent.clientHeight &&
    window.getComputedStyle(parent).overflowY !== 'hidden';

  if (isParentHasScroll) {
    return parent;
  }

  return findVerticalScrollContainer(parent);
}

export default findVerticalScrollContainer;
