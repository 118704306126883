import React from 'react';

import { MessageResultVersion } from '../../../../../../../messagesTypes';

import { Translate } from '../../../../../../../../../helpers/Translate';

import { resultsKeys } from '../../../../../../../../../locales/keys';

interface ItemMessagesListResultsMessageResultVersionProps {
  resultVersion: MessageResultVersion;
}

function ItemMessagesListResultsMessageResultVersion({
  resultVersion
}: ItemMessagesListResultsMessageResultVersionProps) {
  if (!resultVersion) {
    return null;
  }

  return (
    <div className="absolute top-2 left-2 flex gap-2 items-center">
      <span className="text-xs">
        <Translate id={resultsKeys.singular} /> {resultVersion}
      </span>
    </div>
  );
}

export default ItemMessagesListResultsMessageResultVersion;
