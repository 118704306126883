import { MessageUUID } from '../../messagesTypes';
import { UpdateItemsCacheKeys } from '../../../../types/updateItemsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleMessageIsResultOptions {
  cacheKeys: UpdateItemsCacheKeys;
  query: string;
}

interface ToggleMessageIsResultInput {
  uuid: MessageUUID;
}

interface ToggleMessageIsResultError {
  fullMessages: string[] | null;
}

interface ToggleMessageIsResultResponse<ToggleMessageIsResultRecordType> {
  toggleMessageIsResult: {
    status: string;
    record: ToggleMessageIsResultRecordType;
    errors: ToggleMessageIsResultError;
  };
}

const action = 'toggleMessageIsResult';

function useToggleMessageIsResult<ToggleMessageIsResultRecordType>({
  cacheKeys,
  query
}: ToggleMessageIsResultOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleMessageIsResultInput,
    ToggleMessageIsResultResponse<ToggleMessageIsResultRecordType>,
    ToggleMessageIsResultError,
    ToggleMessageIsResultRecordType
  >({ action, cacheKeys: cacheKeys, query });

  return {
    toggleMessageIsResultData: updateQueryData,
    toggleMessageIsResultError: updateQueryError,
    toggleMessageIsResultLoading: updateQueryLoading,
    toggleMessageIsResultErrorMessage: updateQueryErrorMessage,
    toggleMessageIsResult: updateQuery,
    toggleMessageIsResultReset: updateQueryReset
  };
}

export default useToggleMessageIsResult;
