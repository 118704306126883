import { CSSProperties, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';

import { ContentWrapperRefType } from '../../types';

interface UseStickyBottomScrollSizeOptions {
  contentWrapperRef: ContentWrapperRefType;
}

function useStickyBottomScrollSize({
  contentWrapperRef
}: UseStickyBottomScrollSizeOptions) {
  const { width: contentWidth } = useResizeObserver({
    ref: contentWrapperRef
  });

  const stickyScrollInnerStyle = useMemo<CSSProperties | undefined>(() => {
    return contentWidth ? { width: contentWidth } : undefined;
  }, [contentWidth]);

  return { stickyScrollInnerStyle };
}

export default useStickyBottomScrollSize;
