import { Control } from 'react-hook-form';

import {
  FetchUsersFetched,
  FetchUsersTotalCount
} from '../../../../users/usersTypes';
import { ItemMessagesFilterFormUsers } from './ItemMessagesFilterForm.types';
import { ItemMessageFilterFormData } from './hooks/useItemMessagesFilterForm/useItemMessagesFilterForm.types';

import { ItemMessagesFilterFormVisibility } from './components/ItemMessagesFilterFormVisibility';
import { ItemMessagesFilterFormAuthor } from './components/ItemMessagesFilterFormAuthor';
import { ItemMessagesFilterFormOther } from './components/ItemMessagesFilterFormOther';

import { Form } from '../../../../../helpers/Form';

interface ItemMessagesFilterFormProps {
  form: string;
  control: Control<ItemMessageFilterFormData>;
  users: ItemMessagesFilterFormUsers;
  usersFetched: FetchUsersFetched;
  usersTotalCount: FetchUsersTotalCount;
}

function ItemMessagesFilterForm({
  form,
  control,
  users,
  usersFetched,
  usersTotalCount
}: ItemMessagesFilterFormProps) {
  return (
    <Form id={form} className="p-4 space-y-8">
      <ItemMessagesFilterFormVisibility control={control} />

      <ItemMessagesFilterFormAuthor
        control={control}
        users={users}
        usersFetched={usersFetched}
        usersTotalCount={usersTotalCount}
      />

      <ItemMessagesFilterFormOther control={control} />
    </Form>
  );
}

export default ItemMessagesFilterForm;
