import React, { memo } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';

import { IndexTableStickyCell } from '../IndexTableStickyCell';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

interface IndexTableRowEditButtonProps {
  onClick: () => void;
  onMouseEnter?: () => void;
}

function IndexTableRowEditButton({
  onClick,
  onMouseEnter
}: IndexTableRowEditButtonProps) {
  return (
    <IndexTableStickyCell isRight>
      <PureIconButtonHelper
        className="absolute dark:hover:bg-gray-700 dark:focus:bg-gray-700  flex focus:ring-offset-0 focus:ring-0 hover:bg-gray-200 focus:bg-gray-200 inset-0 items-center justify-center p-2 text-gray-500 hover:text-gray-800 focus:text-gray-800 dark:hover:text-gray-200 dark:focus:text-gray-200 w-full"
        icon={IconsEnum.PENCIL}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
      />
    </IndexTableStickyCell>
  );
}

export default memo<IndexTableRowEditButtonProps>(IndexTableRowEditButton);
