import React, { memo } from 'react';
import { Trans } from 'react-i18next';

import { ItemMessagesListPaymentProcessingInvoiceMessageItem } from './ItemMessagesListPaymentProcessingInvoiceMessage.types';

import { Loading } from '../../../../../../../helpers/Loading';
import { MoneyHelper } from '../../../../../../../helpers/MoneyHelper';
import { InvoiceIdLink } from '../../../../../../invoices/helpers/InvoiceIdLink';

import { getCurrencyPrefix } from '../../../../../../currencyRates/utils/getCurrencyPrefix';
import { invoicesKeys } from '../../../../../../../locales/keys';

interface ItemMessagesListPaymentProcessingInvoiceMessageProps {
  message: ItemMessagesListPaymentProcessingInvoiceMessageItem;
}

function ItemMessagesListPaymentProcessingInvoiceMessage({
  message
}: ItemMessagesListPaymentProcessingInvoiceMessageProps) {
  const invoice = message.bitInvoice;

  const amountDue = invoice?.amount;

  const currencyExchangeRate = invoice?.selectedCurrencyRate || 1;
  const currencyPrefix = getCurrencyPrefix(invoice?.selectedCurrency);

  return (
    <div
      id={`message-${message.uuid}`}
      className="text-center text-xs text-gray-500 dark:text-gray-400 mt-6 mb-4"
    >
      <Loading loaded={message.bitInvoiceFetched}>
        <div className="text-center">
          <span>
            <Trans
              i18nKey={invoicesKeys.paymentForIsProcessing}
              components={{
                invoiceId: <InvoiceIdLink invoice={invoice} prefix="#" />,
                invoiceAmount: (
                  <MoneyHelper
                    value={amountDue * currencyExchangeRate || 0}
                    currency={currencyPrefix}
                  />
                )
              }}
            />
          </span>
        </div>
      </Loading>
    </div>
  );
}

export default memo<ItemMessagesListPaymentProcessingInvoiceMessageProps>(
  ItemMessagesListPaymentProcessingInvoiceMessage
);
