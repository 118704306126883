import {
  FetchTodoItemsInProjectCacheKey,
  FetchTodoItemInProjectCacheKey
} from './todoItemsTypes';

import { ProjectNanoID } from '../projects/projectsTypes';

export class ProjectTodoItemsCache {
  static indexCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-todo-items` as FetchTodoItemsInProjectCacheKey;
  }

  static showCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-todo-item` as FetchTodoItemInProjectCacheKey;
  }
}
