import { ItemMessagesListReturnReasonInvoiceMessageItem } from './ItemMessagesListReturnReasonInvoiceMessage.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';
import { invoiceReturnReasonTexts } from '../../../../../../invoices/invoicesTypes';

import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';

import { Icon } from '../../../../../../../helpers/Icon';
import { MoneyHelper } from '../../../../../../../helpers/MoneyHelper';
import { PureLinkHelper } from '../../../../../../../helpers/links/PureLinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';

import { InvoicePath } from '../../../../../../invoices/InvoicePath';

import { invoicesKeys } from '../../../../../../../locales/keys';

interface ItemMessagesListReturnReasonInvoiceMessageProps {
  message: ItemMessagesListReturnReasonInvoiceMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
}
function ItemMessagesListReturnReasonInvoiceMessage({
  message,
  messagesCacheKey
}: ItemMessagesListReturnReasonInvoiceMessageProps) {
  const invoice = message.bitInvoice;

  const invoiceId = invoice?.clientId || invoice?.id;

  const invoiceReturnReason = invoice?.returnReason;

  const invoiceReturnReasonText = invoiceReturnReasonTexts[invoiceReturnReason];

  return (
    <div id={`message-${message.uuid}`} className="mb-10 p-2">
      <div className="bg-gray-100 dark:bg-gray-850 border dark:border-gray-700 rounded-lg p-4">
        <div className="flex gap-4">
          <div>
            <LoadingSkeleton
              className="m-0"
              itemClassName="w-10 h-10"
              loaded={message.bitInvoiceFetched}
              count={1}
            >
              <Icon
                icon={IconsEnum.CASH_OUTLINE}
                className="h-10 w-10"
                strokeWidth="1"
              />
            </LoadingSkeleton>
          </div>

          <div className="flex-1">
            <div className="font-semibold mb-1">
              <LoadingSkeleton
                className="m-0"
                loaded={message.bitInvoiceFetched}
                count={1}
              >
                <Translate id={invoicesKeys.singular} />
                &nbsp;
                {`#${invoiceId}`}
              </LoadingSkeleton>
            </div>

            <LoadingSkeleton
              className="m-0 text-xs"
              loaded={message.bitInvoiceFetched}
              count={3}
            >
              <div className="text-xs">
                <Translate
                  id={invoicesKeys.messages.invoiceHasBeen[invoiceReturnReason]}
                />{' '}
                <span className="font-bold">
                  <MoneyHelper value={invoice?.amount} />
                </span>
                .
              </div>

              <div className="flex gap-3 mt-1">
                <PureLinkHelper
                  className="text-xs dark:text-gray-300 text-gray-700 underline"
                  href={InvoicePath.paymentsInvoice(invoice?.nanoId)}
                  i18nText={invoicesKeys.view}
                />

                <ItemMessagesListMessageSendEmailNotificationButton
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                  className="flex items-center"
                />
              </div>
            </LoadingSkeleton>
          </div>

          <LoadingSkeleton
            className="m-0 self-start"
            itemClassName="w-20 h-9 rounded-full"
            loaded={message.bitInvoiceFetched}
            count={1}
          >
            <div className="px-4 py-1.5 rounded-full self-start text-red-800 bg-red-200">
              {invoiceReturnReasonText}
            </div>
          </LoadingSkeleton>
        </div>
      </div>
    </div>
  );
}

export default ItemMessagesListReturnReasonInvoiceMessage;
