import {
  action,
  DownloadMaterialResponse,
  DownloadMaterialInput,
  DownloadMaterialError,
  DOWNLOAD_MATERIAL_QUERY
} from '../../queries/downloadMaterial.query';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { DownloadCache } from '../../../downloads/DownloadCache';

function useDownloadMaterial() {
  const { updateQuery, updateQueryLoading, updateQueryErrorMessage } =
    useUpdateQuery<
      DownloadMaterialInput,
      DownloadMaterialResponse,
      DownloadMaterialError,
      undefined
    >({
      action,
      query: DOWNLOAD_MATERIAL_QUERY,
      cacheKeys: [DownloadCache.indexCacheKey()]
    });

  return {
    downloadMaterialLoading: updateQueryLoading,
    downloadMaterialErrorMessage: updateQueryErrorMessage,
    downloadMaterial: updateQuery
  };
}

export default useDownloadMaterial;
