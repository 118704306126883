import { gql } from 'graphql-request';

import { MessageIsResult } from '../messagesTypes';

export interface ToggleMessageIsResultRecordType {
  isResult: MessageIsResult;
}

export const TOGGLE_MESSAGE_IS_RESULT_QUERY = gql`
  mutation ToggleMessageIsResult($uuid: ID!) {
    toggleMessageIsResult(input: { uuid: $uuid }) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        isResult
      }
    }
  }
`;
