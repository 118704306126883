import React, { useCallback } from 'react';

import { ItemMessagesListResultsMessageItem } from '../../../../ItemMessagesListResultsMessage.types';

import {
  SEND_RESULTS_TO_CLIENT_IN_TASK_QUERY,
  SendResultsToClientInTaskQueryResponse
} from '../../../../../../../../../../tasks/queries/sendResultsToClientInTask.query';

import { PureButtonHelper } from '../../../../../../../../../../../helpers/buttons/PureButtonHelper';

import { useToastNotification } from '../../../../../../../../../../../common/hooks/useToastNotification';
import { useSendResultsToClientInTask } from '../../../../../../../../../../tasks/hooks/useSendResultsToClientInTask';
import { useShowToastOnErrorChange } from '../../../../../../../../../../../common/hooks/useShowToastOnErrorChange';

import {
  messagesKeys,
  words
} from '../../../../../../../../../../../locales/keys';

interface ItemMessagesListResultsMessageMenuDropdownSendResultsToClientButtonProps {
  message: ItemMessagesListResultsMessageItem;
}

function ItemMessagesListResultsMessageMenuDropdownSendResultsToClientButton({
  message
}: ItemMessagesListResultsMessageMenuDropdownSendResultsToClientButtonProps) {
  const { showToastI18nNotification } = useToastNotification({
    appearance: 'success',
    i18nMessage: messagesKeys.sent
  });

  const {
    sendResultsToClientInTask,
    sendResultsToClientInTaskErrorMessage,
    sendResultsToClientInTaskLoading
  } = useSendResultsToClientInTask<SendResultsToClientInTaskQueryResponse>({
    query: SEND_RESULTS_TO_CLIENT_IN_TASK_QUERY
  });

  useShowToastOnErrorChange({ error: sendResultsToClientInTaskErrorMessage });

  const handleSendResultsToClientInTask = useCallback<() => void>(
    () =>
      sendResultsToClientInTask({ uuid: message.task.nanoId }).then(() =>
        showToastI18nNotification()
      ),
    [message.task.nanoId, sendResultsToClientInTask, showToastI18nNotification]
  );

  return (
    <PureButtonHelper
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      i18nText={words.sendViaEmail}
      disabled={sendResultsToClientInTaskLoading}
      onClick={handleSendResultsToClientInTask}
    />
  );
}

export default ItemMessagesListResultsMessageMenuDropdownSendResultsToClientButton;
