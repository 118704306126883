import {
  action,
  DownloadProductResponse,
  DownloadProductInput,
  DownloadProductError,
  DOWNLOAD_PRODUCT_QUERY
} from '../../queries/downloadProduct.query';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { DownloadCache } from '../../../downloads/DownloadCache';

function useDownloadProduct() {
  const { updateQuery, updateQueryLoading, updateQueryErrorMessage } =
    useUpdateQuery<
      DownloadProductInput,
      DownloadProductResponse,
      DownloadProductError,
      undefined
    >({
      action,
      query: DOWNLOAD_PRODUCT_QUERY,
      cacheKeys: [DownloadCache.indexCacheKey()]
    });

  return {
    downloadProductLoading: updateQueryLoading,
    downloadProductErrorMessage: updateQueryErrorMessage,
    downloadProduct: updateQuery
  };
}

export default useDownloadProduct;
