import { useState, useEffect, useCallback } from 'react';

import { FixedThead } from './utils/FixedThead';

function useFixedThead() {
  const [theadElem, setTheadElem] = useState<HTMLTableSectionElement>();

  const theadCallbackRef = useCallback(
    (elem: HTMLTableSectionElement | null) => {
      if (elem) {
        setTheadElem(elem);
      }
    },
    [setTheadElem]
  );

  useEffect(() => {
    if (!theadElem) {
      return;
    }

    const fixedTheadInstance = new FixedThead(theadElem);
    return () => {
      fixedTheadInstance.stop();
    };
  }, [theadElem]);

  return { theadCallbackRef };
}

export default useFixedThead;
