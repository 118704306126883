import React from 'react';

import { TaskStatuses } from '../../../../../../../../tasks/tasksTypes';
import { ItemMessagesListResultsMessageContentItem } from './ItemMessagesListResultsMessageContent.types';

import { ItemMessagesListResultsMessageContentDoneStatus } from './components/ItemMessagesListResultsMessageContentDoneStatus';
import { ItemMessagesListResultsMessageContentQaStatus } from './components/ItemMessagesListResultsMessageContentQaStatus';
import { ItemMessagesListResultsMessageContentRFAStatus } from './components/ItemMessagesListResultsMessageContentRFAStatus';

interface ItemMessagesListResultsMessageContentProps {
  message: ItemMessagesListResultsMessageContentItem;
  isFinBlock?: boolean;
}

function ItemMessagesListResultsMessageContent({
  message,
  isFinBlock
}: ItemMessagesListResultsMessageContentProps) {
  const task = message.task;

  if (!task) {
    return null;
  }

  switch (task.status) {
    case TaskStatuses.QUALITY_CONTROL:
      return (
        <ItemMessagesListResultsMessageContentQaStatus
          task={task}
          withoutClose={isFinBlock}
        />
      );

    case TaskStatuses.READY_FOR_ACCEPTANCE:
      return (
        <ItemMessagesListResultsMessageContentRFAStatus
          task={task}
          withoutClose={isFinBlock}
          withoutAccept={!isFinBlock}
        />
      );

    case TaskStatuses.DONE:
      return <ItemMessagesListResultsMessageContentDoneStatus task={task} />;

    default:
      return null;
  }
}

export default ItemMessagesListResultsMessageContent;
