import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import ceil from 'lodash/ceil';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';

import { PAGE_ITEMS_LIMIT } from '../../../../../../common/constants';

interface PaginationFormData {
  page?: number;
}

interface PaginationFormOptions {
  itemsPerPageLimit?: number;
  onPaginate: (page: number) => void;
  page?: number;
  totalItemsCount: number;
}

function usePaginationForm({
  itemsPerPageLimit = PAGE_ITEMS_LIMIT,
  onPaginate,
  page = 1,
  totalItemsCount = 0
}: PaginationFormOptions) {
  const totalPagesCount = ceil(totalItemsCount / itemsPerPageLimit);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<PaginationFormData>({
    defaultValues: {
      page
    }
  });

  useEffect(() => {
    setValue('page', page);
  }, [page, setValue]);

  const handleNavigatePage = useMemo(
    () => handleSubmit(({ page }) => onPaginate(toNumber(page))),
    [handleSubmit, onPaginate]
  );

  return {
    registerPage: useMemo(
      () =>
        register('page', {
          validate: {
            isValid: (value: number) =>
              (value > 0 && value < totalPagesCount + 1) ||
              'forms.errors.page.valid'
          }
        }),
      [register, totalPagesCount]
    ),
    pageValidationError: get(errors, 'page.message'),
    canGoFirst: page !== 1 && page > 1,
    canGoNext: page < totalPagesCount,
    canGoPrev: page > 1,
    canGoLast: page !== totalPagesCount && page < totalPagesCount,
    totalPagesCount,
    pageFirstItemNumber: (page - 1) * itemsPerPageLimit + 1,
    pageLastItemNumber:
      page * itemsPerPageLimit > totalItemsCount
        ? totalItemsCount
        : page * itemsPerPageLimit,
    navigateNextPage: useCallback(
      () => onPaginate(page + 1),
      [page, onPaginate]
    ),
    navigateFirstPage: useCallback(() => onPaginate(1), [onPaginate]),
    navigatePrevPage: useCallback(
      () => onPaginate(page - 1),
      [page, onPaginate]
    ),
    navigateLastPage: useCallback(
      () => onPaginate(totalPagesCount),
      [totalPagesCount, onPaginate]
    ),
    navigatePage: handleNavigatePage
  };
}

export default usePaginationForm;
