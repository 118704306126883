import React from 'react';

import { ItemMessagesListResultsMessageMenuDropdownUploadToLibraryButtonMessage } from './ItemMessagesListResultsMessageMenuDropdownUploadToLibraryButton.types';

import { useUploadToLibrary } from './hooks/useUploadToLibrary';

import { NextLinkHelper } from '../../../../../../../../../../../helpers/links/NextLinkHelper';
import { Translate } from '../../../../../../../../../../../helpers/Translate';

import { resultsKeys } from '../../../../../../../../../../../locales/keys';
import { ProductPath } from '../../../../../../../../../../products/ProductPath';

interface ItemMessagesListResultsMessageMenuDropdownUploadToLibraryButtonProps {
  message: ItemMessagesListResultsMessageMenuDropdownUploadToLibraryButtonMessage;
}

function ItemMessagesListResultsMessageMenuDropdownUploadToLibraryButton({
  message
}: ItemMessagesListResultsMessageMenuDropdownUploadToLibraryButtonProps) {
  const { setCreateProductData, disabled } = useUploadToLibrary({
    message
  });

  return disabled ? (
    <span className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap cursor-default">
      <Translate id={resultsKeys.uploadToLibrary} />
    </span>
  ) : (
    <NextLinkHelper
      id="upload-to-library"
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      onClick={setCreateProductData}
      href={ProductPath.create()}
      target="_blank"
    >
      <Translate id={resultsKeys.uploadToLibrary} />
    </NextLinkHelper>
  );
}

export default ItemMessagesListResultsMessageMenuDropdownUploadToLibraryButton;
