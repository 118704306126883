import React from 'react';

import { MessageCreatedAt, MessageUUID } from '../../../messagesTypes';

import { ItemMessagesListMessageCreatedAt } from '../ItemMessagesListMessage/components/ItemMessagesListMessageCreatedAt';

export type ItemMessagesListMessageWrapperProps = {
  message: {
    uuid: MessageUUID;
    createdAt: MessageCreatedAt;
  };
  showDate?: boolean;
  toggleShowDate?: () => void;
  children?: React.ReactNode;
};

function ItemMessagesListMessageWrapper({
  message,
  showDate,
  toggleShowDate,
  children
}: ItemMessagesListMessageWrapperProps) {
  return (
    <>
      {showDate ? (
        <ItemMessagesListMessageCreatedAt
          uuid={message.uuid}
          createdAt={message.createdAt}
          onClick={toggleShowDate}
        />
      ) : null}

      {children}
    </>
  );
}

export default ItemMessagesListMessageWrapper;
