import React, { useCallback } from 'react';

import { ItemMessagesListResultsMessageItem } from '../../../../ItemMessagesListResultsMessage.types';

import {
  DOWNLOAD_TASK_RESULTS_QUERY,
  DownloadTaskResultsQueryResponse
} from '../../../../../../../../../../tasks/queries/downloadTaskResults.query';

import { useDownloadNanoId } from '../../../../../../../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadTaskResults } from '../../../../../../../../../../tasks/hooks/useDownloadTaskResults';
import { useShowToastOnErrorChange } from '../../../../../../../../../../../common/hooks/useShowToastOnErrorChange';

import { PureButtonHelper } from '../../../../../../../../../../../helpers/buttons/PureButtonHelper';

import { words } from '../../../../../../../../../../../locales/keys';

interface ItemMessagesListResultsMessageMenuDropdownDownloadButtonProps {
  message: ItemMessagesListResultsMessageItem;
}

function ItemMessagesListResultsMessageMenuDropdownDownloadButton({
  message
}: ItemMessagesListResultsMessageMenuDropdownDownloadButtonProps) {
  const { downloadNanoId } = useDownloadNanoId();

  const {
    downloadTaskResults,
    downloadTaskResultsErrorMessage,
    downloadTaskResultsLoading
  } = useDownloadTaskResults<DownloadTaskResultsQueryResponse>({
    query: DOWNLOAD_TASK_RESULTS_QUERY
  });

  useShowToastOnErrorChange({ error: downloadTaskResultsErrorMessage });

  const handleDownloadTaskResults = useCallback<() => void>(
    () =>
      downloadTaskResults({
        uuid: message.task.nanoId,
        deviceNanoId: downloadNanoId
      }),
    [message.task.nanoId, downloadNanoId, downloadTaskResults]
  );

  return (
    <PureButtonHelper
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      i18nText={words.download}
      disabled={downloadTaskResultsLoading}
      onClick={handleDownloadTaskResults}
    />
  );
}

export default ItemMessagesListResultsMessageMenuDropdownDownloadButton;
