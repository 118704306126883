import React, { useCallback, useMemo } from 'react';

import { IconsEnum } from '../../../../../../../../../../../assets/icons/types';
import { PositionStyleEnum } from '../../../../../../../../../../../types';
import {
  FetchMessagesCacheKey,
  MessageSortType,
  MessageSortTypeTypes
} from '../../../../../../../../../messagesTypes';
import { ItemMessagesListResultsMessageItem } from '../../../../ItemMessagesListResultsMessage.types';

import { UPDATE_MESSAGE_SORT_TYPE_QUERY } from '../../../../../../../../../queries/updateMessageSortType.query';

import { useShowToastOnErrorChange } from '../../../../../../../../../../../common/hooks/useShowToastOnErrorChange';
import { useUpdateMessageSortType } from '../../../../../../../../../hooks/useUpdateMessageSortType';
import { useDropdownState } from '../../../../../../../../../../../helpers/dropdowns/hooks/useDropdownState';

import { PopoverPlacement } from '../../../../../../../../../../../helpers/Popover/popoverConstants';
import { RefButtonHelper } from '../../../../../../../../../../../helpers/buttons/RefButtonHelper';
import { Icon } from '../../../../../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../../../../../helpers/Translate';
import { Popover } from '../../../../../../../../../../../helpers/Popover';
import { ButtonHelper } from '../../../../../../../../../../../helpers/buttons/ButtonHelper';

import { words } from '../../../../../../../../../../../locales/keys';

const BUTTON_ID = 'results-message-sort-type-button';
const dropdownMenuOffset = 12;

const defaultSortType = MessageSortTypeTypes.NAME_ASC;

const sortTypes = [
  MessageSortTypeTypes.NAME_ASC,
  MessageSortTypeTypes.NAME_DESC,
  MessageSortTypeTypes.CREATED_AT_DESC,
  MessageSortTypeTypes.CREATED_AT_ASC,
  MessageSortTypeTypes.SIZE_ASC,
  MessageSortTypeTypes.SIZE_DESC
];

const sortTypesI18nText = {
  [MessageSortTypeTypes.NAME_ASC]: words.name,
  [MessageSortTypeTypes.NAME_DESC]: words.name,
  [MessageSortTypeTypes.CREATED_AT_DESC]: words.date,
  [MessageSortTypeTypes.CREATED_AT_ASC]: words.date,
  [MessageSortTypeTypes.SIZE_ASC]: words.size,
  [MessageSortTypeTypes.SIZE_DESC]: words.size
};

interface ItemMessagesListResultsMessageMenuSortTypeButtonProps {
  message: ItemMessagesListResultsMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
}

function ItemMessagesListResultsMessageMenuSortTypeButton({
  message,
  messagesCacheKey
}: ItemMessagesListResultsMessageMenuSortTypeButtonProps) {
  const {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef,
    togglePopover,
    closePopover
  } = useDropdownState();

  const messageSortType = useMemo<MessageSortType>(
    () => (message.sortType ? message.sortType : defaultSortType),
    [message?.sortType]
  );

  const {
    updateMessageSortType,
    updateMessageSortTypeErrorMessage,
    updateMessageSortTypeLoading
  } = useUpdateMessageSortType({
    indexCacheKey: messagesCacheKey,
    query: UPDATE_MESSAGE_SORT_TYPE_QUERY
  });

  const handleUpdateMessageSortType = useCallback(
    (sortType: MessageSortType) => {
      updateMessageSortType({
        uuid: message.uuid,
        sortType
      });
    },
    [message.uuid, updateMessageSortType]
  );

  useShowToastOnErrorChange({ error: updateMessageSortTypeErrorMessage });

  return (
    <div className="relative flex items-center justify-center" id={BUTTON_ID}>
      <RefButtonHelper
        className="py-1.5 px-4 space-x-1 space-x-reverse flex-row-reverse rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
        onClick={togglePopover}
        ref={referencePopoverCallbackRef}
      >
        <Icon
          icon={
            messageSortType.includes('ASC')
              ? IconsEnum.SORT_ASC
              : IconsEnum.SORT_DESC
          }
          className="h-5 w-5"
        />

        <span>
          <Translate id={words.sortBy} />
          : <Translate id={sortTypesI18nText[messageSortType]} />
        </span>
      </RefButtonHelper>

      {isPopoverOpen ? (
        <Popover
          className="min-w-48 z-30 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700"
          closePopover={closePopover}
          distanceOffset={dropdownMenuOffset}
          positionStyle={PositionStyleEnum.fixed}
          placement={PopoverPlacement.BOTTOM_END}
          referenceElement={referencePopoverElement}
        >
          {sortTypes.map(
            (sortType) =>
              sortType !== messageSortType && (
                <ButtonHelper
                  key={sortType}
                  className="flex items-center text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
                  onClick={() => {
                    handleUpdateMessageSortType(sortType);
                  }}
                  disabled={updateMessageSortTypeLoading}
                >
                  <div className="mr-2">
                    <Icon
                      icon={
                        sortType.includes('ASC')
                          ? IconsEnum.SORT_ASC
                          : IconsEnum.SORT_DESC
                      }
                      className="h-5 w-5"
                    />
                  </div>
                  <Translate id={sortTypesI18nText[sortType]} />
                </ButtonHelper>
              )
          )}
        </Popover>
      ) : null}
    </div>
  );
}

export default ItemMessagesListResultsMessageMenuSortTypeButton;
