import { useMemo } from 'react';
import filter from 'lodash/filter';
import first from 'lodash/first';
import drop from 'lodash/drop';

import { useCurrentUser } from '../../../../../../../../../../../auth/hooks/useAuth';

import {
  buttonPermissions,
  ItemMessagesListResultsMessageContentButtonTypes
} from '../../ItemMessagesListResultsMessageContent.types';

interface ItemMessagesListResultsMessageContentButtonsOptions {
  buttons: ItemMessagesListResultsMessageContentButtonTypes[];
}

function useItemMessagesListResultsMessageContentButtons({
  buttons
}: ItemMessagesListResultsMessageContentButtonsOptions) {
  const currentUser = useCurrentUser();

  const filteredButtons = useMemo<
    ItemMessagesListResultsMessageContentButtonTypes[]
  >(
    () =>
      filter<ItemMessagesListResultsMessageContentButtonTypes>(
        buttons,
        (button) => currentUser.hasPermissions(buttonPermissions[button])
      ),
    [currentUser, buttons]
  );

  const mainButton =
    first<ItemMessagesListResultsMessageContentButtonTypes>(filteredButtons);
  const dropdownButtons = useMemo<
    ItemMessagesListResultsMessageContentButtonTypes[]
  >(() => drop(filteredButtons), [filteredButtons]);

  return { mainButton, dropdownButtons };
}

export default useItemMessagesListResultsMessageContentButtons;
