import React, { Fragment, memo, useState } from 'react';

import { FetchMessagesCacheKey } from '../../../../../messagesTypes';
import { ItemMessagesListVersionMessageItem } from './ItemMessagesListVersionMessage.types';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { MessageMenuVFCButton } from '../ItemMessagesListMessageMenu/components/MessageMenuVFCButton';
import { UpdateMessageVersionModalButton } from '../../../../modalButtons/UpdateMessageVersionModalButton';

import { DateTimeHelper } from '../../../../../../../helpers/DateTimeHelper';
import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { MessagesPermissions } from '../../../../../messagesConstants';

interface ItemMessagesListVersionMessageProps {
  message: ItemMessagesListVersionMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
}

function ItemMessagesListVersionMessage({
  message,
  messagesCacheKey
}: ItemMessagesListVersionMessageProps) {
  const currentUser = useCurrentUser();

  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  return (
    <Fragment>
      <div
        id={`message-${message.uuid}`}
        className="-mx-4 border-b-4 border-double border-orange-500 mb-4 px-4 py-0.5 text-center text-orange-500 text-sm"
        ref={setReferenceTooltipElement}
      >
        <div className="group flex justify-center items-center">
          {message.task?.nanoId ? (
            <UpdateMessageVersionModalButton
              messageUuid={message.uuid}
              versionName={message.body}
              taskNanoId={message.task.nanoId}
              i18nText={message.body}
              className="font-medium"
              disabled={
                !currentUser.hasPermissions(
                  MessagesPermissions.CHANGE_MESSAGE_VERSION
                )
              }
            />
          ) : (
            <span className="font-medium">{message.body}</span>
          )}

          <div className="flex sm:opacity-0 group-hover:opacity-100 focus-within:opacity-100 ml-2">
            <CheckPermissions
              action={
                MessagesPermissions.READ_MESSAGE_VERSION_VISIBLE_FOR_CLIENTS_BUTTON
              }
            >
              <MessageMenuVFCButton
                message={message}
                messagesCacheKey={messagesCacheKey}
              />
            </CheckPermissions>
          </div>
        </div>
      </div>
      <Tooltip
        withArrow
        placement={TooltipPlacement.TOP}
        referenceElement={referenceTooltipElement}
      >
        <DateTimeHelper date={message.createdAt} />
      </Tooltip>
    </Fragment>
  );
}

export default memo<ItemMessagesListVersionMessageProps>(
  ItemMessagesListVersionMessage
);
