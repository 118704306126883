import { gql } from 'graphql-request';

import {
  DownloadProductGqlStatus,
  ProductDeviceNanoId,
  DownloadProductGqlError,
  ProductUUID
} from '../productsTypes';

export interface DownloadProductResponse {
  downloadProduct: {
    status: DownloadProductGqlStatus;
    errors: DownloadProductError;
  };
}

export interface DownloadProductInput {
  uuid: ProductUUID;
  deviceNanoId: ProductDeviceNanoId;
}

export interface DownloadProductError {
  fullMessages: DownloadProductGqlError;
}

export const action = 'downloadProduct';

export const DOWNLOAD_PRODUCT_QUERY = gql`
  mutation DownloadProduct($uuid: ID!, $deviceNanoId: String!) {
    downloadProduct(input: { uuid: $uuid, deviceNanoId: $deviceNanoId }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
