import { ProjectNanoID } from '../../../projects/projectsTypes';

import {
  CreateTodoItemInProjectGqlQuery,
  CreateTodoItemInProjectGqlStatus,
  TodoItemDone,
  TodoItemGqlError,
  TodoItemName,
  TodoItemProjectID,
  TodoItemUUID,
  TodoItemVisibleForClients
} from '../../todoItemsTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

import { ProjectTodoItemsCache } from '../../ProjectTodoItemsCache';

interface CreateTodoItemInProjectInput {
  done: TodoItemDone;
  name: TodoItemName;
  projectId: TodoItemProjectID;
  visibleForClients: TodoItemVisibleForClients;
}

interface CreateTodoItemInProjectResponse<CreateTodoItemInProjectRecordType> {
  createTodoItemInProject: {
    status: CreateTodoItemInProjectGqlStatus;
    recordUuid: TodoItemUUID;
    record: CreateTodoItemInProjectRecordType;
    errors: CreateTodoItemInProjectErrors;
  };
}

interface CreateTodoItemInProjectErrors {
  done: TodoItemGqlError;
  fullMessages: TodoItemGqlError;
  name: TodoItemGqlError;
  projectId: TodoItemGqlError;
  visibleForClients: TodoItemGqlError;
}

interface CreateTodoItemInProjectOptions {
  projectNanoId: ProjectNanoID;
  query: CreateTodoItemInProjectGqlQuery;
}

const action = 'createTodoItemInProject';

function useCreateTodoItemInProject<CreateTodoItemInProjectRecordType>({
  projectNanoId,
  query
}: CreateTodoItemInProjectOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateTodoItemInProjectInput,
    CreateTodoItemInProjectResponse<CreateTodoItemInProjectRecordType>,
    CreateTodoItemInProjectErrors,
    CreateTodoItemInProjectRecordType
  >({
    action,
    cacheKeys: [ProjectTodoItemsCache.indexCacheKey(projectNanoId)],
    query
  });

  return {
    createTodoItemInProjectData: createQueryData,
    createTodoItemInProjectError: createQueryError,
    createTodoItemInProjectLoading: createQueryLoading,
    createTodoItemInProjectErrorMessage: createQueryErrorMessage,
    createTodoItemInProject: createQuery,
    createTodoItemInProjectReset: createQueryReset
  };
}

export default useCreateTodoItemInProject;
