import {
  FetchFinUsersFilters,
  FetchUsersSort,
  FetchUsersPage,
  FetchUsersLimit
} from '../../usersTypes';

import { InfiniteIndexQueryBaseNodeType } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';
import { useFinInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useFinInfiniteIndexQuery';

import {
  INITIAL_USERS_FILTERS,
  INITIAL_USERS_LIMIT,
  INITIAL_USERS_SORT
} from '../../usersConstants';

interface FinUsersOptions {
  cacheKey: string;
  query: string;
  initialFilters?: FetchFinUsersFilters;
  initialSort?: FetchUsersSort;
  initialPage?: FetchUsersPage;
  initialLimit?: FetchUsersLimit;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

const scope = 'users';

function useFinUsers<ItemType extends InfiniteIndexQueryBaseNodeType>({
  cacheKey,
  query,
  initialFilters = INITIAL_USERS_FILTERS,
  initialSort = INITIAL_USERS_SORT,
  initialLimit = INITIAL_USERS_LIMIT,
  options = {}
}: FinUsersOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    limitItems
  } = useFinInfiniteIndexQuery<ItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    usersData: data,
    users: items,
    usersError: itemsError,
    usersTotalCount: itemsTotalCount,
    usersFetched: isFetched,
    usersLoading: isLoading,
    usersFetchingNextPage: isFetchingNextPage,
    usersIsPlaceholderData: isPlaceholderData,
    usersFilters: currentFilters,
    usersSort: currentSort,
    usersPage: currentPage,
    usersLimit: currentLimit,
    hasNextUsersPage: hasNextPage,
    updateUserCache: updateItemCache,
    loadMoreUsers: loadMoreItems,
    filterUsers: filterItems,
    changeUsersFilters: changeItemsFilters,
    clearUsersFilters: clearItemsFilters,
    sortUsers: sortItems,
    limitUsers: limitItems
  };
}

export default useFinUsers;
