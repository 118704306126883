import React, { Fragment, useMemo, useCallback } from 'react';
import compact from 'lodash/compact';

import { TogglePreventModalCloseAction } from '../../../../../../../../../helpers/modals/modalsTypes';
import { ItemMessagesListWhiteboardLogMessageImageMessage } from './ItemMessagesListWhiteboardLogMessageImage.types';

import { LinkHelper } from '../../../../../../../../../helpers/links/LinkHelper';
import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../../../helpers/ImageHelper';
import {
  LightboxWrapper,
  useLightboxWrapper,
  LightboxItems
} from '../../../../../../../../../helpers/LightboxWrapper';

import { Files } from '../../../../../../../../../utils/Files';

interface ItemMessagesListWhiteboardLogMessageImageProps {
  message: ItemMessagesListWhiteboardLogMessageImageMessage;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}
function ItemMessagesListWhiteboardLogMessageImage({
  message,
  togglePreventModalClose
}: ItemMessagesListWhiteboardLogMessageImageProps) {
  const lightboxItem = message.fileAttachment;

  const lightboxItems = useMemo<LightboxItems>(
    () => compact([lightboxItem]),
    [lightboxItem]
  );

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc
  } = useLightboxWrapper({
    items: lightboxItems,
    toggleBackdrop: togglePreventModalClose
  });

  const handleOpenLightbox = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      if (lightboxItem && Files.isImage(lightboxItem.file)) {
        e.preventDefault();
        handleLightboxOpenOnSlide(lightboxItem);
      }
    },
    [lightboxItem, handleLightboxOpenOnSlide]
  );

  return (
    <Fragment>
      <LinkHelper
        className="rounded focus:ring-base focus:ring-offset-0"
        href={lightboxItem?.file}
        target="_blank"
        onClick={handleOpenLightbox}
      >
        <ImageHelper
          className="w-5 h-5 rounded"
          src={lightboxItem?.file}
          alt={lightboxItem?.name}
          version={FileAttachmentItemImageVersions.Thumb120x120}
        />
      </LinkHelper>

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imagesCount={imagesCount}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        withFullScreenButton
        withBaseImageTitle
      />
    </Fragment>
  );
}

export default ItemMessagesListWhiteboardLogMessageImage;
