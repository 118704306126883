import {
  action,
  DownloadLifestyleResponse,
  DownloadLifestyleInput,
  DownloadLifestyleError,
  DOWNLOAD_LIFESTYLE_QUERY
} from './downloadLifestyle.query';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { DownloadCache } from '../../../downloads/DownloadCache';

function useDownloadLifestyle() {
  const { updateQuery, updateQueryLoading, updateQueryErrorMessage } =
    useUpdateQuery<
      DownloadLifestyleInput,
      DownloadLifestyleResponse,
      DownloadLifestyleError,
      undefined
    >({
      action,
      query: DOWNLOAD_LIFESTYLE_QUERY,
      cacheKeys: [DownloadCache.indexCacheKey()]
    });

  return {
    downloadLifestyleLoading: updateQueryLoading,
    downloadLifestyleErrorMessage: updateQueryErrorMessage,
    downloadLifestyle: updateQuery
  };
}

export default useDownloadLifestyle;
