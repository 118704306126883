import { gql } from 'graphql-request';

import { SourceFileUUID } from '../sourceFilesTypes';

export interface DownloadSourceFileQueryResponse {
  uuid: SourceFileUUID;
}

export const DOWNLOAD_SOURCE_FILE_QUERY = gql`
  mutation DownloadSourceFile($uuid: ID!, $deviceNanoId: String!) {
    downloadSourceFile(input: { uuid: $uuid, deviceNanoId: $deviceNanoId }) {
      recordUuid
      status
      record {
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
