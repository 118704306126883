import React from 'react';
import isEmpty from 'lodash/isEmpty';
import cl from 'classnames';

import { ItemMessagesListCurrentUserMessageItem } from './ItemMessagesListCurrentUserMessage.types';
import {
  FetchMessagesCacheKey,
  MessageID,
  MessageProjectUUID,
  MessageTaskUUID,
  MessageUUID
} from '../../../../../messagesTypes';
import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';
import { CheckableMessagesProps } from '../../../../../../../common/hooks/useMessagesCheckable';

import { ItemMessagesListMessageAttachmentsAmount } from '../ItemMessagesListMessageAttachmentsAmount';
import { ItemMessagesListMessageBody } from '../ItemMessagesListMessageBody';
import { ItemMessagesListMessageIdButtons } from '../ItemMessagesListMessageIdButtons';
import { ItemMessagesListMessageImages } from '../ItemMessagesListMessageImages';
import { ItemMessagesListMessageFiles } from '../ItemMessagesListMessageFiles';
import { ItemMessagesListMessageColors } from '../ItemMessagesListMessageColors';
import { ItemMessagesListMessageIcons } from '../ItemMessagesListMessageIcons';
import { ItemMessagesListMessageMenu } from '../ItemMessagesListMessageMenu';
import { ItemMessagesListMessageForwardedMessage } from '../ItemMessagesListMessageForwardedMessage';
import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';
import { ItemMessagesListMessageTodoList } from '../ItemMessagesListMessageTodoList';
import { ItemMessagesListMessageCheckBoxField } from '../ItemMessagesListMessageCheckBoxField';
import { ItemMessagesListMessageRepliedMessage } from '../ItemMessagesListMessageRepliedMessage';

import { TooltipSingletonSourceWrapper } from '../../../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { MessagesPermissions } from '../../../../../messagesConstants';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

type ItemMessagesListCurrentUserMessageProps = {
  message: ItemMessagesListCurrentUserMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
  onReplyMessage?: (messageId: MessageID) => void;
  prevSameAuthor: boolean;
  nextSameAuthor: boolean;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  togglePreventModalClose?: TogglePreventModalCloseAction;
  jumpToMessage?: (message: { id: MessageID; uuid: MessageUUID }) => void;
  markedUnhappyMessages?: boolean;
} & CheckableMessagesProps;

function ItemMessagesListCurrentUserMessage({
  message,
  messagesCacheKey,
  onReplyMessage,
  prevSameAuthor,
  nextSameAuthor,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  togglePreventModalClose,
  checkedMessages,
  onSetCheckedMessage,
  jumpToMessage,
  markedUnhappyMessages
}: ItemMessagesListCurrentUserMessageProps) {
  const withoutBody = isEmpty(message.body);
  const withoutTodoList = isEmpty(message.todoItems);

  return (
    <div id={`message-${message.uuid}`} className="flex flex-1">
      <ItemMessagesListMessageCheckBoxField
        message={message}
        checkedMessages={checkedMessages}
        onSetCheckedMessage={onSetCheckedMessage}
      />

      <div
        className={cl('flex-1 ml-12 sm:ml-16 mb-0.5 ', {
          'mt-5': !prevSameAuthor
        })}
      >
        <div className="flex group">
          <div className="flex flex-col flex-1">
            <div className="text-xs mb-0.5 ml-2 text-gray-600 dark:text-gray-400 flex justify-end">
              <CheckPermissions action={MessagesPermissions.READ_MESSAGE_ID}>
                <span>{message.id}</span>
              </CheckPermissions>
              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_THREAD_KEY_ID}
              >
                &nbsp;
                <span>{message.threadKeyId}</span>
              </CheckPermissions>
            </div>
            {prevSameAuthor ? null : (
              <div className="text-right text-xs mb-1 ml-2 text-gray-600 dark:text-gray-400">
                <ItemMessagesListMessageIdButtons message={message} />
              </div>
            )}
            <div className="w-full">
              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_FORWARD_MESSAGE}
              >
                {isEmpty(message.forwardedMessage) ? null : (
                  <ItemMessagesListMessageForwardedMessage
                    message={message}
                    reverse
                    messagesCacheKey={messagesCacheKey}
                    onReplyMessage={onReplyMessage}
                    forwardMessageProjectUuid={forwardMessageProjectUuid}
                    forwardMessageTaskUuid={forwardMessageTaskUuid}
                    checkedMessages={checkedMessages}
                    onSetCheckedMessage={onSetCheckedMessage}
                    markedUnhappyMessages={markedUnhappyMessages}
                  />
                )}
              </CheckPermissions>

              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_REPLY_MESSAGE}
              >
                {isEmpty(message.repliedMessage) ? null : (
                  <ItemMessagesListMessageRepliedMessage
                    message={message}
                    jumpToMessage={jumpToMessage}
                    reverse
                    markedUnhappyMessages={markedUnhappyMessages}
                  />
                )}
              </CheckPermissions>
              <div className="flex justify-end">
                {withoutBody ? null : (
                  <div>
                    <ItemMessagesListMessageBody
                      reverse
                      message={message}
                      messagesCacheKey={messagesCacheKey}
                      prevSameAuthor={prevSameAuthor}
                      nextSameAuthor={nextSameAuthor}
                      togglePreventModalClose={togglePreventModalClose}
                      markedUnhappyMessages={markedUnhappyMessages}
                    />
                  </div>
                )}

                {withoutTodoList ? null : (
                  <div>
                    <ItemMessagesListMessageTodoList
                      reverse
                      message={message}
                      prevSameAuthor={prevSameAuthor}
                      nextSameAuthor={nextSameAuthor}
                    />
                  </div>
                )}

                {withoutBody && withoutTodoList ? null : (
                  <ItemMessagesListMessageMenu
                    message={message}
                    messagesCacheKey={messagesCacheKey}
                    onReplyMessage={onReplyMessage}
                    isLeft
                    forwardMessageProjectUuid={forwardMessageProjectUuid}
                    forwardMessageTaskUuid={forwardMessageTaskUuid}
                    checkedMessages={checkedMessages}
                    onSetCheckedMessage={onSetCheckedMessage}
                  />
                )}
              </div>

              <TooltipSingletonSourceWrapper
                placement={TooltipPlacement.TOP}
                withArrow
              >
                <ItemMessagesListMessageImages
                  reverse
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                  onReplyMessage={onReplyMessage}
                  forwardMessageProjectUuid={forwardMessageProjectUuid}
                  forwardMessageTaskUuid={forwardMessageTaskUuid}
                  togglePreventModalClose={togglePreventModalClose}
                  checkedMessages={checkedMessages}
                  onSetCheckedMessage={onSetCheckedMessage}
                />
                <ItemMessagesListMessageFiles
                  reverse
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                  onReplyMessage={onReplyMessage}
                  forwardMessageProjectUuid={forwardMessageProjectUuid}
                  forwardMessageTaskUuid={forwardMessageTaskUuid}
                  checkedMessages={checkedMessages}
                  onSetCheckedMessage={onSetCheckedMessage}
                />
                <ItemMessagesListMessageAttachmentsAmount
                  reverse
                  message={message}
                />
              </TooltipSingletonSourceWrapper>

              <ItemMessagesListMessageColors
                reverse
                message={message}
                messagesCacheKey={messagesCacheKey}
                onReplyMessage={onReplyMessage}
                forwardMessageProjectUuid={forwardMessageProjectUuid}
                forwardMessageTaskUuid={forwardMessageTaskUuid}
                checkedMessages={checkedMessages}
                onSetCheckedMessage={onSetCheckedMessage}
              />
              <ItemMessagesListMessageIcons
                reverse
                message={message}
                messagesCacheKey={messagesCacheKey}
              />

              {withoutBody ? (
                <ItemMessagesListMessageSendEmailNotificationButton
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                  className="mt-1 flex items-center flex-row-reverse"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemMessagesListCurrentUserMessage;
