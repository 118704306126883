import React from 'react';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';

import { Translate } from '../../../../../../../../../helpers/Translate';
import { Icon } from '../../../../../../../../../helpers/Icon';

import { attachmentsKeys } from '../../../../../../../../../locales/keys';

interface ItemMessagesListSendingMessageAttachmentsProps {
  attachmentsAmount: number;
}

function ItemMessagesListSendingMessageAttachments({
  attachmentsAmount
}: ItemMessagesListSendingMessageAttachmentsProps) {
  return attachmentsAmount > 0 ? (
    <div className="opacity-75 -mb-4 z-0 relative hover:opacity-90 focus-within:opacity-90 pointer-events-none">
      <div className="mr-3">
        <div className="flex justify-end">
          <div className="flex flex-col relative items-end">
            <div className="w-full">
              <div className="flex justify-end mt-1">
                <div className="max-w-xl flex items-start gap-2 rounded-xl bg-gray-100 dark:bg-gray-850 hover:bg-gray-200 dark:hover:bg-gray-800 p-2 rounded-tr pb-6">
                  <Icon
                    icon={IconsEnum.PAPER_CLIP_OUTLINE}
                    className="h-4 w-4"
                  />
                  <span className="text-xs">
                    {attachmentsAmount}
                    &nbsp;
                    <Translate id={attachmentsKeys.pluralGenitive} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default ItemMessagesListSendingMessageAttachments;
