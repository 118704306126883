import React, { useMemo } from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import { TaskStatuses } from '../../../../../../../../tasks/tasksTypes';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { PositionStyleEnum } from '../../../../../../../../../types';
import { ItemMessagesListResultsMessageItem } from '../../ItemMessagesListResultsMessage.types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';
import { SendImagesToWhiteboardPageModalButtonImage } from '../../../../../../../../whiteboards/components/forms/SendImagesToWhiteboardPageForm';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';

import { SendImagesToWhiteboardPageDynamicModalButton } from '../../../../../../../../whiteboards/components/buttons/SendImagesToWhiteboardPageModalButton';
import { ItemMessagesListResultsMessageMenuDropdownDownloadButton } from './components/ItemMessagesListResultsMessageMenuDropdownDownloadButton';
import { ItemMessagesListResultsMessageMenuDropdownSendResultsToClientButton } from './components/ItemMessagesListResultsMessageMenuDropdownSendResultsToClientButton';
import { ItemMessagesListResultsMessageMenuDropdownVFCButton } from './components/ItemMessagesListResultsMessageMenuDropdownVFCButton';
import { ItemMessagesListResultsMessageMenuDropdownIsResultButton } from './components/ItemMessagesListResultsMessageMenuDropdownIsResultButton';
import { ItemMessagesListResultsMessageMenuDropdownUploadToLibraryButton } from './components/ItemMessagesListResultsMessageMenuDropdownUploadToLibraryButton';
import { ItemMessagesListResultsMessageMenuSortTypeButton } from './components/ItemMessagesListResultsMessageMenuSortTypeButton';

import { TooltipDropdownHelper } from '../../../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { PopoverPlacement } from '../../../../../../../../../helpers/Popover/popoverConstants';

import { Files } from '../../../../../../../../../utils/Files';

import { words, stringsKeys } from '../../../../../../../../../locales/keys';
import { MessagesPermissions } from '../../../../../../../messagesConstants';

interface ItemMessagesListResultsMessageMenuProps {
  message: ItemMessagesListResultsMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
}

function ItemMessagesListResultsMessageMenu({
  message,
  messagesCacheKey
}: ItemMessagesListResultsMessageMenuProps) {
  const currentUser = useCurrentUser();

  const withSendViaEmailButton =
    message.task.status === TaskStatuses.READY_FOR_ACCEPTANCE &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_RESULT_SEND_TO_CLIENT_BUTTON
    );

  const withDownloadButton = currentUser.hasPermissions(
    MessagesPermissions.READ_MESSAGE_RESULT_DOWNLOAD_BUTTON
  );

  const withVFCButton = currentUser.hasPermissions(
    MessagesPermissions.READ_MESSAGE_RESULT_VISIBLE_FOR_CLIENT_BUTTON
  );

  const withIsResultButton = currentUser.hasPermissions(
    MessagesPermissions.READ_MESSAGE_RESULT_IS_RESULT_BUTTON
  );

  const withUploadToLibraryButton = currentUser.hasPermissions(
    MessagesPermissions.READ_MESSAGE_RESULT_UPLOAD_TO_LIBRARY_BUTTON
  );

  const taskNanoId = message.task?.nanoId;

  const images = useMemo<SendImagesToWhiteboardPageModalButtonImage[]>(() => {
    return map(
      filter(
        message.fileAttachments,
        ({ file }) => file && Files.isImage(file)
      ),
      ({ file, height, name, width, id }) => ({
        fileUrl: file,
        height,
        name,
        width,
        fileAttachmentId: id
      })
    );
  }, [message]);

  const withSendToWhiteboardButton =
    !isEmpty(images) &&
    taskNanoId &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_SEND_RESULTS_TO_WHITEBOARD_BUTTON
    );

  const withSortButton = currentUser.hasPermissions(
    MessagesPermissions.READ_MESSAGE_RESULT_SORT_BUTTON
  );

  const withoutDropdown =
    !withSendViaEmailButton &&
    !withDownloadButton &&
    !withVFCButton &&
    !withIsResultButton &&
    !withUploadToLibraryButton &&
    !withSendToWhiteboardButton;

  if (withoutDropdown && !withSortButton) {
    return null;
  }

  return (
    <div className="absolute top-2 right-2">
      <div className="flex gap-x-0.5">
        {withSortButton && (
          <ItemMessagesListResultsMessageMenuSortTypeButton
            messagesCacheKey={messagesCacheKey}
            message={message}
          />
        )}

        {!withoutDropdown && (
          <TooltipDropdownHelper
            buttonClassName="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
            popoverPositionStyle={PositionStyleEnum.fixed}
            tooltipI18nText={words.more}
            dropdownPlacement={PopoverPlacement.BOTTOM_END}
            tooltipSingleton
            closeOnOutOfView
          >
            {withDownloadButton ? (
              <ItemMessagesListResultsMessageMenuDropdownDownloadButton
                message={message}
              />
            ) : null}

            {withSendViaEmailButton ? (
              <ItemMessagesListResultsMessageMenuDropdownSendResultsToClientButton
                message={message}
              />
            ) : null}

            {withVFCButton ? (
              <ItemMessagesListResultsMessageMenuDropdownVFCButton
                messagesCacheKey={messagesCacheKey}
                message={message}
              />
            ) : null}

            {withIsResultButton ? (
              <ItemMessagesListResultsMessageMenuDropdownIsResultButton
                messagesCacheKey={messagesCacheKey}
                message={message}
              />
            ) : null}

            {withUploadToLibraryButton ? (
              <ItemMessagesListResultsMessageMenuDropdownUploadToLibraryButton
                message={message}
              />
            ) : null}

            {withSendToWhiteboardButton ? (
              <SendImagesToWhiteboardPageDynamicModalButton
                cacheKeys={[messagesCacheKey]}
                className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                i18nText={stringsKeys.sendToWhiteboard}
                images={images}
                isResults
                projectNanoId={message.task?.project?.nanoId}
                taskNanoId={taskNanoId}
              />
            ) : null}
          </TooltipDropdownHelper>
        )}
      </div>
    </div>
  );
}

export default ItemMessagesListResultsMessageMenu;
