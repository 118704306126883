import { Control, Controller } from 'react-hook-form';

import {
  ItemMessageFilterFormData,
  ItemMessageFilterFormOtherTypes
} from '../../hooks/useItemMessagesFilterForm/useItemMessagesFilterForm.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  ItemMessagesFilterFormItem,
  ItemMessagesFilterFormItemValue
} from '../ItemMessagesFilterFormItem';

import { Icon } from '../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../helpers/Translate';

import {
  formsFields,
  messagesKeys,
  words
} from '../../../../../../../locales/keys';

const items: ItemMessagesFilterFormItemValue<ItemMessageFilterFormOtherTypes>[] =
  [
    {
      i18nText: words.criticalRequirements,
      value: ItemMessageFilterFormOtherTypes.CRITICAL_REQUIREMENTS
    },
    {
      i18nText: words.whiteboardNotifications,
      value: ItemMessageFilterFormOtherTypes.WHITEBOARD
    },
    {
      i18nText: messagesKeys.withAttachments,
      value: ItemMessageFilterFormOtherTypes.WITH_ATTACHMENTS
    }
  ];

interface ItemMessagesFilterFormOtherProps {
  control: Control<ItemMessageFilterFormData>;
}

function ItemMessagesFilterFormOther({
  control
}: ItemMessagesFilterFormOtherProps) {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-2">
        <Icon icon={IconsEnum.SPARKLES_SOLID} className="h-4 w-4" />

        <span className="text-sm font-semibold text-left flex-1">
          <Translate id={formsFields.other} />
        </span>
      </div>

      <Controller
        control={control}
        name="other"
        render={({ field: { onChange, value } }) => (
          <div className="flex flex-wrap gap-2">
            {items.map((item, index) => (
              <ItemMessagesFilterFormItem
                key={index}
                value={value}
                item={item}
                onChange={onChange}
              />
            ))}
          </div>
        )}
      />
    </div>
  );
}

export default ItemMessagesFilterFormOther;
