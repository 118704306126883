interface IsNeedFixedTheadOptions {
  theadElem?: HTMLElement;
  tableElem?: HTMLElement;
  verticalScrollContainerElem?: HTMLElement;
}

function isNeedFixedThead({
  theadElem,
  tableElem,
  verticalScrollContainerElem
}: IsNeedFixedTheadOptions): boolean {
  if (!theadElem || !tableElem || !verticalScrollContainerElem) {
    return false;
  }

  const topOffset = verticalScrollContainerElem.getBoundingClientRect().top;

  const isTableTopHigherView =
    tableElem.getBoundingClientRect().top - topOffset < 0;

  const isTableBottomInViewOrBelow =
    tableElem.getBoundingClientRect().bottom - topOffset > 0;

  if (isTableTopHigherView && isTableBottomInViewOrBelow) {
    return true;
  } else {
    return false;
  }
}

export default isNeedFixedThead;
