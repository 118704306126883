import React from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import {
  ItemMessagesFilters,
  ItemMessagesListFilterMessages
} from '../../list/ItemMessagesList';

import { useItemMessagesSettingsForm } from '../../forms/ItemMessagesSettingsForm/hooks/useItemMessagesSettingsForm';

import { ItemMessagesSettingsForm } from '../../forms/ItemMessagesSettingsForm';

import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { messagesKeys, words } from '../../../../../locales/keys';

const MESSAGES_SETTINGS_FORM = 'messages-settings-form';
const MESSAGES_SETTINGS_MODAL_BUTTON = 'messages-settings-modal-button';

interface MessagesSettingsModalButtonProps {
  messagesFilters: ItemMessagesFilters;
  filterMessages: ItemMessagesListFilterMessages;

  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
  disabled?: IsDisabled;
}

function MessagesSettingsModalButton({
  filterMessages,
  messagesFilters,

  i18nText,
  icon,
  iconClassName,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton,
  disabled
}: MessagesSettingsModalButtonProps) {
  const {
    control,
    clearFilterMessagesForm,
    handleSubmitFilterMessagesForm,
    resetFilterMessagesForm
  } = useItemMessagesSettingsForm({
    filterMessages,
    messagesFilters
  });

  return (
    <SimpleModalButton
      id={MESSAGES_SETTINGS_MODAL_BUTTON}
      className="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white focus:ring-offset-0"
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      i18nSubmitText={words.apply}
      i18nTitle={messagesKeys.settings}
      i18nText={i18nText}
      icon={icon || IconsEnum.COG}
      iconClassName={iconClassName || 'h-4 w-4'}
      tooltipI18nText={tooltipI18nText || messagesKeys.settings}
      tooltipPlacement={tooltipPlacement}
      tooltipSingleton={tooltipSingleton}
      modalSize="xl"
      submitDisabled={disabled}
      cancelButtonPosition="start"
      onCancel={clearFilterMessagesForm}
      i18nCancelText={words.reset}
      withCloseButton
      onOpen={resetFilterMessagesForm}
      onSubmit={handleSubmitFilterMessagesForm}
    >
      <ItemMessagesSettingsForm
        control={control}
        form={MESSAGES_SETTINGS_FORM}
      />
    </SimpleModalButton>
  );
}

export default MessagesSettingsModalButton;
