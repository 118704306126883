import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { I18nText } from '../../../../../../../types';

import { Icon } from '../../../../../../../helpers/Icon';
import { ButtonHelper } from '../../../../../../../helpers/buttons/ButtonHelper';
import { Translate } from '../../../../../../../helpers/Translate';

type ItemMessagesHeaderFilterButtonProps = {
  active: boolean;
  count: number;
  icon: IconsEnum;
  onClick: () => void;
  i18nText: I18nText;
};

function ItemMessagesHeaderFilterButton({
  active,
  count,
  icon,
  i18nText,
  onClick
}: ItemMessagesHeaderFilterButtonProps) {
  if (!count) {
    return null;
  }

  return (
    <ButtonHelper
      className={cl(
        'py-1 pl-1 pr-1 sm:pr-2 sm:space-x-1 rounded inline-flex items-center whitespace-nowrap text-xs leading-5 focus:ring-base focus:ring-offset-0',
        active
          ? 'text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300'
          : 'hover:text-gray-950 dark:hover:text-white'
      )}
      onClick={onClick}
    >
      <Icon className="w-4 h-4 mr-1" icon={icon} />
      <span>{count}</span>
      <span className="sr-only sm:not-sr-only">
        <Translate id={i18nText} />
      </span>
    </ButtonHelper>
  );
}

export default ItemMessagesHeaderFilterButton;
