import React, { ReactNode } from 'react';
import cl from 'classnames';

import { ClassName, TableViewType } from '../../../../../types';

interface IndexTableCellDefaultProps {
  viewType?: TableViewType;
  tdClassName?: ClassName;
  divClassName?: ClassName;
  spanClassName?: ClassName;
  withSpan?: boolean;
  tabIndex?: number;
  colSpan?: number;
}

interface IndexTableCellWithTextProps {
  text: string;
  children?: never;
}

interface IndexTableCellWithChildrenProps {
  text?: never;
  children: ReactNode;
}

type IndexTableCellProps = IndexTableCellDefaultProps &
  (IndexTableCellWithTextProps | IndexTableCellWithChildrenProps);

function IndexTableCell({
  viewType,
  tdClassName,
  divClassName,
  spanClassName,
  withSpan = false,
  text,
  children,
  tabIndex,
  colSpan
}: IndexTableCellProps) {
  if (withSpan) {
    return (
      <td
        className={
          tdClassName ||
          cl({
            'px-3 py-2': !viewType || viewType === TableViewType.DEFAULT,
            'px-3 py-0.5 text-xs font-mono': viewType === TableViewType.SLIM
          })
        }
        tabIndex={tabIndex}
        colSpan={colSpan}
      >
        <div className={divClassName || 'flex flex-wrap'}>
          <span
            className={
              spanClassName ||
              'inline-flex items-center px-2 py-0.5 m-0.5 rounded text-xs font-medium dark:bg-opacity-50 bg-gray-100 text-gray-800 dark:text-gray-100 dark:bg-gray-700'
            }
          >
            {text || children}
          </span>
        </div>
      </td>
    );
  }

  return (
    <td
      className={
        tdClassName ||
        cl({
          'px-3 py-2': !viewType || viewType === TableViewType.DEFAULT,
          'px-3 py-0.5 text-xs font-mono': viewType === TableViewType.SLIM
        })
      }
      tabIndex={tabIndex}
      colSpan={colSpan}
    >
      <div
        className={
          divClassName ||
          'text-gray-900 dark:text-gray-200 whitespace-nowrap font-normal text-sm'
        }
      >
        {text || children}
      </div>
    </td>
  );
}

export default IndexTableCell;
