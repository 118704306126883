import cl from 'classnames';
import { Trans } from 'react-i18next';

import { ItemMessagesListPaidInvoiceMessageItem } from './ItemMessagesListPaidInvoiceMessage.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';
import {
  InvoiceStatuses,
  invoiceStatusTexts
} from '../../../../../../invoices/invoicesTypes';

import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';

import { Icon } from '../../../../../../../helpers/Icon';
import { MoneyHelper } from '../../../../../../../helpers/MoneyHelper';
import { PureLinkHelper } from '../../../../../../../helpers/links/PureLinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { InvoicePath } from '../../../../../../invoices/InvoicePath';

import { invoicesKeys } from '../../../../../../../locales/keys';
import { invoiceStatusesBgColors } from '../../../../../../invoices/invoicesConstants';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';

interface ItemMessagesListPaidInvoiceMessageProps {
  message: ItemMessagesListPaidInvoiceMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
}
function ItemMessagesListPaidInvoiceMessage({
  message,
  messagesCacheKey
}: ItemMessagesListPaidInvoiceMessageProps) {
  const invoice = message.bitInvoice;

  const primaryInvoiceStatus = invoice?.clientStatus || invoice?.status;

  const secondaryInvoiceStatus =
    invoice?.secondaryInvoice?.clientStatus ||
    invoice?.secondaryInvoice?.status;

  const invoiceStatus =
    secondaryInvoiceStatus === InvoiceStatuses.PAID
      ? secondaryInvoiceStatus
      : primaryInvoiceStatus;

  const invoiceId = invoice?.clientId || invoice?.id;

  return (
    <div id={`message-${message.uuid}`} className="mb-10 p-2">
      <div className="bg-gray-100 dark:bg-gray-850 border dark:border-gray-700 rounded-lg p-4">
        <div className="flex gap-4">
          <div>
            <LoadingSkeleton
              className="m-0"
              itemClassName="w-10 h-10"
              loaded={message.bitInvoiceFetched}
              count={1}
            >
              <Icon
                icon={IconsEnum.CASH_OUTLINE}
                className="h-10 w-10"
                strokeWidth="1"
              />
            </LoadingSkeleton>
          </div>

          <div className="flex-1">
            <div className="font-semibold mb-1">
              <LoadingSkeleton
                className="m-0"
                loaded={message.bitInvoiceFetched}
                count={1}
              >
                <Translate id={invoicesKeys.singular} />
                &nbsp;
                {`#${invoiceId}`}
              </LoadingSkeleton>
            </div>

            <LoadingSkeleton
              className="m-0 text-xs"
              loaded={message.bitInvoiceFetched}
              count={3}
            >
              <div className="text-xs">
                <Translate id={invoicesKeys.messages.invoiceHasBeen.paid} />{' '}
                <span className="font-bold">
                  <MoneyHelper
                    value={
                      secondaryInvoiceStatus === InvoiceStatuses.PAID
                        ? invoice?.secondaryInvoice?.amount
                        : invoice?.amount
                    }
                  />
                </span>
                .
              </div>

              {invoice?.secondaryInvoice &&
                secondaryInvoiceStatus !== InvoiceStatuses.PAID && (
                  <div className="text-xs">
                    <Trans
                      i18nKey={invoicesKeys.messages.finalPaymentIsExpected}
                      components={{
                        money: (
                          <MoneyHelper
                            value={invoice?.secondaryInvoice?.amount || 0}
                          />
                        )
                      }}
                    />
                  </div>
                )}

              <div className="flex gap-3 mt-1">
                <PureLinkHelper
                  className="text-xs dark:text-gray-300 text-gray-700 underline"
                  href={InvoicePath.paymentsInvoice(invoice?.nanoId)}
                  i18nText={invoicesKeys.view}
                />

                <ItemMessagesListMessageSendEmailNotificationButton
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                  className="flex items-center"
                />
              </div>
            </LoadingSkeleton>
          </div>

          <LoadingSkeleton
            className="m-0 self-start"
            itemClassName="w-20 h-9 rounded-full"
            loaded={message.bitInvoiceFetched}
            count={1}
          >
            <div
              className={cl(
                'px-4 py-1.5 rounded-full self-start',
                invoiceStatusesBgColors[invoiceStatus]
              )}
            >
              {invoiceStatusTexts[invoiceStatus]}
            </div>
          </LoadingSkeleton>
        </div>
      </div>
    </div>
  );
}

export default ItemMessagesListPaidInvoiceMessage;
