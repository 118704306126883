function findHorizontalScrollContainer(
  element: HTMLElement
): HTMLElement | undefined {
  const parent = element.parentElement;

  if (!parent) {
    return undefined;
  }

  const isParentHasScroll =
    parent.scrollWidth > parent.clientWidth &&
    window.getComputedStyle(parent).overflowX !== 'hidden';

  if (isParentHasScroll) {
    return parent;
  }

  return findHorizontalScrollContainer(parent);
}

export default findHorizontalScrollContainer;
