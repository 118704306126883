import React from 'react';

import { DateFieldI18nLabel, DateFieldValue } from './DateField.types';
import { ClassName } from '../../../types';

import { Translate } from '../../Translate';
import { DateTimeHelper } from '../../DateTimeHelper';
import { DateHelper } from '../../DateHelper';

interface DateFieldProps {
  value: DateFieldValue;
  nullValue?: string;
  className?: ClassName;
  dateClassName?: ClassName;
  i18nLabel: DateFieldI18nLabel;
  i18nLabelClassName?: ClassName;
  type?: 'date' | 'dateTime';
}

function DateField({
  value,
  nullValue,
  className,
  dateClassName,
  i18nLabel,
  i18nLabelClassName,
  type = 'dateTime'
}: DateFieldProps) {
  const valueNode =
    type === 'dateTime' ? (
      <DateTimeHelper date={value} />
    ) : (
      <DateHelper date={value} />
    );

  return (
    <div className={className}>
      <p
        className={
          i18nLabelClassName || 'mb-1 text-2xs text-gray-600 dark:text-gray-400'
        }
      >
        <Translate id={i18nLabel} />
      </p>
      <span className={dateClassName}>{value ? valueNode : nullValue}</span>
    </div>
  );
}

export default DateField;
