import React, { Fragment } from 'react';
import split from 'lodash/split';
import size from 'lodash/size';

import { ClassName } from '../../../../types';
import { InvoiceIdLinkInvoice } from './InvoiceIdLink.types';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { NextLinkHelper } from '../../../../helpers/links/NextLinkHelper';
import { InvoicePath } from '../../InvoicePath';
import { InvoicesPermissions } from '../../invoicesConstants';

interface InvoiceIdLinkProps {
  invoice: InvoiceIdLinkInvoice;
  linkClassName?: ClassName;
  linkType?: 'clientIdOrId' | 'id';
  prefix?: string;
}

function InvoiceIdLink({
  invoice,
  linkClassName,
  linkType = 'clientIdOrId',
  prefix
}: InvoiceIdLinkProps) {
  const currentUser = useCurrentUser();

  const canReadLink = currentUser.hasPermissions(
    InvoicesPermissions.READ_INVOICE_LINK
  );

  if (!invoice) {
    return null;
  }

  const invoiceIds = invoice.clientId ? split(invoice.clientId, '-') : null;

  if (size(invoiceIds) > 1 && linkType === 'clientIdOrId') {
    return (
      <Fragment>
        {invoiceIds.map((invoiceId, index) => (
          <Fragment key={invoiceId}>
            {index !== 0 ? '-' : null}
            {canReadLink ? (
              <NextLinkHelper
                className={linkClassName}
                href={InvoicePath.showById(invoiceId)}
              >
                {index === 0 && prefix}
                {invoiceId}
              </NextLinkHelper>
            ) : (
              invoiceId
            )}
          </Fragment>
        ))}
      </Fragment>
    );
  }

  if (invoice?.id && invoice?.nanoId) {
    return (
      <Fragment>
        {canReadLink ? (
          <NextLinkHelper
            className={linkClassName}
            href={InvoicePath.show(invoice.nanoId)}
          >
            {prefix}
            {invoice.id}
          </NextLinkHelper>
        ) : (
          invoice.id
        )}
      </Fragment>
    );
  }

  return null;
}

export default InvoiceIdLink;
