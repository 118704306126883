import { useCallback, useState } from 'react';
import sumBy from 'lodash/sumBy';
import size from 'lodash/size';

import {
  ItemMessagesListSendingMessageMessageItem,
  ItemMessagesListSendingMessageOnRemoveSendingMessage
} from '../../ItemMessagesListSendingMessage.types';

import {
  CREATE_MESSAGE_IN_TASK_QUERY,
  CreateMessageInTaskQueryResponse
} from '../../../../../../../../messages/queries/createMessageInTask.query';
import {
  CREATE_MESSAGE_IN_PROJECT_QUERY,
  CreateMessageInProjectQueryResponse
} from '../../../../../../../../messages/queries/createMessageInProject.query';

import { useCreateMessageInTask } from '../../../../../../../../messages/hooks/useCreateMessageInTask';
import { useCreateMessageInProject } from '../../../../../../../../messages/hooks/useCreateMessageInProject';

interface ItemMessagesListSendingMessageOptions {
  message: ItemMessagesListSendingMessageMessageItem;
  onRemoveSendingMessage?: ItemMessagesListSendingMessageOnRemoveSendingMessage;
}

function useItemMessagesListSendingMessage({
  message,
  onRemoveSendingMessage
}: ItemMessagesListSendingMessageOptions) {
  const attachmentsAmount = sumBy(
    [
      message.formData?.attachedFileAttachmentIds,
      message.formData?.fileAttachmentIds,
      message.formData?.selectedProductIds,
      message.formData?.selectedLifestyleIds,
      message.formData?.selectedMaterialIds
    ],
    size
  );

  const [messageCreated, setMessageCreated] = useState<boolean>(false);

  const {
    createMessageInTaskLoading,
    createMessageInTaskErrorMessage,
    createMessageInTask
  } = useCreateMessageInTask<CreateMessageInTaskQueryResponse>({
    taskNanoId: message.task?.nanoId,
    query: CREATE_MESSAGE_IN_TASK_QUERY
  });

  const {
    createMessageInProjectLoading,
    createMessageInProjectErrorMessage,
    createMessageInProject
  } = useCreateMessageInProject<CreateMessageInProjectQueryResponse>({
    projectNanoId: message.project?.nanoId,
    query: CREATE_MESSAGE_IN_PROJECT_QUERY
  });

  const handleTrySendAgain = useCallback<() => void>(() => {
    if (message.task) {
      return createMessageInTask({
        ...message.formData,
        taskId: message.task.id
      }).then(() => setMessageCreated(true));
    }
    if (message.project) {
      return createMessageInProject({
        ...message.formData,
        projectId: message.project.id
      }).then(() => setMessageCreated(true));
    }
  }, [message, createMessageInTask, createMessageInProject]);

  const handleRemoveSendingMessage = useCallback<() => void>(() => {
    onRemoveSendingMessage?.(message.formData?.uuid);
  }, [onRemoveSendingMessage, message]);

  return {
    attachmentsAmount,
    messageCreated,
    trySendAgainErrorMessage:
      createMessageInTaskErrorMessage || createMessageInProjectErrorMessage,
    isTrySendAgainLoading:
      createMessageInTaskLoading || createMessageInProjectLoading,
    handleTrySendAgain,
    handleRemoveSendingMessage
  };
}

export default useItemMessagesListSendingMessage;
