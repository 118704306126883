import React from 'react';

import { FetchMessagesCacheKey } from '../../../../../../../../../messagesTypes';
import { ItemMessagesListResultsMessageItem } from '../../../../ItemMessagesListResultsMessage.types';

import {
  TOGGLE_MESSAGE_VISIBLE_FOR_CLIENT_QUERY,
  ToggleMessageVisibleForClientRecordType
} from '../../../../../../../../../queries/toggleMessageVisibleForClient.query';

import { useToggleMessageVisibleForClient } from '../../../../../../../../../hooks/useToggleMessageVisibleForClient';

import { PureButtonHelper } from '../../../../../../../../../../../helpers/buttons/PureButtonHelper';

import { words } from '../../../../../../../../../../../locales/keys';

interface ItemMessagesListResultsMessageMenuDropdownVfCButtonProps {
  messagesCacheKey: FetchMessagesCacheKey;
  message: ItemMessagesListResultsMessageItem;
}

function ItemMessagesListResultsMessageMenuDropdownVFCButton({
  message,
  messagesCacheKey
}: ItemMessagesListResultsMessageMenuDropdownVfCButtonProps) {
  const {
    toggleMessageVisibleForClient,
    toggleMessageVisibleForClientLoading
  } = useToggleMessageVisibleForClient<ToggleMessageVisibleForClientRecordType>(
    {
      indexCacheKey: messagesCacheKey,
      query: TOGGLE_MESSAGE_VISIBLE_FOR_CLIENT_QUERY
    }
  );

  const handleToggleMessageVisibleForClient = () =>
    toggleMessageVisibleForClient({ uuid: message.uuid });

  return (
    <PureButtonHelper
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      disabled={toggleMessageVisibleForClientLoading}
      i18nText={
        message.visibleForClient
          ? words.invisibleForClient
          : words.visibleForClient
      }
      onClick={handleToggleMessageVisibleForClient}
    />
  );
}

export default ItemMessagesListResultsMessageMenuDropdownVFCButton;
