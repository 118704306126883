import { useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import some from 'lodash/some';

import { I18nText } from '../../../../../../../types';

import { Translate } from '../../../../../../../helpers/Translate';
import { ButtonHelper } from '../../../../../../../helpers/buttons/ButtonHelper';
import { ImageHelper } from '../../../../../../../helpers/ImageHelper';

export type ItemMessagesFilterFormItemValue<ValueType> = {
  i18nText: I18nText;
  value: ValueType;
  image?: string;
};

interface ItemMessagesFilterFormItemProps<ValueType> {
  value: ValueType | ValueType[] | null;
  item: ItemMessagesFilterFormItemValue<ValueType>;
  onChange: (value: ValueType | ValueType[] | null) => void;
  multi?: boolean;
}

function ItemMessagesFilterFormItem<ValueType>({
  value,
  item,
  onChange,
  multi = false
}: ItemMessagesFilterFormItemProps<ValueType>) {
  const selected = useMemo<boolean>(() => {
    return multi && isArray(value)
      ? some(value, (v) => isEqual(v, item.value))
      : isEqual(value, item.value);
  }, [item.value, multi, value]);

  const handleChange = useCallback<() => void>(() => {
    if (multi && isArray(value)) {
      const newValue = value.filter((v) => !isEqual(v, item.value));

      onChange(selected ? newValue : [item.value, ...value]);
    } else {
      onChange(selected ? null : item.value);
    }
  }, [item.value, multi, onChange, selected, value]);

  return (
    <ButtonHelper
      className={
        selected
          ? 'border rounded text-sm flex items-center h-8 box-content border-blue-200 dark:border-transparent bg-blue-100 hover:bg-blue-50 text-black'
          : 'border rounded text-sm flex items-center h-8 box-content border-gray-300 dark:border-gray-700 hover:bg-gray-500/10 text-gray-700 dark:text-gray-300'
      }
      onClick={handleChange}
    >
      {item.image && (
        <ImageHelper
          alt={item.i18nText}
          className="h-8 w-8 rounded-l object-cover"
          src={item.image}
        />
      )}

      <span className="px-3 whitespace-nowrap">
        <Translate id={item.i18nText} />
      </span>
    </ButtonHelper>
  );
}

export default ItemMessagesFilterFormItem;
