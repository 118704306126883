import { gql } from 'graphql-request';

import { MessageBody, MessageID, MessageUUID } from '../messagesTypes';

export interface FetchSelectFieldFinMessagesQueryResponse {
  id: MessageID;
  uuid: MessageUUID;
  body: MessageBody;
}

export const FETCH_SELECT_FIELD_FIN_MESSAGES = gql`
  query SelectFieldFinMessages(
    $filters: MessagesFilters
    $sort: [MessagesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    messages(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        body
      }
      paginationInfo {
        nextPage
      }
    }
  }
`;
