import {
  DownloadClientFileGqlQuery,
  DownloadClientFileGqlStatus,
  ClientFileDeviceNanoId,
  ClientFileGqlError,
  ClientFileUUID
} from '../../clientFilesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface DownloadClientFileOptions {
  query: DownloadClientFileGqlQuery;
}

export interface DownloadClientFileResponse<DownloadClientFileRecordType> {
  downloadClientFile: {
    status: DownloadClientFileGqlStatus;
    recordUuid: ClientFileUUID;
    record: DownloadClientFileRecordType;
    errors: DownloadClientFileError;
  };
}

export interface DownloadClientFileInput {
  uuid: ClientFileUUID;
  deviceNanoId: ClientFileDeviceNanoId;
}

export interface DownloadClientFileError {
  fullMessages: ClientFileGqlError;
}

const action = 'downloadClientFile';

function useDownloadClientFile<DownloadClientFileRecordType>({
  query
}: DownloadClientFileOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadClientFileInput,
    DownloadClientFileResponse<DownloadClientFileRecordType>,
    DownloadClientFileError,
    DownloadClientFileRecordType
  >({ action, query });

  return {
    downloadClientFileData: updateQueryData,
    downloadClientFileError: updateQueryError,
    downloadClientFileLoading: updateQueryLoading,
    downloadClientFileErrorMessage: updateQueryErrorMessage,
    downloadClientFile: updateQuery,
    downloadClientFileReset: updateQueryReset
  };
}

export default useDownloadClientFile;
