import { gql } from 'graphql-request';

import {
  DownloadMaterialGqlStatus,
  MaterialDeviceNanoId,
  DownloadMaterialGqlError,
  MaterialUUID
} from '../materialsTypes';

export interface DownloadMaterialResponse {
  downloadMaterial: {
    status: DownloadMaterialGqlStatus;
    errors: DownloadMaterialError;
  };
}

export interface DownloadMaterialInput {
  uuid: MaterialUUID;
  deviceNanoId: MaterialDeviceNanoId;
}

export interface DownloadMaterialError {
  fullMessages: DownloadMaterialGqlError;
}

export const action = 'downloadMaterial';

export const DOWNLOAD_MATERIAL_QUERY = gql`
  mutation DownloadMaterial($uuid: ID!, $deviceNanoId: String!) {
    downloadMaterial(input: { uuid: $uuid, deviceNanoId: $deviceNanoId }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
