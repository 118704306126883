import React, { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import isEmpty from 'lodash/isEmpty';

import { ItemMessagesListResultsMessageContentButtonTypes } from '../../ItemMessagesListResultsMessageContent.types';
import { ItemMessagesListResultsMessageContentQaStatusTask } from './ItemMessagesListResultsMessageContentQaStatus.types';

import { useItemMessagesListResultsMessageContentButtons } from '../../hooks/useItemMessagesListResultsMessageContentButtons';
import { useApproveResultsInTaskForm } from '../../../../../../../../../../../main/tasks/components/forms/ApproveResultsInTaskForm/hooks/useApproveResultsInTaskForm';

import { ApproveResultsInTaskForm } from '../../../../../../../../../../../main/tasks/components/forms/ApproveResultsInTaskForm';
import { ItemMessagesListResultsMessageContentButton } from '../ItemMessagesListResultsMessageContentButton';
import { ItemMessagesListResultsMessageContentDropdownButtons } from '../ItemMessagesListResultsMessageContentDropdownButtons';

import { AlertMessage } from '../../../../../../../../../../../helpers/AlertMessage';

import { TaskCache } from '../../../../../../../../../../tasks/TaskCache';

const buttons: ItemMessagesListResultsMessageContentButtonTypes[] = [
  ItemMessagesListResultsMessageContentButtonTypes.APPROVE_RESULTS,
  ItemMessagesListResultsMessageContentButtonTypes.REVISION,
  ItemMessagesListResultsMessageContentButtonTypes.DOWNLOAD_RESULTS,
  ItemMessagesListResultsMessageContentButtonTypes.SUBMIT_SOURCE_FILES
];

const APPROVE_RESULTS_IN_TASK_MESSAGE_FORM =
  'approve-results-in-task-message-form';

interface ItemMessagesListResultsMessageContentQaStatusProps {
  task: ItemMessagesListResultsMessageContentQaStatusTask;
  withoutClose?: boolean;
}

function ItemMessagesListResultsMessageContentQaStatus({
  task,
  withoutClose
}: ItemMessagesListResultsMessageContentQaStatusProps) {
  const queryClient = useQueryClient();

  const { mainButton, dropdownButtons } =
    useItemMessagesListResultsMessageContentButtons({
      buttons
    });

  const {
    control,
    approveResultsInTaskLoading,
    approveResultsInTaskErrorMessage,
    handleApproveResultsInTask,
    validationErrors,
    isValid
  } = useApproveResultsInTaskForm({
    taskUuid: task.uuid,
    taskNanoId: task.nanoId,
    withoutClose
  });

  const handleRefetchAfterSubmit = useCallback<() => void>(
    () =>
      queryClient.invalidateQueries(TaskCache.messagesCacheKey(task.nanoId)),
    [task.nanoId, queryClient]
  );

  const handleConfirm = useCallback<() => void>(
    () => handleApproveResultsInTask().then(() => handleRefetchAfterSubmit()),
    [handleApproveResultsInTask, handleRefetchAfterSubmit]
  );

  return (
    <div className="flex justify-center w-full text-sm mt-4 px-2">
      <div className="w-full rounded-md px-2 py-4 bg-opacity-15 space-y-4">
        <ApproveResultsInTaskForm
          form={APPROVE_RESULTS_IN_TASK_MESSAGE_FORM}
          control={control}
          isLoading={approveResultsInTaskLoading}
          withResultFileAttachments
          supportMarkValidationError={
            validationErrors.supportMarkValidationError
          }
          footer={
            <>
              <AlertMessage message={approveResultsInTaskErrorMessage} />
              <div className="flex gap-1 justify-center">
                <span className="relative z-10 inline-flex shadow-sm rounded-md z-auto">
                  <ItemMessagesListResultsMessageContentButton
                    main
                    buttonType={mainButton}
                    disabled={isValid === false || approveResultsInTaskLoading}
                    task={task}
                    color="purple"
                    rounded={isEmpty(dropdownButtons)}
                    onClick={handleConfirm}
                    refetchAfterSubmit={handleRefetchAfterSubmit}
                  />
                  <ItemMessagesListResultsMessageContentDropdownButtons
                    buttonColor="purple"
                    buttons={dropdownButtons}
                    task={task}
                    refetchAfterSubmit={handleRefetchAfterSubmit}
                  />
                </span>
              </div>
            </>
          }
        />
      </div>
    </div>
  );
}

export default ItemMessagesListResultsMessageContentQaStatus;
