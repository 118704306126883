import { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { SearchFilterNanoID } from '../../../../../../searchFilters/searchFiltersTypes';

import { useDeleteSearchFilter } from '../../../../../../searchFilters/hooks/useDeleteSearchFilter';

import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';
import { ConfirmModalButton } from '../../../../../../../helpers/buttons/ConfirmModalButton';

import { DELETE_SEARCH_FILTER_QUERY } from '../../../../../../searchFilters/queries/deleteSearchFilter.query';

import { stringsKeys, words } from '../../../../../../../locales/keys';

interface ItemMessagesSavedFiltersButtonFilterItemProps {
  searchFilterNanoId: SearchFilterNanoID;
  text: string;
  onSelectItem: (searchFilterNanoId: SearchFilterNanoID) => void;
}

function ItemMessagesSavedFiltersButtonFilterItem({
  text,
  searchFilterNanoId,
  onSelectItem
}: ItemMessagesSavedFiltersButtonFilterItemProps) {
  const {
    deleteSearchFilter,
    deleteSearchFilterLoading,
    deleteSearchFilterErrorMessage,
    deleteSearchFilterReset
  } = useDeleteSearchFilter({ query: DELETE_SEARCH_FILTER_QUERY });

  const handleSelectItem = useCallback<() => void>(() => {
    onSelectItem(searchFilterNanoId);
  }, [searchFilterNanoId, onSelectItem]);

  const handleDeleteFilter = useCallback<() => Promise<unknown>>(
    () => deleteSearchFilter({ uuid: searchFilterNanoId }),
    [searchFilterNanoId, deleteSearchFilter]
  );

  const handleCloseConfirmModal = useCallback<() => void>(() => {
    deleteSearchFilterReset();
  }, [deleteSearchFilterReset]);

  return (
    <li>
      <div className="flex hover:bg-gray-500/20 rounded-md">
        <PureButtonHelper
          className="flex-1 px-2 py-1 text-sm text-left rounded-md truncate max-w-64"
          text={text}
          onClick={handleSelectItem}
        />
        <ConfirmModalButton
          className="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0 inline-flex"
          errorMessage={deleteSearchFilterErrorMessage}
          i18nSubmitText={words.delete}
          i18nText={
            stringsKeys.areYouSureYouWantToDeleteSavedFilterThisActionCannotBeUndone
          }
          i18nTitle={words.delete_filter}
          icon={IconsEnum.X}
          iconClassName="h-4 w-4"
          id="delete-saved-filter-modal-button"
          isLoading={deleteSearchFilterLoading}
          modalIcon={IconsEnum.EXCLAMATION}
          modalIconClassName="h-6 w-6 text-red-600"
          onClose={handleCloseConfirmModal}
          onSubmit={handleDeleteFilter}
        />
      </div>
    </li>
  );
}

export default ItemMessagesSavedFiltersButtonFilterItem;
