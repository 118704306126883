import React, { Fragment, memo, useState } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { ItemMessagesListMessageIdButtons } from '../ItemMessagesListMessageIdButtons';

import { Icon } from '../../../../../../../helpers/Icon';

import { DateTimeHelper } from '../../../../../../../helpers/DateTimeHelper';
import { PeriodHelper } from '../../../../../../../helpers/PeriodHelper';
import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { Translate } from '../../../../../../../helpers/Translate';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { messagesKeys } from '../../../../../../../locales/keys';

import { ItemMessagesListTrackTimeMessageItem } from './ItemMessagesListTrackTimeMessage.types';

interface ItemMessagesListTrackTimeMessageProps {
  message: ItemMessagesListTrackTimeMessageItem;
}

function ItemMessagesListTrackTimeMessage({
  message
}: ItemMessagesListTrackTimeMessageProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);
  return (
    <Fragment>
      <div
        id={`message-${message.uuid}`}
        className="text-center text-xs text-gray-500 dark:text-gray-400 mt-6 mb-4"
        ref={setReferenceTooltipElement}
      >
        <div className="text-center">
          <Icon
            className="w-4 h-4 inline-block -mt-0.5 text-current"
            icon={IconsEnum.CLOCK}
          />
          <span>
            {' '}
            <ItemMessagesListMessageIdButtons message={message} />
            {message.user?.fullName} <Translate id={messagesKeys.tracked} />{' '}
            <PeriodHelper hours={message.hours} minutes={message.minutes} />
          </span>
        </div>
        <div className="mt-0.5 mx-auto max-w-xl">
          <span>{message.body}</span>
        </div>
      </div>
      <Tooltip
        withArrow
        placement={TooltipPlacement.TOP}
        referenceElement={referenceTooltipElement}
      >
        <DateTimeHelper date={message.createdAt} />
      </Tooltip>
    </Fragment>
  );
}

export default memo<ItemMessagesListTrackTimeMessageProps>(
  ItemMessagesListTrackTimeMessage
);
