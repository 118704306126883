import React from 'react';

import { IsLoading } from '../../../../../../../../../types';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';

import { PureTooltipIconButtonHelper } from '../../../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipSingletonSourceWrapper } from '../../../../../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { TooltipPlacement } from '../../../../../../../../../helpers/tooltips/tooltipsConstants';
import { words } from '../../../../../../../../../locales/keys';

interface ItemMessagesListSendingMessageMenuProps {
  isTrySendAgainLoading?: IsLoading;
  onTrySendAgain?: () => void;
  onRemoveSendingMessage?: () => void;
}

function ItemMessagesListSendingMessageMenu({
  isTrySendAgainLoading,
  onTrySendAgain,
  onRemoveSendingMessage
}: ItemMessagesListSendingMessageMenuProps) {
  return (
    <TooltipSingletonSourceWrapper placement={TooltipPlacement.TOP} withArrow>
      <div className="flex z-5 sm:opacity-0 group-hover:opacity-100 focus-within:opacity-100 self-stretch items-center space-x-0.5 order-first flex-row-reverse mr-2 space-x-reverse">
        {onTrySendAgain ? (
          <PureTooltipIconButtonHelper
            className="py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-400 dark:text-gray-500 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0 inline-flex dark:hover:bg-gray-900 hover:bg-white"
            iconClassName="h-5 w-5"
            tooltipI18nText={words.tryAgain}
            icon={IconsEnum.REFRESH_SOLID}
            tooltipSingleton
            onClick={onTrySendAgain}
            disabled={isTrySendAgainLoading}
          />
        ) : null}
        {onRemoveSendingMessage ? (
          <PureTooltipIconButtonHelper
            className="py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-400 dark:text-gray-500 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0 inline-flex dark:hover:bg-gray-900 hover:bg-white"
            iconClassName="h-5 w-5"
            tooltipI18nText={words.remove}
            icon={IconsEnum.CROSS}
            tooltipSingleton
            onClick={onRemoveSendingMessage}
          />
        ) : null}
      </div>
    </TooltipSingletonSourceWrapper>
  );
}

export default ItemMessagesListSendingMessageMenu;
