import {
  FetchTodoItemsInTaskCacheKey,
  FetchTodoItemInTaskCacheKey
} from './todoItemsTypes';

import { TaskNanoID } from '../tasks/tasksTypes';

export class TaskTodoItemsCache {
  static indexCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-todo-items` as FetchTodoItemsInTaskCacheKey;
  }

  static showCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-todo-item` as FetchTodoItemInTaskCacheKey;
  }
}
