import { gql } from 'graphql-request';

import { FileAttachmentUUID } from '../fileAttachmentsTypes';

export interface DownloadFileAttachmentQueryResponse {
  uuid: FileAttachmentUUID;
}

export const DOWNLOAD_FILE_ATTACHMENT_QUERY = gql`
  mutation DownloadFileAttachment($uuid: ID!, $deviceNanoId: String!) {
    downloadFileAttachment(
      input: { uuid: $uuid, deviceNanoId: $deviceNanoId }
    ) {
      recordUuid
      status
      record {
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
