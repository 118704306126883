import React, { Fragment, memo, useState } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';

import { ItemMessagesListPaidBillMessageItem } from './ItemMessagesListPaidBillMessage.types';

import { Icon } from '../../../../../../../helpers/Icon';
import { MoneyHelper } from '../../../../../../../helpers/MoneyHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { words, invoicesKeys } from '../../../../../../../locales/keys';

import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';
import { ItemMessagesListMessageIdButtons } from '../ItemMessagesListMessageIdButtons';
import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { DateTimeHelper } from '../../../../../../../helpers/DateTimeHelper';

interface ItemMessagesListPaidBillMessageProps {
  message: ItemMessagesListPaidBillMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
}

function ItemMessagesListPaidBillMessage({
  message,
  messagesCacheKey
}: ItemMessagesListPaidBillMessageProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);
  return (
    <Fragment>
      <div
        id={`message-${message.uuid}`}
        className="text-center text-xs text-gray-500 dark:text-gray-400 mt-6 mb-4"
        ref={setReferenceTooltipElement}
      >
        <div className="text-center">
          <Icon
            className="w-4 h-4 inline-block -mt-0.5 text-current"
            icon={IconsEnum.RECEIPT_REFUND}
          />
          <span>
            <ItemMessagesListMessageIdButtons message={message} />
            {message.paidBill?.payer?.fullName} <Translate id={words.paid} />{' '}
            <MoneyHelper
              withSuffix
              value={message.paidBill?.amount}
              currency={message.paidBill?.currency}
            />{' '}
            <Translate id={words.accordingTo} />{' '}
            <Translate id={invoicesKeys.singular} /> #{message.paidBill?.id}
          </span>
        </div>

        <ItemMessagesListMessageSendEmailNotificationButton
          message={message}
          messagesCacheKey={messagesCacheKey}
          className="flex items-center justify-center"
        />
      </div>
      <Tooltip
        withArrow
        placement={TooltipPlacement.TOP}
        referenceElement={referenceTooltipElement}
      >
        <DateTimeHelper date={message.createdAt} />
      </Tooltip>
    </Fragment>
  );
}

export default memo<ItemMessagesListPaidBillMessageProps>(
  ItemMessagesListPaidBillMessage
);
