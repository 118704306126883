import { useCallback } from 'react';
import omit from 'lodash/omit';

import {
  ItemMessagesFilters,
  ItemMessagesListFilterMessages
} from '../../../../list/ItemMessagesList';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useMessagesSettings } from '../../../../../hooks/useMessagesSettings';

export enum ItemMessagesSettingsFormFields {
  AUTO_PIN_MESSAGE_WITH_TODO_ITEM = 'autoPinMessageWithTodoItem',
  DISPLAY_TIME = 'displayTime',
  WITH_WHITEBOARD_MESSAGES = 'withWhiteboardMessages'
}

export type ItemMessagesSettingsFormData = {
  [ItemMessagesSettingsFormFields.AUTO_PIN_MESSAGE_WITH_TODO_ITEM]: boolean;
  [ItemMessagesSettingsFormFields.DISPLAY_TIME]: boolean;
  [ItemMessagesSettingsFormFields.WITH_WHITEBOARD_MESSAGES]: boolean;
};

interface useItemMessagesFilterFormProps {
  messagesFilters: ItemMessagesFilters;
  filterMessages: ItemMessagesListFilterMessages;
}

function useItemMessagesSettingsForm({
  filterMessages,
  messagesFilters
}: useItemMessagesFilterFormProps) {
  const { messagesSettings, resetMessagesSettings, setMessagesSettings } =
    useMessagesSettings();

  const { control, handleSubmitReactHookForm, resetForm } =
    useReactHookForm<ItemMessagesSettingsFormData>({
      defaultValues: {
        [ItemMessagesSettingsFormFields.AUTO_PIN_MESSAGE_WITH_TODO_ITEM]:
          messagesSettings.autoPinMessageWithTodoItem,
        [ItemMessagesSettingsFormFields.DISPLAY_TIME]:
          messagesSettings.displayTime === 0,
        [ItemMessagesSettingsFormFields.WITH_WHITEBOARD_MESSAGES]:
          messagesSettings.withWhiteboardMessages
      },
      isModalForm: true
    });

  const resetFilterMessagesForm = useCallback<() => void>(() => {
    resetForm({
      [ItemMessagesSettingsFormFields.AUTO_PIN_MESSAGE_WITH_TODO_ITEM]:
        messagesSettings.autoPinMessageWithTodoItem,
      [ItemMessagesSettingsFormFields.DISPLAY_TIME]:
        messagesSettings.displayTime === 0,
      [ItemMessagesSettingsFormFields.WITH_WHITEBOARD_MESSAGES]:
        messagesSettings.withWhiteboardMessages
    });
  }, [messagesSettings, resetForm]);

  const clearFilterMessagesForm = useCallback<() => void>(() => {
    resetMessagesSettings();
    filterMessages(omit(messagesFilters, ['whiteboardKeyId']));
  }, [filterMessages, messagesFilters, resetMessagesSettings]);

  return {
    control,
    resetFilterMessagesForm,
    handleSubmitReactHookForm,
    handleSubmitFilterMessagesForm: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: (data) => {
        filterMessages(
          data.withWhiteboardMessages
            ? omit(messagesFilters, ['whiteboardKeyId'])
            : { ...messagesFilters, whiteboardKeyId: { isNull: true } }
        );

        setMessagesSettings({
          autoPinMessageWithTodoItem: data.autoPinMessageWithTodoItem,
          displayTime: data.displayTime ? 0 : 15,
          withWhiteboardMessages: data.withWhiteboardMessages
        });
      }
    }),
    clearFilterMessagesForm
  };
}

export default useItemMessagesSettingsForm;
