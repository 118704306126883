import { useCallback } from 'react';
import size from 'lodash/size';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { PinnedMessageWithTodoListMessageItem } from '../../../PinnedMessageWithTodoListMessage/PinnedMessageWithTodoListMessage.types';

import {
  DownloadMessageFileAttachmentsQueryResponse,
  DOWNLOAD_MESSAGE_FILE_ATTACHMENTS_QUERY
} from '../../../../../queries/downloadMessageFileAttachments.query';
import {
  DOWNLOAD_MESSAGE_SELECTED_LIFESTYLES_QUERY,
  DownloadMessageSelectedLifestylesQueryResponse
} from '../../../../../queries/downloadMessageSelectedLifestyles.query';
import {
  DOWNLOAD_MESSAGE_SELECTED_PRODUCTS_QUERY,
  DownloadMessageSelectedProductsQueryResponse
} from '../../../../../queries/downloadMessageSelectedProducts.query';

import { useDownloadNanoId } from '../../../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadMessageFileAttachments } from '../../../../../hooks/useDownloadMessageFileAttachments';
import { useDownloadMessageSelectedProducts } from '../../../../../hooks/useDownloadMessageSelectedProducts';
import { useDownloadMessageSelectedLifestyles } from '../../../../../hooks/useDownloadMessageSelectedLifestyles';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { messagesKeys } from '../../../../../../../locales/keys';

interface PinnedMessageWithTodoListDownloadAllFileAttachmentsButtonProps {
  messages: PinnedMessageWithTodoListMessageItem[];
}

function PinnedMessageWithTodoListDownloadAllFileAttachmentsButton({
  messages
}: PinnedMessageWithTodoListDownloadAllFileAttachmentsButtonProps) {
  const { downloadNanoId } = useDownloadNanoId();

  const {
    downloadMessageFileAttachments,
    downloadMessageFileAttachmentsLoading
  } = useDownloadMessageFileAttachments<DownloadMessageFileAttachmentsQueryResponse>(
    {
      query: DOWNLOAD_MESSAGE_FILE_ATTACHMENTS_QUERY
    }
  );

  const {
    downloadMessageSelectedProducts,
    downloadMessageSelectedProductsLoading
  } = useDownloadMessageSelectedProducts<DownloadMessageSelectedProductsQueryResponse>(
    {
      query: DOWNLOAD_MESSAGE_SELECTED_PRODUCTS_QUERY
    }
  );

  const {
    downloadMessageSelectedLifestyles,
    downloadMessageSelectedLifestylesLoading
  } = useDownloadMessageSelectedLifestyles<DownloadMessageSelectedLifestylesQueryResponse>(
    {
      query: DOWNLOAD_MESSAGE_SELECTED_LIFESTYLES_QUERY
    }
  );

  const handleDownloadResults = useCallback<() => void>(
    () =>
      messages?.map((message) => {
        if (size(message.fileAttachments) > 0) {
          return downloadMessageFileAttachments({
            uuid: message.uuid,
            deviceNanoId: downloadNanoId
          });
        }

        if (size(message.selectedProducts) > 0) {
          return downloadMessageSelectedProducts({
            uuid: message.uuid,
            deviceNanoId: downloadNanoId
          });
        }

        if (size(message.selectedLifestyles) > 0) {
          return downloadMessageSelectedLifestyles({
            uuid: message.uuid,
            deviceNanoId: downloadNanoId
          });
        }
      }),
    [
      downloadMessageFileAttachments,
      downloadMessageSelectedLifestyles,
      downloadMessageSelectedProducts,
      downloadNanoId,
      messages
    ]
  );

  return (
    <PureTooltipIconButtonHelper
      className="py-1 pl-1 pr-2 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
      icon={IconsEnum.DOWNLOAD_SOLID}
      iconClassName="w-5 h-5"
      onClick={handleDownloadResults}
      i18nText={messagesKeys.downloadAllAttachments}
      i18nTextClassName="inline"
      tooltipI18nText={messagesKeys.downloadAllAttachments}
      tooltipPlacement={TooltipPlacement.TOP}
      disabled={
        downloadMessageFileAttachmentsLoading ||
        downloadMessageSelectedProductsLoading ||
        downloadMessageSelectedLifestylesLoading
      }
    />
  );
}

export default PinnedMessageWithTodoListDownloadAllFileAttachmentsButton;
