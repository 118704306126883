import { useState, useCallback } from 'react';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';

import { ErrorMessage } from '../../../../../../../types';
import { MessageUUID } from '../../../../../../messages/messagesTypes';
import { FetchTaskMessagesQueryResponse } from '../../../../../../messages/queries/fetchTaskMessages.query';

import {
  CREATE_MESSAGE_IN_TASK_QUERY,
  CreateMessageInTaskQueryResponse
} from '../../../../../../messages/queries/createMessageInTask.query';

import {
  useCreateMessageInTask,
  CreateMessageInTaskInput
} from '../../../../../../messages/hooks/useCreateMessageInTask';

import {
  useCreateMockMessageWithFormData,
  CreateMockMessageWithFormDataTask,
  CreateMockMessageWithFormDataOptions
} from '../../../../../../messages/components/list/ItemMessagesList/hooks/useCreateMockMessageWithFormData';

import { ItemCreateMessageFormSubmit } from '../../../../../../messages/components/forms/ItemCreateMessageForm';
import { ItemMessagesListMessage } from '../../../../../../messages/components/list/ItemMessagesList';

import { parseRequestError } from '../../../../../../../utils/parseRequestError';

interface TaskMessagesContentCreateMessageOptions {
  task?: CreateMockMessageWithFormDataTask;
  onAfterCreateMessage?: (newMessage: FetchTaskMessagesQueryResponse) => void;
}

function useTaskMessagesContentCreateMessage({
  task,
  onAfterCreateMessage
}: TaskMessagesContentCreateMessageOptions) {
  const [showClosedTaskMessageForm, setShowClosedTaskMessageForm] =
    useState<boolean>(false);

  const handleShowCreateMessageForm = useCallback<() => Promise<unknown>>(
    async () => setShowClosedTaskMessageForm(true),
    [setShowClosedTaskMessageForm]
  );

  const [sendingMessages, setSendingMessages] = useState<
    ItemMessagesListMessage[]
  >([]);

  const handleRemoveSendingMessage = useCallback<
    (messageUuid: MessageUUID) => void
  >((messageUuid) => {
    setSendingMessages((prev) => {
      return remove(
        prev,
        (sendingMessage) => sendingMessage.uuid !== messageUuid
      );
    });
  }, []);

  const { createMessageInTask, createMessageInTaskLoading } =
    useCreateMessageInTask<CreateMessageInTaskQueryResponse>({
      taskNanoId: task?.nanoId,
      query: CREATE_MESSAGE_IN_TASK_QUERY
    });

  const handleMarkSendingMessageFailed = useCallback<
    (messageUuid: MessageUUID, errorMessage: ErrorMessage) => void
  >((messageUuid, errorMessage) => {
    setSendingMessages((prev) => {
      const failedMessageIndex = findIndex(
        prev,
        (sendingMessage) => sendingMessage.uuid === messageUuid
      );

      if (failedMessageIndex === -1) {
        return prev;
      } else {
        const newSendingMessages = [...prev];
        newSendingMessages[failedMessageIndex] = {
          ...prev[failedMessageIndex],
          errorMessage
        };
        return newSendingMessages;
      }
    });
  }, []);

  const createMockMessageFromFormData = useCreateMockMessageWithFormData();

  const handleCreateMessageInTask = useCallback<ItemCreateMessageFormSubmit>(
    async (data) => {
      const newSendingMessage = createMockMessageFromFormData({
        formData: data,
        task
      } as CreateMockMessageWithFormDataOptions);

      setSendingMessages((prev) => [...prev, newSendingMessage]);

      try {
        const result = await createMessageInTask({
          ...data,
          taskId: task?.id
        } as CreateMessageInTaskInput);

        handleRemoveSendingMessage(result?.createMessageInTask?.record?.uuid);

        onAfterCreateMessage?.(result?.createMessageInTask?.record);

        return result;
      } catch (err) {
        const errorMessage = parseRequestError(err);
        handleMarkSendingMessageFailed(data.uuid as MessageUUID, errorMessage);
        throw err;
      }
    },
    [
      createMockMessageFromFormData,
      task,
      createMessageInTask,
      handleRemoveSendingMessage,
      onAfterCreateMessage,
      handleMarkSendingMessageFailed
    ]
  );

  return {
    createMessageInTaskLoading,
    showClosedTaskMessageForm,
    handleShowCreateMessageForm,
    sendingMessages,
    handleCreateMessageInTask,
    handleRemoveSendingMessage
  };
}

export default useTaskMessagesContentCreateMessage;
