import map from 'lodash/map';
import includes from 'lodash/includes';

import { UserUUID } from '../../../../../../../../users/usersTypes';

import {
  UserCheckboxField,
  UserCheckboxFieldUserType,
  UserCheckboxFieldOnCheck
} from '../UserCheckboxField';

interface UsersCheckboxListProps {
  users: UserCheckboxFieldUserType[];
  checkedUuids: UserUUID[];
  onCheck: UserCheckboxFieldOnCheck;
}

function UsersCheckboxList({
  users,
  checkedUuids,
  onCheck
}: UsersCheckboxListProps) {
  if (!users.length) return null;

  return (
    <ul>
      {map(users, (user) => (
        <li key={user.uuid}>
          <UserCheckboxField
            user={user}
            checked={includes(checkedUuids, user.uuid)}
            onCheck={onCheck}
          />
        </li>
      ))}
    </ul>
  );
}

export default UsersCheckboxList;
