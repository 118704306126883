import { useCallback } from 'react';

import includes from 'lodash/includes';
import snakeCase from 'lodash/snakeCase';
import toUpper from 'lodash/toUpper';

import {
  FetchItemsSort,
  FetchItemsSortableField,
  FetchItemsSortItems
} from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

interface IndexTableHeaderSortableCellOptions {
  currentSort?: FetchItemsSort;
  onSort: FetchItemsSortItems;
  sortableField: FetchItemsSortableField;
  ascField?: string;
  descField?: string;
  defaultSort?: boolean;
}

function useIndexTableHeaderSortableCell({
  currentSort,
  onSort,
  sortableField,
  ascField,
  descField,
  defaultSort
}: IndexTableHeaderSortableCellOptions) {
  const upCasedField = toUpper(snakeCase(sortableField));

  const ascSort = ascField || `${upCasedField}_ASC`;

  const descSort = descField || `${upCasedField}_DESC`;

  const isSortedAsc = includes(currentSort, ascSort);

  const isSortedDesc = includes(currentSort, descSort);

  const sortIcon = isSortedDesc ? IconsEnum.SORT_DESC : IconsEnum.SORT_ASC;

  const handleSortItems = useCallback(() => {
    if (isSortedAsc && !defaultSort) {
      return onSort?.([]);
    }

    if (isSortedDesc) {
      return onSort?.([ascSort]);
    }

    return onSort?.([descSort]);
  }, [defaultSort, isSortedAsc, isSortedDesc, onSort, descSort, ascSort]);

  return {
    isSortedDesc,
    isSortedAsc,
    sortIcon,
    handleSortItems
  };
}

export default useIndexTableHeaderSortableCell;
