import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { InvoiceID, InvoiceNanoID } from '../../../invoicesTypes';

import { useToastNotification } from '../../../../../common/hooks/useToastNotification';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { copyToClipboard } from '../../../../../utils/copyToClipboard';

import { InvoicePath } from '../../../InvoicePath';

import { words } from '../../../../../locales/keys';

interface InvoiceLinkButtonProps {
  invoiceId: InvoiceNanoID | InvoiceID;
  icon?: IconsEnum;
  i18nText?: I18nText;
  className?: ClassName;
  iconClassName?: ClassName;
  tooltipI18nText?: I18nText;
  disabled?: boolean;
  onClick?: () => void;
}

function InvoiceLinkButton({
  invoiceId,
  className,
  i18nText,
  icon,
  iconClassName,
  tooltipI18nText,
  disabled,
  onClick
}: InvoiceLinkButtonProps) {
  const { showToastI18nNotification } = useToastNotification({
    appearance: 'success',
    i18nMessage: words.linkCopied
  });

  const handleCopyLifestyleUrl = useCallback(() => {
    copyToClipboard(
      `${window.location.origin}${InvoicePath.paymentsInvoice(invoiceId)}`
    );
    onClick?.();
    showToastI18nNotification();
  }, [invoiceId, onClick, showToastI18nNotification]);

  return (
    <PureTooltipIconButtonHelper
      className={className}
      icon={icon}
      tooltipI18nText={tooltipI18nText}
      i18nText={i18nText}
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleCopyLifestyleUrl}
      iconClassName={iconClassName}
      disabled={disabled}
    />
  );
}

export default InvoiceLinkButton;
