import React from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import {
  FetchUsersFetched,
  FetchUsersTotalCount
} from '../../../../users/usersTypes';

import {
  ItemMessagesFilters,
  ItemMessagesListFilterMessages
} from '../../list/ItemMessagesList';
import { MessagesFilterModalButtonUsers } from './MessagesFilterModalButton.types';

import { useItemMessagesFilterForm } from '../../forms/ItemMessagesFilterForm/hooks/useItemMessagesFilterForm';

import { ItemMessagesFilterForm } from '../../forms/ItemMessagesFilterForm';

import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { messagesKeys, words } from '../../../../../locales/keys';

const MESSAGES_FILTER_FORM = 'messages-filter-form';
const MESSAGES_FILTER_MODAL_BUTTON = 'messages-filter-modal-button';

interface MessagesFilterModalButtonProps {
  messagesFilters: ItemMessagesFilters;
  filterMessages: ItemMessagesListFilterMessages;
  users: MessagesFilterModalButtonUsers;
  usersFetched: FetchUsersFetched;
  usersTotalCount: FetchUsersTotalCount;

  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
  disabled?: IsDisabled;
}

function MessagesFilterModalButton({
  filterMessages,
  messagesFilters,
  users,
  usersFetched,
  usersTotalCount,
  i18nText,
  icon,
  iconClassName,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton,
  disabled
}: MessagesFilterModalButtonProps) {
  const {
    control,
    clearFilterMessagesForm,
    handleSubmitFilterMessagesForm,
    resetFilterMessagesForm,
    isEmptyMessagesFilters
  } = useItemMessagesFilterForm({
    filterMessages,
    messagesFilters
  });

  return (
    <SimpleModalButton
      id={MESSAGES_FILTER_MODAL_BUTTON}
      className={
        isEmptyMessagesFilters
          ? 'py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white focus:ring-offset-0'
          : 'py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base focus:ring-offset-0 text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300'
      }
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      i18nSubmitText={words.apply}
      i18nTitle={messagesKeys.messagesFilter}
      i18nText={i18nText}
      icon={icon || IconsEnum.ADJUSTMENTS_ALT_SOLID}
      iconClassName={iconClassName || 'h-4 w-4'}
      tooltipI18nText={tooltipI18nText || messagesKeys.messagesFilter}
      tooltipPlacement={tooltipPlacement}
      tooltipSingleton={tooltipSingleton}
      modalSize="xl"
      submitDisabled={disabled}
      cancelButtonPosition="start"
      onCancel={clearFilterMessagesForm}
      i18nCancelText={words.reset}
      withCloseButton
      onOpen={resetFilterMessagesForm}
      onSubmit={handleSubmitFilterMessagesForm}
    >
      <ItemMessagesFilterForm
        control={control}
        users={users}
        usersFetched={usersFetched}
        usersTotalCount={usersTotalCount}
        form={MESSAGES_FILTER_FORM}
      />
    </SimpleModalButton>
  );
}

export default MessagesFilterModalButton;
