import React, { useMemo } from 'react';
import find from 'lodash/find';
import map from 'lodash/map';

import { IconsEnum } from '../../../../../assets/icons/types';
import { I18nText } from '../../../../../types';
import {
  FetchMessagesCacheKey,
  FetchMessagesErrorMessage,
  FetchMessagesFetched,
  FetchMessagesIsPlaceholderData
} from '../../../messagesTypes';
import { TaskNanoID } from '../../../../tasks/tasksTypes';
import { ProjectNanoID } from '../../../../projects/projectsTypes';
import {
  PinnedMessageWithTodoListMessageItem,
  PinnedMessageWithTodoListMessageItemWithTodo
} from '../PinnedMessageWithTodoListMessage/PinnedMessageWithTodoListMessage.types';

import {
  FETCH_TODO_LIST_TODO_ITEMS,
  FetchTodoListTodoItemsQueryResponse
} from '../../../../todoItems/queries/fetchTodoListTodoItems.query';

import { usePaginatedTodoItems } from '../../../../todoItems/hooks/usePaginatedTodoItems';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { PinnedMessageWithTodoListMessage } from '../PinnedMessageWithTodoListMessage';
import { PinnedMessageWithTodoListDownloadAllFileAttachmentsButton } from './components/PinnedMessageWithTodoListDownloadAllFileAttachmentsButton';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { Translate } from '../../../../../helpers/Translate';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { ProjectTodoItemsCache } from '../../../../todoItems/ProjectTodoItemsCache';
import { TaskTodoItemsCache } from '../../../../todoItems/TaskTodoItemsCache';

import { MessagesPermissions } from '../../../messagesConstants';

interface PinnedMessageWithTodoListTaskProps {
  taskNanoId: TaskNanoID;
  projectNanoId?: never;
}

interface PinnedMessageWithTodoListProjectProps {
  taskNanoId?: never;
  projectNanoId: ProjectNanoID;
}

interface PinnedMessageWithTodoListDefaultProps {
  i18nTitle: I18nText;
  onBackClick: () => void;
  messages: PinnedMessageWithTodoListMessageItem[];
  messagesFetched: FetchMessagesFetched;
  messagesErrorMessage: FetchMessagesErrorMessage;
  messagesIsPlaceholderData: FetchMessagesIsPlaceholderData;
  messagesCacheKey: FetchMessagesCacheKey;
}

type PinnedMessageWithTodoListProps = PinnedMessageWithTodoListDefaultProps &
  (PinnedMessageWithTodoListTaskProps | PinnedMessageWithTodoListProjectProps);

function PinnedMessageWithTodoList({
  i18nTitle,
  onBackClick,
  messages,
  messagesErrorMessage,
  messagesIsPlaceholderData,
  messagesFetched,
  messagesCacheKey,
  projectNanoId,
  taskNanoId
}: PinnedMessageWithTodoListProps) {
  const currentUser = useCurrentUser();

  const cacheKey = useMemo(
    () =>
      projectNanoId
        ? ProjectTodoItemsCache.indexCacheKey(projectNanoId)
        : TaskTodoItemsCache.indexCacheKey(taskNanoId),
    [projectNanoId, taskNanoId]
  );

  const {
    todoItems,
    todoItemsError,
    todoItemsFetched,
    todoItemsIsPlaceholderData,
    updateTodoItemCache
  } = usePaginatedTodoItems<FetchTodoListTodoItemsQueryResponse>({
    cacheKey: cacheKey,
    query: FETCH_TODO_LIST_TODO_ITEMS,
    initialFilters: { projectNanoId, taskNanoId },
    initialLimit: 1000
  });

  const messagesWithCheckedTodoItems = useMemo<
    PinnedMessageWithTodoListMessageItemWithTodo[]
  >(
    () =>
      map(messages, (message) => {
        const todoItem = find(
          todoItems,
          (todoItem) => todoItem.name === message.uuid
        );

        return {
          ...message,
          done: todoItem?.done,
          todoItemUuid: todoItem?.uuid
        };
      }).sort(
        (a, b) =>
          new Date(a.pinnedAt).getTime() - new Date(b.pinnedAt).getTime()
      ),
    [messages, todoItems]
  );

  const withCheckBox = currentUser.hasPermissions(
    MessagesPermissions.READ_PINNED_MESSAGES_TODO_CHECKBOX
  );

  return (
    <div className="flex-1 relative">
      <div className="absolute inset-0 overflow-y-auto overflow-x-hidden">
        <div className="py-4 px-2 sm:px-4 flex flex-col min-h-full justify-start m-auto max-w-screen-lg isolate">
          <AlertMessage
            addClassName="m-4"
            message={messagesErrorMessage || todoItemsError}
          />
          <LoadingSkeleton
            loaded={
              (messagesIsPlaceholderData || messagesFetched) &&
              (todoItemsFetched || todoItemsIsPlaceholderData)
            }
          >
            <div className="flex items-center gap-x-2 mb-3">
              <PureIconButtonHelper
                className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                icon={IconsEnum.ARROW_LEFT_OUTLINE}
                iconClassName="stroke-2 w-6 h-6"
                onClick={onBackClick}
              />
              <h6 className="text-lg font-medium whitespace-nowrap">
                <Translate id={i18nTitle} />
              </h6>

              <CheckPermissions
                action={
                  MessagesPermissions.READ_PINNED_MESSAGES_DOWNLOAD_ALL_BUTTON
                }
              >
                <div className="flex-1 flex justify-end">
                  <PinnedMessageWithTodoListDownloadAllFileAttachmentsButton
                    messages={messages}
                  />
                </div>
              </CheckPermissions>
            </div>

            <div className="space-y-3">
              {messagesWithCheckedTodoItems.map((message) => (
                <PinnedMessageWithTodoListMessage
                  key={message.uuid}
                  message={message}
                  todoItemsCacheKey={cacheKey}
                  messagesCacheKey={messagesCacheKey}
                  updateTodoItemCache={updateTodoItemCache}
                  withCheckBox={withCheckBox}
                />
              ))}
            </div>
          </LoadingSkeleton>
        </div>
      </div>
    </div>
  );
}

export default PinnedMessageWithTodoList;
