import { MessageSortType, MessageUUID } from '../../messagesTypes';
import { UpdateMessageSortTypeRecordType } from '../../queries/updateMessageSortType.query';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateMessageSortTypeOptions {
  indexCacheKey: string;
  query: string;
}

interface UpdateMessageSortTypeInput {
  uuid: MessageUUID;
  sortType: MessageSortType;
}

interface UpdateMessageSortTypeError {
  fullMessages: string[] | null;
}

interface UpdateMessageSortTypeResponse<UpdateMessageSortTypeRecord> {
  updateMessageSortType: {
    status: string;
    record: UpdateMessageSortTypeRecord;
    errors: UpdateMessageSortTypeError;
  };
}

const action = 'updateMessageSortType';

function useUpdateMessageSortType<
  UpdateMessageSortTypeRecord extends UpdateMessageSortTypeRecordType
>({ indexCacheKey, query }: UpdateMessageSortTypeOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateMessageSortTypeInput,
    UpdateMessageSortTypeResponse<UpdateMessageSortTypeRecord>,
    UpdateMessageSortTypeError,
    UpdateMessageSortTypeRecord
  >({
    action,
    cacheKeys: [indexCacheKey],
    query
  });

  return {
    updateMessageSortTypeData: updateQueryData,
    updateMessageSortTypeError: updateQueryError,
    updateMessageSortTypeLoading: updateQueryLoading,
    updateMessageSortTypeErrorMessage: updateQueryErrorMessage,
    updateMessageSortType: updateQuery,
    updateMessageSortTypeReset: updateQueryReset
  };
}

export default useUpdateMessageSortType;
