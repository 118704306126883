import React from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import {
  ItemMessagesFilters,
  ItemMessagesListFilterMessages
} from '../../list/ItemMessagesList';

import { ItemMessagesSavedFiltersButtonContent } from './components/ItemMessagesSavedFiltersButtonContent';

import { PureTooltipIconRefButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconRefButtonHelper';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import {
  PopoverWithPortal,
  usePopoverState
} from '../../../../../helpers/Popover';

import { PopoverPlacement } from '../../../../../helpers/Popover/popoverConstants';

interface ItemMessagesSavedFiltersButtonProps {
  messagesFilters: ItemMessagesFilters;
  filterMessages: ItemMessagesListFilterMessages;

  i18nText?: I18nText;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
  disabled?: IsDisabled;
}

function ItemMessagesSavedFiltersButton({
  filterMessages,
  messagesFilters,

  i18nText,
  className,
  icon,
  iconClassName,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton,
  disabled
}: ItemMessagesSavedFiltersButtonProps) {
  const {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef,
    togglePopover,
    closePopover
  } = usePopoverState();

  return (
    <>
      <PureTooltipIconRefButtonHelper
        ref={referencePopoverCallbackRef}
        i18nText={i18nText}
        className={className}
        icon={icon}
        iconClassName={iconClassName}
        tooltipI18nText={tooltipI18nText}
        tooltipPlacement={tooltipPlacement}
        tooltipSingleton={tooltipSingleton}
        disabled={disabled}
        onClick={togglePopover}
      />
      {isPopoverOpen ? (
        <PopoverWithPortal
          referenceElement={referencePopoverElement}
          placement={PopoverPlacement.BOTTOM_START}
          closePopover={closePopover}
          distanceOffset={6}
          className="min-w-48 z-20 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700"
        >
          <ItemMessagesSavedFiltersButtonContent
            filterMessages={filterMessages}
            messagesFilters={messagesFilters}
            closePopover={closePopover}
          />
        </PopoverWithPortal>
      ) : null}
    </>
  );
}

export default ItemMessagesSavedFiltersButton;
