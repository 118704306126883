import React, { useCallback } from 'react';

import { ClassName, ID, IsDisabled } from '../../../../../types';

import { Checkbox } from '../../../../../helpers/Checkbox';

interface IndexTableCheckboxProps {
  className?: ClassName;
  checkBoxClassName?: ClassName;
  scope: string;
  itemId: ID;
  checked: boolean;
  disabled?: IsDisabled;
  onCheck: (value: ID) => void;
}

function IndexTableCheckbox({
  className,
  checkBoxClassName,
  scope,
  itemId,
  checked,
  disabled,
  onCheck
}: IndexTableCheckboxProps) {
  const handleCheck = useCallback(() => onCheck(itemId), [itemId, onCheck]);

  return (
    <div
      className={
        className || 'flex items-center justify-center absolute inset-0'
      }
    >
      <Checkbox
        checkboxClassName={
          checkBoxClassName ||
          'basic-checkbox border-2 bg-transparent dark:bg-transparent border-gray-400 dark:border-gray-600 group-hover:border-gray-600 dark:group-hover:border-gray-400'
        }
        checked={checked}
        id={`check_${scope}_${itemId}`}
        disabled={disabled}
        onChange={handleCheck}
      />
    </div>
  );
}

export default IndexTableCheckbox;
