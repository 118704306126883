import { Control } from 'react-hook-form';

import {
  ItemMessagesSettingsFormData,
  ItemMessagesSettingsFormFields
} from './hooks/useItemMessagesSettingsForm';

import { ItemMessagesSettingsCheckboxField } from './components/ItemMessagesSettingsCheckboxField';

import { Form } from '../../../../../helpers/Form';

import { messagesKeys } from '../../../../../locales/keys';

interface ItemMessagesSettingsFormProps {
  form: string;
  control: Control<ItemMessagesSettingsFormData>;
}

function ItemMessagesSettingsForm({
  form,
  control
}: ItemMessagesSettingsFormProps) {
  return (
    <Form id={form} className="p-4 space-y-4">
      <ItemMessagesSettingsCheckboxField
        control={control}
        name={ItemMessagesSettingsFormFields.DISPLAY_TIME}
        i18nLabel={messagesKeys.showTimeStampForEveryMessage}
      />

      <ItemMessagesSettingsCheckboxField
        control={control}
        name={ItemMessagesSettingsFormFields.WITH_WHITEBOARD_MESSAGES}
        i18nLabel={messagesKeys.showWhiteboardMessages}
      />

      <ItemMessagesSettingsCheckboxField
        control={control}
        name={ItemMessagesSettingsFormFields.AUTO_PIN_MESSAGE_WITH_TODO_ITEM}
        i18nLabel={messagesKeys.automaticallyPinMessageWithTodoItem}
      />
    </Form>
  );
}

export default ItemMessagesSettingsForm;
