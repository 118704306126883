import React, { useCallback, useState } from 'react';

import { ItemMessagesListInvoiceMessageItem } from './ItemMessagesListInvoiceMessage.types';

import { ItemMessagesListInvoiceMessageDetailedView } from './components/ItemMessagesListInvoiceMessageDetailedView';
import { ItemMessagesListInvoiceMessageShortView } from './components/ItemMessagesListInvoiceMessageShortView';
import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';

import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';

import { FetchMessagesCacheKey } from '../../../../../messagesTypes';

interface ItemMessagesListInvoiceMessageProps {
  message: ItemMessagesListInvoiceMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
}
function ItemMessagesListInvoiceMessage({
  message,
  messagesCacheKey
}: ItemMessagesListInvoiceMessageProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleIsOpen = useCallback<() => void>(
    () => setIsOpen((prevState) => !prevState),
    []
  );

  return (
    <div id={`message-${message.uuid}`} className="my-10 p-2">
      <div className="flex flex-col flex-1">
        <LoadingSkeleton
          className="m-0 w-full"
          loaded={message.bitInvoiceFetched}
          count={9}
        >
          {message?.bitInvoice ? (
            <div className="w-full">
              <div className="border dark:border-gray-800">
                <div className="bg-white dark:bg-gray-900">
                  {isOpen ? (
                    <ItemMessagesListInvoiceMessageDetailedView
                      isOpen={isOpen}
                      message={message}
                      messagesCacheKey={messagesCacheKey}
                      toggleIsOpen={toggleIsOpen}
                    />
                  ) : (
                    <ItemMessagesListInvoiceMessageShortView
                      isOpen={isOpen}
                      message={message}
                      messagesCacheKey={messagesCacheKey}
                      toggleIsOpen={toggleIsOpen}
                    />
                  )}
                </div>
              </div>

              <ItemMessagesListMessageSendEmailNotificationButton
                message={message}
                messagesCacheKey={messagesCacheKey}
                className="flex items-center justify-center mt-1"
              />
            </div>
          ) : null}
        </LoadingSkeleton>

        <AlertMessage message={message.bitInvoiceErrorMessage} />
      </div>
    </div>
  );
}
export default ItemMessagesListInvoiceMessage;
