import React, { Fragment, memo } from 'react';

import {
  FetchMessagesCacheKey,
  MessageID,
  MessageProjectUUID,
  MessageTaskUUID,
  MessageUUID
} from '../../../messagesTypes';
import { CheckableMessagesProps } from '../../../../../common/hooks/useMessagesCheckable';

import {
  ItemMessagesListHasNextMessagesPage,
  ItemMessagesListLoadMoreMessages,
  ItemMessagesListMessages,
  ItemMessagesListMessagesErrorMessage,
  ItemMessagesListMessagesFetched,
  ItemMessagesListMessagesFetchingNextPage,
  ItemMessagesListMessagesIsPlaceholderData,
  ItemMessagesListOnRemoveSendingMessage
} from './ItemMessagesList.types';

import { useItemMessagesList } from './hooks/useItemMessagesList';
import { useMessagesSettings } from '../../../hooks/useMessagesSettings';

import { ItemMessagesListMessage } from '../ItemMessagesListMessage';

import { ScrollToBottom } from '../../../../../helpers/scrolls/ScrollToBottom';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import {
  FetchMoreInfiniteButtonHelper,
  FetchMoreInfiniteButtonHelperPlacement
} from '../../../../../helpers/buttons/FetchMoreInfiniteButtonHelper';

import { defineIsAuthorSame } from './utils/defineIsAuthorSame';
import { getDifferenceInMinutes } from '../../../../../utils/getDifferenceInMinutes';

import { messagesKeys, words } from '../../../../../locales/keys';

interface ItemMessagesListWithPreviousPage {
  messagesFetchingPreviousPage: ItemMessagesListMessagesFetchingNextPage;
  hasPreviousMessagesPage: ItemMessagesListHasNextMessagesPage;
  loadMorePreviousMessages: ItemMessagesListLoadMoreMessages;
}

interface ItemMessagesListWithoutPreviousPage {
  messagesFetchingPreviousPage?: never;
  hasPreviousMessagesPage?: never;
  loadMorePreviousMessages?: never;
}

interface ItemMessagesListDefault {
  messages: ItemMessagesListMessages;
  messagesCacheKey: FetchMessagesCacheKey;
  messagesFetched: ItemMessagesListMessagesFetched;
  messagesFetchingNextPage: ItemMessagesListMessagesFetchingNextPage;
  messagesErrorMessage: ItemMessagesListMessagesErrorMessage;
  messagesIsPlaceholderData: ItemMessagesListMessagesIsPlaceholderData;
  onReplyMessage?: (messageId: MessageID) => void;
  hasNextMessagesPage: ItemMessagesListHasNextMessagesPage;
  loadMoreMessages: ItemMessagesListLoadMoreMessages;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  sendingMessages?: ItemMessagesListMessages;
  onRemoveSendingMessage?: ItemMessagesListOnRemoveSendingMessage;
  goToLastMessage?: () => void;
  jumpToMessage?: (message: { id: MessageID; uuid: MessageUUID }) => void;
  markedUnhappyMessages?: boolean;
}

type ItemMessagesListProps = ItemMessagesListDefault &
  (ItemMessagesListWithPreviousPage | ItemMessagesListWithoutPreviousPage) &
  CheckableMessagesProps;

function ItemMessagesList({
  messages,
  messagesCacheKey,
  messagesErrorMessage,
  messagesFetched,
  messagesFetchingNextPage,
  messagesIsPlaceholderData,
  onReplyMessage,
  hasNextMessagesPage,
  loadMoreMessages,
  messagesFetchingPreviousPage,
  hasPreviousMessagesPage,
  loadMorePreviousMessages,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  sendingMessages,
  onRemoveSendingMessage,
  goToLastMessage,
  checkedMessages,
  onSetCheckedMessage,
  jumpToMessage,
  markedUnhappyMessages
}: ItemMessagesListProps) {
  const { messagesSettings } = useMessagesSettings();

  const {
    isDateShown,
    sortedMessages,
    messagesScrollElement,
    setMessagesScrollElement,
    toggleShowDate
  } = useItemMessagesList({
    messages,
    messagesFetched,
    sendingMessages,
    hasPreviousMessagesPage,
    goToLastMessage
  });

  return (
    <div className="flex-1 relative">
      <div
        id="message-thread"
        className="absolute inset-0 overflow-y-auto overflow-x-hidden"
        ref={setMessagesScrollElement}
      >
        <div className="flex flex-col min-h-full justify-end m-auto max-w-screen-lg isolate">
          <div className="py-4 px-2 sm:px-4">
            <AlertMessage addClassName="mt-4" message={messagesErrorMessage} />
            <LoadingSkeleton
              loaded={messagesIsPlaceholderData || messagesFetched}
            >
              <FetchMoreInfiniteButtonHelper
                errorMessage={messagesErrorMessage}
                isLoading={messagesFetchingNextPage}
                hasNextPage={hasNextMessagesPage}
                i18nText={messagesKeys.showOlder}
                className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                wrapperClassName="text-center mt-8"
                placement={FetchMoreInfiniteButtonHelperPlacement.TOP}
                onFetchMore={loadMoreMessages}
              />
              {sortedMessages.map((message, index) => (
                <Fragment key={message.uuid}>
                  <ItemMessagesListMessage
                    message={message}
                    messagesCacheKey={messagesCacheKey}
                    onReplyMessage={onReplyMessage}
                    forwardMessageProjectUuid={forwardMessageProjectUuid}
                    forwardMessageTaskUuid={forwardMessageTaskUuid}
                    prevSameAuthor={
                      defineIsAuthorSame(message, sortedMessages[index - 1]) &&
                      messagesSettings.displayTime &&
                      getDifferenceInMinutes(
                        message.createdAt,
                        sortedMessages[index - 1]?.createdAt
                      ) <= 15 &&
                      message.messageType ===
                        sortedMessages[index - 1]?.messageType
                    }
                    nextSameAuthor={
                      defineIsAuthorSame(message, sortedMessages[index + 1]) &&
                      messagesSettings.displayTime &&
                      getDifferenceInMinutes(
                        message.createdAt,
                        sortedMessages[index + 1]?.createdAt
                      ) <= 15 &&
                      message.messageType ===
                        sortedMessages[index + 1]?.messageType
                    }
                    onRemoveSendingMessage={onRemoveSendingMessage}
                    checkedMessages={checkedMessages}
                    onSetCheckedMessage={onSetCheckedMessage}
                    jumpToMessage={jumpToMessage}
                    markedUnhappyMessages={markedUnhappyMessages}
                    showDate={
                      !sortedMessages[index - 1] ||
                      !messagesSettings.displayTime ||
                      getDifferenceInMinutes(
                        message.createdAt,
                        sortedMessages[index - 1]?.createdAt
                      ) > messagesSettings.displayTime ||
                      isDateShown
                    }
                    toggleShowDate={toggleShowDate}
                  />
                </Fragment>
              ))}

              <FetchMoreInfiniteButtonHelper
                errorMessage={messagesErrorMessage}
                isLoading={messagesFetchingPreviousPage}
                hasNextPage={hasPreviousMessagesPage}
                i18nText={words.showMore}
                className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                wrapperClassName="text-center mt-8"
                placement={FetchMoreInfiniteButtonHelperPlacement.BOTTOM}
                onFetchMore={loadMorePreviousMessages}
                distanceToViewport={1500}
              />
            </LoadingSkeleton>
          </div>
        </div>
      </div>
      {messagesFetched ? (
        <ScrollToBottom
          scrollContainerElement={messagesScrollElement}
          i18nText={messagesKeys.toLatestMessages}
          customScrollToBottom={
            hasPreviousMessagesPage ? goToLastMessage : undefined
          }
        />
      ) : null}
    </div>
  );
}

export default memo<ItemMessagesListProps>(ItemMessagesList);
