import debounce from 'lodash/debounce';

class SyncScroll {
  inSync: HTMLElement[];

  currentInteractiveElem?: HTMLElement;
  resetCurrentInteractiveElemDebounced: () => void;

  constructor(inSync: HTMLElement[]) {
    this.handleElementsScroll = this.handleElementsScroll.bind(this);
    this.resetCurrentInteractiveElemDebounced = debounce(
      this.resetCurrentInteractiveElem.bind(this),
      100
    );

    this.inSync = inSync;

    this.start();
  }

  handleElementsScroll(event: Event) {
    const target = event.target as HTMLElement;

    if (this.currentInteractiveElem && this.currentInteractiveElem !== target) {
      return;
    }

    this.updateCurrentInteractiveElem(target);

    this.inSync.forEach((element) => {
      if (element !== target && element.scrollLeft !== target.scrollLeft) {
        element.scrollLeft = target.scrollLeft;
      }
    });
  }

  updateCurrentInteractiveElem(elem: HTMLElement) {
    if (!this.currentInteractiveElem) {
      this.currentInteractiveElem = elem;
    }

    this.resetCurrentInteractiveElemDebounced();
  }

  resetCurrentInteractiveElem() {
    this.currentInteractiveElem = undefined;
  }

  start() {
    this.inSync.forEach((item) => {
      item.addEventListener('scroll', this.handleElementsScroll);
    });
  }

  stop() {
    this.inSync.forEach((item) => {
      item.removeEventListener('scroll', this.handleElementsScroll);
    });
  }
}

export default SyncScroll;
