import { ID } from '../../types';

class GenerateId {
  static lastId = 99999999999999;

  static generate(): ID {
    return `${GenerateId.lastId++}`;
  }
}

export default GenerateId;
