import React, { Fragment, useMemo, memo, useState, useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { ItemMessagesListResultsMessageItem } from './ItemMessagesListResultsMessage.types';
import {
  FetchMessagesCacheKey,
  MessageSortTypeTypes
} from '../../../../../messagesTypes';
import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';
import { FileAttachmentItemImageVersions } from '../../../../../../../helpers/ImageHelper';

import { useImageEditorSubmitWithItemNanoId } from '../../../../../../../helpers/ImageEditor/hooks/useImageEditorSubmitWithItemNanoId';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useDownloadTypeFile } from '../../../../../../s3Multipart/hooks/useDownloadTypeFile';

import { UserLink } from '../../../../../../common/helpers/UserLink';

import { ItemMessagesListResultsMessageMenu } from './components/ItemMessagesListResultsMessageMenu';
import { ItemMessagesListResultsMessageContent } from './components/ItemMessagesListResultsMessageContent';
import { ItemMessagesListResultsMessageResultVersion } from './components/ItemMessagesListResultsMessageResultVersion';
import {
  ItemMessagesListResultsMessageResultFile,
  ItemMessagesListResultsMessageResultFileFileAttachment
} from './components/ItemMessagesListResultsMessageResultFile';
import {
  ItemMessagesListResultsMessageResultImage,
  ItemMessagesListResultsMessageResultImageFileAttachment
} from './components/ItemMessagesListResultsMessageResultImage';
import { ItemMessagesListMessageIdButtons } from '../ItemMessagesListMessageIdButtons';
import { ItemMessagesListMessageLikesList } from '../ItemMessagesListMessageLikesList';
import { ItemMessagesListMessageLikesPopover } from '../ItemMessagesListMessageLikesPopover';
import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';

import { Icon } from '../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../helpers/Translate';
import {
  LightboxWrapper,
  useLightboxWrapper,
  LightboxThumbnails,
  OnLightboxImageDownload
} from '../../../../../../../helpers/LightboxWrapper';
import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { DateTimeHelper } from '../../../../../../../helpers/DateTimeHelper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { MarkdownHelper } from '../../../../../../../helpers/MarkdownHelper';

import { Files } from '../../../../../../../utils/Files';
import { sortMessageFileAttachments } from '../../../../../utils/sortMessageFileAttachments';

import { LightboxWrapperPermissions } from '../../../../../../../helpers/LightboxWrapper/lightboxWrapperConstants';
import { MessagesPermissions } from '../../../../../messagesConstants';
import { tasksKeys } from '../../../../../../../locales/keys';

const defaultSortType = MessageSortTypeTypes.NAME_ASC;

interface ItemMessagesListResultsMessageProps {
  message: ItemMessagesListResultsMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}

function ItemMessagesListResultsMessage({
  message,
  messagesCacheKey,
  togglePreventModalClose
}: ItemMessagesListResultsMessageProps) {
  const currentUser = useCurrentUser();

  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const imageFileAttachments = useMemo<
    ItemMessagesListResultsMessageResultImageFileAttachment[]
  >(() => {
    return sortMessageFileAttachments(
      message.fileAttachments.filter((attachment) =>
        Files.isImage(attachment.file)
      ),
      message.sortType ?? defaultSortType
    );
  }, [message]);

  const fileFileAttachments = useMemo<
    ItemMessagesListResultsMessageResultFileFileAttachment[]
  >(() => {
    return sortMessageFileAttachments(
      message.fileAttachments.filter(
        (attachment) => !Files.isImage(attachment.file)
      ),
      message.sortType ?? defaultSortType
    );
  }, [message]);

  const lightboxThumbnails = useMemo<LightboxThumbnails>(() => {
    return imageFileAttachments.map((attachment) =>
      Files.urlFromFile(
        attachment.file,
        FileAttachmentItemImageVersions.FullScreenThumb1000x850
      )
    );
  }, [imageFileAttachments]);

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc,
    mainSrcThumbnail,
    prevSrcThumbnail,
    nextSrcThumbnail
  } = useLightboxWrapper({
    items: imageFileAttachments,
    thumbnails: currentUser.hasPermissions(
      LightboxWrapperPermissions.READ_LIGHTBOX_PLACEHOLDER_THUMBNAILS
    )
      ? lightboxThumbnails
      : null
  });

  const { onDownload } = useDownloadTypeFile({});

  const handleDownload = useCallback<OnLightboxImageDownload>(
    (uuid) => {
      onDownload(uuid, 'fileAttachments');
    },
    [onDownload]
  );

  const { handleImageEditorSubmitWithItemNanoId } =
    useImageEditorSubmitWithItemNanoId({
      taskNanoId: message.task?.nanoId
    });

  return (
    <Fragment>
      <div
        id={`message-${message.uuid}`}
        className="text-center text-xs text-gray-500 dark:text-gray-400 mt-6 mb-4"
        ref={setReferenceTooltipElement}
      >
        <div className="text-center">
          <Icon
            icon={IconsEnum.ROCKET_SOLID}
            className="w-4 h-4 inline-block -mt-0.5 text-current"
          />
          <span>
            <ItemMessagesListMessageIdButtons message={message} />
            {message.user ? (
              <UserLink user={message.user} text={message.user.fullName} />
            ) : null}{' '}
            <Translate id={tasksKeys.submittedResults} />{' '}
          </span>
        </div>
      </div>
      <Tooltip
        withArrow
        placement={TooltipPlacement.TOP}
        referenceElement={referenceTooltipElement}
      >
        <DateTimeHelper date={message.createdAt} />
      </Tooltip>
      <div className="my-12">
        <div className="flex flex-col flex-1">
          <div className="w-full">
            <div className="max-w-xl mx-auto relative group/reaction">
              <div className="relative rounded-xl px-2 py-4 ring-8 ring-blue-100 dark:ring-blue-900">
                <div className="mb-1 px-2">
                  <h6 className="text-lg font-semibold text-center">
                    <Translate id={tasksKeys.results} />
                  </h6>
                </div>
                <div className="mb-4 px-2">
                  <div className="flex break-wrap justify-center mb-4 sm:text-md text-sm">
                    <MarkdownHelper
                      source={message.body}
                      className="break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap  "
                      fromMessage
                      linkTarget="_blank"
                    />
                  </div>
                </div>
                <div>
                  <div className="mt-1 flex flex-col">
                    <div className="p-0.5 grid gap-1 overflow-hidden rounded-lg rounded-tr w-full grid-cols-2 sm:grid-cols-3 max-w-xl">
                      {imageFileAttachments.map((imageAttachment) => (
                        <ItemMessagesListResultsMessageResultImage
                          key={imageAttachment.uuid}
                          taskNanoId={message.task?.nanoId}
                          projectNanoId={message.task?.project?.nanoId}
                          fileAttachment={imageAttachment}
                          onLightboxOpen={handleLightboxOpenOnSlide}
                          messagesCacheKey={messagesCacheKey}
                          togglePreventModalClose={togglePreventModalClose}
                          createdProductId={message.createdProductId}
                        />
                      ))}
                    </div>
                    <div className="sm:px-2 mt-4">
                      {fileFileAttachments.map((fileAttachment) => (
                        <ItemMessagesListResultsMessageResultFile
                          key={fileAttachment.uuid}
                          taskNanoId={message.task?.nanoId}
                          fileAttachment={fileAttachment}
                          fileAttachments={fileFileAttachments}
                          messagesCacheKey={messagesCacheKey}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                <CheckPermissions
                  action={MessagesPermissions.READ_MESSAGE_RESULT_BUTTONS}
                >
                  <ItemMessagesListResultsMessageContent message={message} />
                </CheckPermissions>

                <CheckPermissions
                  action={MessagesPermissions.READ_MESSAGE_RESULT_FIN_BUTTONS}
                >
                  <ItemMessagesListResultsMessageContent
                    message={message}
                    isFinBlock
                  />
                </CheckPermissions>

                <ItemMessagesListResultsMessageMenu
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                />

                <ItemMessagesListResultsMessageResultVersion
                  resultVersion={message.resultVersion}
                />

                <ItemMessagesListMessageSendEmailNotificationButton
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                  className="flex items-center justify-center mt-1"
                />
              </div>
              <div className="-mt-1.5 flex pb-0.5 space-x-1 px-1.5 flex-row-reverse space-x-reverse">
                <ItemMessagesListMessageLikesList
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                />

                <CheckPermissions
                  action={MessagesPermissions.READ_MESSAGE_LIKES_BUTTON}
                >
                  <div className="sm:opacity-0 group-hover/reaction:opacity-100 focus-within:opacity-100">
                    <ItemMessagesListMessageLikesPopover
                      messageUuid={message.uuid}
                      messagesCacheKey={messagesCacheKey}
                      tooltipSingleton
                    />
                  </div>
                </CheckPermissions>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imagesCount={imagesCount}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        mainSrcThumbnail={mainSrcThumbnail}
        nextSrcThumbnail={nextSrcThumbnail}
        prevSrcThumbnail={prevSrcThumbnail}
        withFullScreenButton
        withBaseImageTitle
        onImageEditSubmit={
          currentUser.hasPermissions(
            MessagesPermissions.READ_MESSAGE_RESULT_EDIT_BUTTON
          )
            ? handleImageEditorSubmitWithItemNanoId
            : undefined
        }
        onImageDownload={handleDownload}
      />
    </Fragment>
  );
}

export default memo<ItemMessagesListResultsMessageProps>(
  ItemMessagesListResultsMessage
);
