import React from 'react';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { ItemMessagesListResultsMessageResultFileFileAttachment } from './ItemMessagesListResultsMessageResultFile.types';
import { TaskNanoID } from '../../../../../../../../tasks/tasksTypes';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { ItemMessagesListResultsMessageResultFileDropdown } from './components/ItemMessagesListResultsMessageResultFileDropdown';

import { LinkHelper } from '../../../../../../../../../helpers/links/LinkHelper';
import { Icon } from '../../../../../../../../../helpers/Icon';
import { SizeHelper } from '../../../../../../../../../helpers/SizeHelper';
import { DateHelper } from '../../../../../../../../../helpers/DateHelper';

interface ItemMessagesListResultsMessageResultFileProps {
  fileAttachments: ItemMessagesListResultsMessageResultFileFileAttachment[];
  fileAttachment: ItemMessagesListResultsMessageResultFileFileAttachment;
  taskNanoId: TaskNanoID;
  messagesCacheKey: FetchMessagesCacheKey;
}

function ItemMessagesListResultsMessageResultFile({
  fileAttachments,
  fileAttachment,
  taskNanoId,
  messagesCacheKey
}: ItemMessagesListResultsMessageResultFileProps) {
  return (
    <div className="flex text-xs sm:text-base space-x-1.5 items-center">
      <div>
        <Icon
          icon={IconsEnum.DOCUMENT_OUTLINE}
          className="h-4 w-4 sm:h-5 sm:w-5"
        />
      </div>
      <LinkHelper
        className="flex flex-1 leading-relaxed overflow-hidden"
        href={fileAttachment.file}
        target="_blank"
      >
        <span className="truncate">{fileAttachment.name}</span>
      </LinkHelper>
      <div className="w-16 sm:w-20 text-right whitespace-nowrap">
        <SizeHelper size={fileAttachment.size} />
      </div>
      <div className="w-20 sm:w-28 text-right whitespace-nowrap">
        <DateHelper date={fileAttachment.createdAt} />
      </div>
      <ItemMessagesListResultsMessageResultFileDropdown
        fileAttachments={fileAttachments}
        fileAttachment={fileAttachment}
        taskNanoId={taskNanoId}
        messagesCacheKey={messagesCacheKey}
      />
    </div>
  );
}

export default ItemMessagesListResultsMessageResultFile;
