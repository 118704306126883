import React, { memo } from 'react';

import { ID, TableViewType, IsDisabled } from '../../../../../types';

import { OnSetCheckedIds } from '../../../../../common/hooks/useTableCheckable';

import { IndexTableCheckbox } from '../IndexTableCheckbox';
import { IndexTableStickyCell } from '../IndexTableStickyCell';

interface IndexTableRowCheckboxProps {
  scope: string;
  checked: boolean;
  disabled?: IsDisabled;
  onCheck: OnSetCheckedIds;
  itemId: ID;
  viewType?: TableViewType;
}

function IndexTableRowCheckbox({
  scope,
  checked,
  disabled,
  itemId,
  viewType,
  onCheck
}: IndexTableRowCheckboxProps) {
  return (
    <IndexTableStickyCell isLeft viewType={viewType}>
      <IndexTableCheckbox
        scope={scope}
        checked={checked}
        disabled={disabled}
        itemId={itemId}
        onCheck={onCheck}
      />
    </IndexTableStickyCell>
  );
}

export default memo<IndexTableRowCheckboxProps>(IndexTableRowCheckbox);
