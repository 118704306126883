import React, { useCallback, useState } from 'react';
import size from 'lodash/size';
import sum from 'lodash/sum';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { PinnedMessageWithTodoListMessageItemWithTodo } from './PinnedMessageWithTodoListMessage.types';
import {
  FetchTodoItemsInProjectCacheKey,
  FetchTodoItemsInTaskCacheKey
} from '../../../../todoItems/todoItemsTypes';
import { UpdateIndexQueryItemCacheAction } from '../../../../common/hooks/base/reactQuery/useIndexQuery';
import { FetchMessagesCacheKey } from '../../../messagesTypes';

import {
  UPDATE_TODO_ITEM_QUERY,
  UpdateTodoItemQueryResponse
} from '../../../../todoItems/queries/updateTodoItem.query';
import {
  CREATE_TODO_ITEM_IN_TASK_QUERY,
  CreateTodoItemInTaskQueryResponse
} from '../../../../todoItems/queries/createTodoItemInTask.query';
import {
  CREATE_TODO_ITEM_IN_PROJECT_QUERY,
  CreateTodoItemInProjectQueryResponse
} from '../../../../todoItems/queries/createTodoItemInProject.query';
import { CHECK_TODO_ITEMS_QUERY } from '../../../../todoItems/queries/checkTodoItems.query';
import { UNCHECK_TODO_ITEMS_QUERY } from '../../../../todoItems/queries/uncheckTodoItems.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useTranslate } from '../../../../../common/hooks/useTranslate';
import { useUpdateTodoItem } from '../../../../todoItems/hooks/useUpdateTodoItem';
import { useCreateTodoItemInTask } from '../../../../todoItems/hooks/useCreateTodoItemInTask';
import { useCreateTodoItemInProject } from '../../../../todoItems/hooks/useCreateTodoItemInProject';
import { useCheckTodoItems } from '../../../../todoItems/hooks/useCheckTodoItems';
import { useUncheckTodoItems } from '../../../../todoItems/hooks/useUncheckTodoItems';

import { MessagesListMessageImages } from '../MessagesListMessage/components/MessagesListMessageImages';
import { MessagesListMessageFiles } from '../MessagesListMessage/components/MessagesListMessageFiles';
import { MessagesListMessageColors } from '../MessagesListMessage/components/MessagesListMessageColors';
import { MessagesListMessageTodoList } from '../MessagesListMessage/components/MessagesListMessageTodoList';
import { ItemMessagesListMessageMenuDropdown } from '../ItemMessagesListMessage/components/ItemMessagesListMessageMenuDropdown';

import { UserAvatarLink } from '../../../../common/helpers/UserAvatarLink';
import { MarkdownHelper } from '../../../../../helpers/MarkdownHelper';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import { TooltipSingletonSourceWrapper } from '../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { Checkbox } from '../../../../../helpers/Checkbox';

import { MessagesPermissions } from '../../../messagesConstants';
import { IconsEnum } from '../../../../../assets/icons/types';

import { TodoItemsCache } from '../../../../todoItems/TodoItemsCache';

import { words } from '../../../../../locales/keys';
import { PopoverPlacement } from '../../../../../helpers/Popover/popoverConstants';

interface PinnedMessageWithTodoListMessageProps {
  message: PinnedMessageWithTodoListMessageItemWithTodo;
  messagesCacheKey: FetchMessagesCacheKey;

  todoItemsCacheKey:
    | FetchTodoItemsInProjectCacheKey
    | FetchTodoItemsInTaskCacheKey;
  updateTodoItemCache: UpdateIndexQueryItemCacheAction<UpdateTodoItemQueryResponse>;
  withCheckBox: boolean;
}

function PinnedMessageWithTodoListMessage({
  message,
  messagesCacheKey,
  todoItemsCacheKey,
  updateTodoItemCache,
  withCheckBox
}: PinnedMessageWithTodoListMessageProps) {
  const [showAttachedData, setShowAttachedData] = useState<boolean>(true);

  const currentUser = useCurrentUser();
  const { t } = useTranslate();

  const { updateTodoItemLoading, updateTodoItem } =
    useUpdateTodoItem<UpdateTodoItemQueryResponse>({
      indexCacheKey: todoItemsCacheKey,
      query: UPDATE_TODO_ITEM_QUERY,
      updateTodoItemCache
    });

  const { createTodoItemInTask } =
    useCreateTodoItemInTask<CreateTodoItemInTaskQueryResponse>({
      taskNanoId: message.task?.nanoId,
      query: CREATE_TODO_ITEM_IN_TASK_QUERY
    });

  const { createTodoItemInProject } =
    useCreateTodoItemInProject<CreateTodoItemInProjectQueryResponse>({
      projectNanoId: message.project?.nanoId,
      query: CREATE_TODO_ITEM_IN_PROJECT_QUERY
    });

  const { checkTodoItems } = useCheckTodoItems({
    query: CHECK_TODO_ITEMS_QUERY,
    indexCacheKey: TodoItemsCache.messageItemsCacheKey(message.uuid)
  });

  const { uncheckTodoItems } = useUncheckTodoItems({
    query: UNCHECK_TODO_ITEMS_QUERY,
    indexCacheKey: TodoItemsCache.messageItemsCacheKey(message.uuid)
  });

  const handleCheckedMessage = useCallback(
    (done: boolean) => {
      if (!isEmpty(message.todoItems)) {
        done
          ? checkTodoItems({
              ids: map(message.todoItems, (todoItem) => todoItem.id)
            })
          : uncheckTodoItems({
              ids: map(message.todoItems, (todoItem) => todoItem.id)
            });
      }

      if (message.todoItemUuid) {
        return updateTodoItem({ uuid: message.todoItemUuid, done });
      }

      if (message.project?.id) {
        return createTodoItemInProject({
          name: message.uuid,
          projectId: message.project.id,
          done,
          visibleForClients: false
        });
      }

      if (message.task?.id) {
        return createTodoItemInTask({
          name: message.uuid,
          taskId: message.task.id,
          done,
          visibleForClients: false
        });
      }
    },
    [
      checkTodoItems,
      createTodoItemInProject,
      createTodoItemInTask,
      message,
      uncheckTodoItems,
      updateTodoItem
    ]
  );

  const toggleShowAttachedData = useCallback<() => void>(
    () => setShowAttachedData((prev) => !prev),
    []
  );

  const withMessageLinkBlank = currentUser.hasPermissions(
    MessagesPermissions.READ_MESSAGE_LINK_BLANK
  );

  const attachedDataCount = sum([
    size(message.fileAttachments),
    size(message.colors),
    size(message.selectedLifestyles),
    size(message.selectedMaterials),
    size(message.selectedProducts)
  ]);

  const forwardMessageProjectUuid =
    message.project?.uuid || message.task?.project?.uuid;
  const forwardMessageTaskUuid = message.task?.uuid;

  return (
    <div key={message.uuid} className="flex gap-2">
      {withCheckBox && (
        <div className="flex items-center self-start">
          <Checkbox
            checked={message.done}
            onChange={handleCheckedMessage}
            disabled={updateTodoItemLoading}
          />
        </div>
      )}

      <div>
        <UserAvatarLink className="h-5 w-5 rounded-full" user={message.user} />
      </div>

      <div className="flex-1 min-w-0">
        <div className={message.done ? 'text-sm line-through' : 'text-sm'}>
          {message.body && (
            <MarkdownHelper
              source={message.body}
              className="break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap leading-snug"
              fromMessage
              linkTarget={withMessageLinkBlank ? '_blank' : undefined}
            />
          )}

          {size(message.todoItems) > 0 && (
            <MessagesListMessageTodoList message={message} />
          )}

          {attachedDataCount > 0 && (
            <div>
              <div className="text-xs text-blue-500">
                <PureIconButtonHelper
                  onClick={toggleShowAttachedData}
                  className="p-0 space-x-1 inline-flex items-center whitespace-nowrap leading-5 focus:ring-base text-current hover:underline"
                  icon={
                    showAttachedData
                      ? IconsEnum.ARROW_DOWN_TRIANGLE_SOLID
                      : IconsEnum.ARROW_RIGHT_TRIANGLE_SOLID
                  }
                  iconClassName="h-3 w-3"
                  text={`${attachedDataCount} ${t(words.attachments)}`}
                />
              </div>

              {showAttachedData && (
                <div className="overflow-y-hidden overscroll-x-auto max-w-full mt-2">
                  <div className="gap-1 grid grid-flow-col justify-start pb-1">
                    <TooltipSingletonSourceWrapper
                      placement={TooltipPlacement.TOP}
                      withArrow
                    >
                      <MessagesListMessageImages message={message} />
                      <MessagesListMessageFiles message={message} />
                    </TooltipSingletonSourceWrapper>
                    <MessagesListMessageColors message={message} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div>
        <ItemMessagesListMessageMenuDropdown
          forwardMessageProjectUuid={forwardMessageProjectUuid}
          forwardMessageTaskUuid={forwardMessageTaskUuid}
          message={message}
          messagesCacheKey={messagesCacheKey}
          withPinButton
          dropdownPlacement={PopoverPlacement.BOTTOM_END}
        />
      </div>
    </div>
  );
}

export default PinnedMessageWithTodoListMessage;
