import { Control, Controller } from 'react-hook-form';

import { I18nText } from '../../../../../../../types';
import {
  ItemMessagesSettingsFormData,
  ItemMessagesSettingsFormFields
} from '../../hooks/useItemMessagesSettingsForm';

import { CheckBoxField } from '../../../../../../../helpers/FormFields/CheckBoxField';

interface ItemMessagesSettingsCheckboxFieldProps {
  control: Control<ItemMessagesSettingsFormData>;
  name: ItemMessagesSettingsFormFields;
  i18nLabel: I18nText;
}

function ItemMessagesSettingsCheckboxField({
  control,
  name,
  i18nLabel
}: ItemMessagesSettingsCheckboxFieldProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => (
        <CheckBoxField
          id={name}
          name={name}
          onChange={onChange}
          checked={value}
          i18nLabel={i18nLabel}
        />
      )}
    />
  );
}

export default ItemMessagesSettingsCheckboxField;
