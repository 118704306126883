import { gql } from 'graphql-request';

import {
  DownloadLifestyleGqlStatus,
  LifestyleDeviceNanoId,
  DownloadLifestyleGqlError,
  LifestyleUUID
} from '../../lifestylesTypes';

export interface DownloadLifestyleResponse {
  downloadLifestyle: {
    status: DownloadLifestyleGqlStatus;
    errors: DownloadLifestyleError;
  };
}

export interface DownloadLifestyleInput {
  uuid: LifestyleUUID;
  deviceNanoId: LifestyleDeviceNanoId;
}

export interface DownloadLifestyleError {
  fullMessages: DownloadLifestyleGqlError;
}

export const action = 'downloadLifestyle';

export const DOWNLOAD_LIFESTYLE_QUERY = gql`
  mutation DownloadLifestyle($uuid: ID!, $deviceNanoId: String!) {
    downloadLifestyle(input: { uuid: $uuid, deviceNanoId: $deviceNanoId }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
