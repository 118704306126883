import { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import {
  FetchUsersFetched,
  FetchUsersTotalCount,
  UserID
} from '../../../../../../users/usersTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { ItemMessagesFilterFormAuthorUserType } from './ItemMessagesFilterFormAuthor.type';
import { ItemMessageFilterFormData } from '../../hooks/useItemMessagesFilterForm/useItemMessagesFilterForm.types';

import {
  ItemMessagesFilterFormItem,
  ItemMessagesFilterFormItemValue
} from '../ItemMessagesFilterFormItem';

import { Icon } from '../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../helpers/Translate';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';

import { words } from '../../../../../../../locales/keys';

interface ItemMessagesFilterFormAuthorProps {
  control: Control<ItemMessageFilterFormData>;
  users: ItemMessagesFilterFormAuthorUserType[];
  usersFetched: FetchUsersFetched;
  usersTotalCount: FetchUsersTotalCount;
}

function ItemMessagesFilterFormAuthor({
  control,
  users,
  usersFetched,
  usersTotalCount
}: ItemMessagesFilterFormAuthorProps) {
  const items = useMemo<ItemMessagesFilterFormItemValue<UserID>[]>(
    () =>
      map(users, (user) => ({
        i18nText: user.fullName,
        value: user.uuid,
        image: user.image?.file
      })),
    [users]
  );

  if (!usersTotalCount || (usersFetched && isEmpty(users))) {
    return null;
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-2">
        <Icon icon={IconsEnum.USERS_SOLID} className="h-4 w-4" />

        <span className="text-sm font-semibold text-left flex-1">
          <Translate id={words.author} />
        </span>
      </div>

      <Controller
        control={control}
        name="userUuids"
        render={({ field: { onChange, value } }) => (
          <div className="flex flex-wrap gap-2 max-h-40 overflow-y-auto">
            <LoadingSkeleton
              loaded={usersFetched}
              className="m-0 grow"
              count={3}
            >
              {items.map((item) => (
                <ItemMessagesFilterFormItem
                  key={item.value}
                  value={value}
                  item={item}
                  onChange={onChange}
                  multi
                />
              ))}
            </LoadingSkeleton>
          </div>
        )}
      />
    </div>
  );
}

export default ItemMessagesFilterFormAuthor;
