export interface ItemMessageFilterFormData {
  visible: boolean | null;
  userUuids: string[] | null;
  other: string | null;
}

export enum ItemMessageFilterFormOtherTypes {
  WITH_ATTACHMENTS = 'withAttachments',
  WHITEBOARD = 'whiteboard',
  CRITICAL_REQUIREMENTS = 'criticalRequirements'
}
