import { useCallback, useMemo } from 'react';

import {
  DownloadTypeFileOnDownload,
  DownloadTypeFileTypes
} from './useDownloadTypeFile.types';

import {
  DownloadClientFileQueryResponse,
  DOWNLOAD_CLIENT_FILE_QUERY
} from '../../../clientFiles/queries/downloadClientFile.query';
import {
  DownloadFileAttachmentQueryResponse,
  DOWNLOAD_FILE_ATTACHMENT_QUERY
} from '../../../fileAttachments/queries/downloadFileAttachment.query';
import {
  DownloadImageQueryResponse,
  DOWNLOAD_IMAGE_QUERY
} from '../../../images/queries/downloadImage.query';
import {
  DownloadSourceFileQueryResponse,
  DOWNLOAD_SOURCE_FILE_QUERY
} from '../../../sourceFiles/queries/downloadSourceFile.query';
import {
  DownloadMaxFileQueryResponse,
  DOWNLOAD_MAX_FILE_QUERY
} from '../../../maxFiles/queries/downloadMaxFile.query';

import { useDownloadClientFile } from '../../../clientFiles/hooks/useDownloadClientFile';
import { useShowToastOnErrorChange } from '../../../../common/hooks/useShowToastOnErrorChange';
import { useDownloadNanoId } from '../../../downloads/hooks/useDownloadNanoId';
import { useDownloadFileAttachment } from '../../../fileAttachments/hooks/useDownloadFileAttachment';
import { useDownloadImage } from '../../../images/hooks/useDownloadImage';
import { useDownloadSourceFile } from '../../../sourceFiles/hooks/useDownloadSourceFile';
import { useDownloadMaxFile } from '../../../maxFiles/hooks/useDownloadMaxFile';
import { useDownloadProduct } from '../../../products/hooks/useDownloadProduct';
import { useDownloadLifestyle } from '../../../lifestyles/hooks/useDownloadLifestyle';
import { useDownloadMaterial } from '../../../materials/hooks/useDownloadMaterial';

interface DownloadTypeFileProps {
  type?: DownloadTypeFileTypes;
}

function useDownloadTypeFile({ type }: DownloadTypeFileProps) {
  const { downloadClientFile, downloadClientFileErrorMessage } =
    useDownloadClientFile<DownloadClientFileQueryResponse>({
      query: DOWNLOAD_CLIENT_FILE_QUERY
    });

  const { downloadFileAttachment, downloadFileAttachmentErrorMessage } =
    useDownloadFileAttachment<DownloadFileAttachmentQueryResponse>({
      query: DOWNLOAD_FILE_ATTACHMENT_QUERY
    });

  const { downloadImage, downloadImageErrorMessage } =
    useDownloadImage<DownloadImageQueryResponse>({
      query: DOWNLOAD_IMAGE_QUERY
    });

  const { downloadSourceFile, downloadSourceFileErrorMessage } =
    useDownloadSourceFile<DownloadSourceFileQueryResponse>({
      query: DOWNLOAD_SOURCE_FILE_QUERY
    });

  const { downloadMaxFile, downloadMaxFileErrorMessage } =
    useDownloadMaxFile<DownloadMaxFileQueryResponse>({
      query: DOWNLOAD_MAX_FILE_QUERY
    });

  const { downloadProductErrorMessage, downloadProduct } = useDownloadProduct();

  const { downloadLifestyleErrorMessage, downloadLifestyle } =
    useDownloadLifestyle();

  const { downloadMaterialErrorMessage, downloadMaterial } =
    useDownloadMaterial();

  const downloadActions = useMemo(
    () => ({
      clientFiles: downloadClientFile,
      fileAttachments: downloadFileAttachment,
      images: downloadImage,
      sourceFiles: downloadSourceFile,
      maxFiles: downloadMaxFile,
      products: downloadProduct,
      lifestyles: downloadLifestyle,
      materials: downloadMaterial
    }),
    [
      downloadClientFile,
      downloadFileAttachment,
      downloadImage,
      downloadSourceFile,
      downloadMaxFile,
      downloadProduct,
      downloadLifestyle,
      downloadMaterial
    ]
  );

  useShowToastOnErrorChange({
    error:
      downloadClientFileErrorMessage ||
      downloadFileAttachmentErrorMessage ||
      downloadImageErrorMessage ||
      downloadSourceFileErrorMessage ||
      downloadMaxFileErrorMessage ||
      downloadProductErrorMessage ||
      downloadLifestyleErrorMessage ||
      downloadMaterialErrorMessage
  });

  const { downloadNanoId } = useDownloadNanoId();

  const onDownload = useCallback<DownloadTypeFileOnDownload>(
    (uuid, downloadFileType) => {
      downloadActions[type || downloadFileType]?.({
        uuid,
        deviceNanoId: downloadNanoId
      });
    },
    [downloadActions, downloadNanoId, type]
  );

  return {
    onDownload
  };
}

export default useDownloadTypeFile;
