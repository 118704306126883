import { gql } from 'graphql-request';

import { TodoItemID, TodoItemName, TodoItemUUID } from '../todoItemsTypes';

export interface CreateTodoItemInProjectQueryResponse {
  id: TodoItemID;
  uuid: TodoItemUUID;
  name: TodoItemName;
}

export const CREATE_TODO_ITEM_IN_PROJECT_QUERY = gql`
  mutation CreateTodoItemInProject(
    $name: String!
    $projectId: ID!
    $visibleForClients: Boolean
    $done: Boolean
  ) {
    createTodoItemInProject(
      input: {
        name: $name
        projectId: $projectId
        visibleForClients: $visibleForClients
        done: $done
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
        name
        visibleForClients
        done
        createdAt
        updatedAt
        userId
        user {
          uuid
          fullName
          image {
            uuid
            file
          }
        }
        projectId
        project {
          id
          uuid
          name
        }
      }
      errors {
        name
        projectId
        visibleForClients
        done
        fullMessages
      }
    }
  }
`;
